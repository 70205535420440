import { FormHelperText, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalInputLable from "../../../../../components/globalInputLable";
import GlobalInput from "../../../../../components/globalInput";
import CommonDropdown from "../../../../../components/commonDropDown";
import { useFormik } from "formik";
import { getInitialSelfEmployedValues } from "../../../../../utils/initialValues";
import { SelfEmployedDetailsSchema } from "../../../../../utils/validateSchema";
import {
  setBanks,
  setBusinessOwned,
  setBusinessType,
  setCompanyAddress,
  setFormData,
  setIndustryType,
  setPincodeError,
} from "../../../../../store/reducers";
import apiEndPointsConfig from "../../../../../config/apiEndPointConfig";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../services/apiCallServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import numberToWords from "number-to-words";
import CommonButton from "../../../../../components/commonButton";
import { errorFocus } from "../../../../../utils/errorFocus";
const SelfEmployed = () => {
  // const workType = ["Self Employed"];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [turnoverInWords, setTurnoverInWords] = useState();
  const [monthlyProfitInWords, setMonthlyProfitInWords] = useState();
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  const PinCodeErrorData = useSelector((state) => state?.form?.pinCodeError);
  const CompanyAddressData = useSelector(
    (state) => state?.form?.companyAddress
  );
  const BusinessOwnedListData = useSelector(
    (state) => state?.form?.businessOwnedList
  );
  const BusinessTypeListData = useSelector(
    (state) => state?.form?.businessTypeList
  );
  const IndustryTypeListData = useSelector(
    (state) => state?.form?.industryTypeList
  );
  const BankListData = useSelector((state) => state?.form?.bankList);
  //----businesowned
  const GetBusinessOwnedList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBusinessOwned,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetBusinessOwnedList?.isSuccess &&
      GetBusinessOwnedList?.data?.data !== null
    ) {
      const modifyres = GetBusinessOwnedList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBusinessOwned(modifyres));
    }
  }, [GetBusinessOwnedList?.isSuccess]);
  //----businessList
  const GetBusinessList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBusinessTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (GetBusinessList?.isSuccess && GetBusinessList?.data?.data !== null) {
      const modifyres = GetBusinessList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBusinessType(modifyres));
    }
  }, [GetBusinessList?.isSuccess]);
  //----
  const GetIndustryTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getIndustryTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetIndustryTypesList?.isSuccess &&
      GetIndustryTypesList?.data?.data !== null
    ) {
      const modifyres = GetIndustryTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setIndustryType(modifyres));
    }
  }, [GetIndustryTypesList?.isSuccess]);
  //----
  const GetAllBankList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBanks,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (GetAllBankList?.isSuccess && GetAllBankList?.data?.data !== null) {
      const modifyres = GetAllBankList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBanks(modifyres));
    }
  }, [GetAllBankList?.isSuccess]);
  //----
  const Year = [
    { label: "1 Year", value: "1 Year" },
    { label: "1-2 Years", value: "1-2 Years" },
    { label: "3 Years", value: "3 Years" },
    { label: "3-5 Years", value: "3-5 Years" },
    { label: "5+ Years", value: "5+ Years" },
  ];

  const navigateToPersonalDetails = () => {
    navigate("/profiling/profile/residencedetails");
  };
  //getAddress API Call
  const [GetPinCodeAddressApi, getPinCodeAddressApiData] = useApiCallMutation();
  useEffect(() => {
    if (getPinCodeAddressApiData?.isSuccess) {
      if (getPinCodeAddressApiData?.data?.status.code === 200) {
        dispatch(
          setFormData({
            employmentDetails: {
              ...formData.employmentDetails,
              pinCode: formData?.employmentDetails?.pinCode,
              cityId: getPinCodeAddressApiData?.data?.data?.city?.id,
              stateId: getPinCodeAddressApiData?.data?.data?.state?.id,
              countryId: getPinCodeAddressApiData?.data?.data?.country?.id,
            },
          })
        );
        dispatch(
          setCompanyAddress({
            city: getPinCodeAddressApiData?.data?.data?.city?.name,
            state: getPinCodeAddressApiData?.data?.data?.state?.name,
            country: getPinCodeAddressApiData?.data?.data?.country?.name,
          })
        );
      } else if (getPinCodeAddressApiData?.data?.status.code === 404) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "Pincode does not exists",
          })
        );
        formik.setErrors("pinCode", "Pincode does not exists");
      } else {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "",
          })
        );
      }
    }
  }, [getPinCodeAddressApiData?.isSuccess]);
  const getAddress = async (event) => {
    if (
      /^\d*$/.test(event.target.value) &&
      event.target.value.length <= 6 &&
      event.target.value !== "000000"
    ) {
      dispatch(
        setPincodeError({
          ...PinCodeErrorData,
          companyPincode: "",
        })
      );
      dispatch(
        setFormData({
          employmentDetails: {
            ...formData.employmentDetails,
            pinCode: event.target.value,
          },
        })
      );
      if (event.target.value?.length === 6) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "",
          })
        );
        const data = {
          pinCode: event.target.value,
        };
        GetPinCodeAddressApi({
          endPoint: apiEndPointsConfig.getPincodesAddress,
          method: "POST",
          data: { data },
        });
      } else {
        dispatch(
          setFormData({
            employmentDetails: {
              ...formData.employmentDetails,
              pinCode: event.target.value,
              cityId: "",
              stateId: "",
              countryId: "",
            },
          })
        );
        dispatch(
          setCompanyAddress({
            city: "",
            state: "",
            country: "",
          })
        );
      }
    }
  };

  //dropOff Api
  const [DropOffApi, dropOffApiData] = useApiCallMutation();
  const dropOffRequest = async () => {
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      stage: "employment details",
      employmentDetails: {
        businessTypeId: formData?.employmentDetails?.businessTypeId,
        businessOwnedId: formData?.employmentDetails?.businessOwnedId,
        // companyTypeId: formData?.employmentDetails?.companyTypeId,
        industryTypeId: formData?.employmentDetails?.industryTypeId,
        companyName: formData?.employmentDetails?.companyName,
        designation: formData?.employmentDetails?.designation,
        address: formData?.employmentDetails?.address,
        pinCode: formData?.employmentDetails?.pinCode,
        countryId: formData?.employmentDetails?.countryId,
        stateId: formData?.employmentDetails?.stateId,
        cityId: formData?.employmentDetails?.cityId,
        yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
        // professionTypeId: formData?.employmentDetails?.professionTypeId,
        turnover: formData?.employmentDetails?.turnover,
        monthlyProfit: formData?.employmentDetails?.monthlyProfit,
        // income: formData?.employmentDetails?.income,
        // salaryMode: formData?.employmentDetails?.salaryMode,
        // bankId: formData?.employmentDetails?.bankId,
        currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
        savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
        gst: formData?.employmentDetails?.gst,
      },
      employmentType: formData?.employmentType,
    };
    DropOffApi({
      endPoint: apiEndPointsConfig?.newDropOff,
      method: "POST",
      data: { data },
    });
  };
  const handleSubmit = async () => {
    dispatch(
      setFormData({
        employmentDetails: {
          ...formData.employmentDetails,
          salaryMode: "Bank Transfer",
          income: "0",
        },
      })
    );
    if (PinCodeErrorData?.companyPincode === "") {
      const isExistUser = sessionStorage.getItem("isExistUser");
      if (!isExistUser) {
        await dropOffRequest();
      }
      navigateToPersonalDetails();
    } else {
      document.getElementById("pinCode").focus();
    }
    navigateToPersonalDetails();
  };
  const formik = useFormik({
    initialValues: getInitialSelfEmployedValues(formData),
    validationSchema: SelfEmployedDetailsSchema,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    if (formik?.isSubmitting) {
      errorFocus(formik?.errors);
    }
  }, [formik?.isSubmitting, formik?.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowGap={4}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Who owns the business?"} />
          <CommonDropdown
            id={"businessOwnedId"}
            name={"businessOwnedId"}
            options={BusinessOwnedListData || []}
            value={
              BusinessOwnedListData?.find(
                (option) =>
                  formData?.employmentDetails?.businessOwnedId === option.value
              )?.label
            }
            onBlur={formik.handleBlur}
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    businessOwnedId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("businessOwnedId", newValue);
            }}
            error={
              formik.touched.businessOwnedId &&
              Boolean(formik.errors.businessOwnedId)
            }
            helperText={
              formik.touched.businessOwnedId && formik.errors.businessOwnedId
            }
            // error={formik.errors.businessOwnedId}
            // helperText={formik.errors.businessOwnedId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select your business type"} />
          <CommonDropdown
            onBlur={formik.handleBlur}
            id={"businessTypeId"}
            name={"businessTypeId"}
            options={BusinessTypeListData ? BusinessTypeListData : ""}
            value={
              BusinessTypeListData?.find(
                (option) =>
                  formData?.employmentDetails?.businessTypeId === option.value
              )?.label
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    businessTypeId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("businessTypeId", newValue);
            }}
            error={
              formik.touched.businessTypeId &&
              Boolean(formik.errors.businessTypeId)
            }
            helperText={
              formik.touched.businessTypeId && formik.errors.businessTypeId
            }
            // error={formik.errors.businessTypeId}
            // helperText={formik.errors.businessTypeId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select industry"} />
          <CommonDropdown
            onBlur={formik.handleBlur}
            id={"industryTypeId"}
            name={"industryTypeId"}
            options={IndustryTypeListData ? IndustryTypeListData : ""}
            value={
              IndustryTypeListData?.find(
                (option) =>
                  formData?.employmentDetails?.industryTypeId === option.value
              )?.label
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    industryTypeId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("industryTypeId", newValue);
            }}
            error={
              formik.touched.industryTypeId &&
              Boolean(formik.errors.industryTypeId)
            }
            helperText={
              formik.touched.industryTypeId && formik.errors.industryTypeId
            }

            // error={formik.errors.industryTypeId}
            // helperText={formik.errors.industryTypeId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter your company name"} />
          <GlobalInput
            onBlur={formik.handleBlur}
            type={""}
            placeholder={"Enter the Company Name"}
            id={"companyName"}
            name={"companyName"}
            value={formData?.employmentDetails?.companyName}
            onChange={(event, newValue) => {
              if (/^(?![ ]).*$/.test(event.target.value)) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      companyName: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
            // error={formik.errors.companyName}
            // helperText={formik.errors.companyName}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter company address"} />
          <GlobalInput
            type={""}
            onBlur={formik.handleBlur}
            placeholder={"Enter the Company address"}
            id={"address"}
            name={"address"}
            value={formData?.employmentDetails?.address}
            onChange={(event, newValue) => {
              if (
                /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(event.target.value)
              ) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      address: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            // error={formik.errors.address}
            // helperText={formik.errors.address}
          />

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <GlobalInput
                type="tel"
                inputMode="numeric"
                onBlur={formik.handleBlur}
                placeholder={"  Pincode"}
                id={"pinCode"}
                name={"pinCode"}
                value={formData?.employmentDetails?.pinCode}
                onChange={(event) => {
                  getAddress(event);
                  formik.handleChange(event);
                }}
                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
                // error={formik.errors.pinCode}
                // helperText={formik.errors.pinCode}
              />

              {PinCodeErrorData?.companyPincode ? (
                <FormHelperText
                  sx={{
                    color: "rgb(211, 47, 47)",
                    fontSize: "0.75rem",
                    ml: "14px",
                  }}
                >
                  {PinCodeErrorData?.companyPincode}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6}>
              <GlobalInput
                disable={true}
                type={""}
                onBlur={formik.handleBlur}
                placeholder={"City"}
                id={"city"}
                name={"cityId"}
                value={CompanyAddressData?.city}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                // error={formik.touched.city && Boolean(formik.errors.city)}
                // helperText={formik.touched.city && formik.errors.city}
                // error={formik.errors.city}
                // helperText={formik.errors.city}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <GlobalInput
                disable={true}
                type={""}
                placeholder={"State"}
                id={"state"}
                name={"state"}
                value={CompanyAddressData?.state}
                onChange={(event, newValue) => {
                  formik.handleChange(event);
                }}
                // error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}
                // error={formik.errors.state}
                // helperText={formik.errors.state}
                onBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item xs={6}>
              <GlobalInput
                disable={true}
                type={""}
                placeholder={"Country"}
                id={"country"}
                name={"country"}
                value={CompanyAddressData?.country}
                onChange={(event, newValue) => {
                  formik.handleChange(event);
                }}
                // error={formik.touched.country && Boolean(formik.errors.country)}
                // helperText={formik.touched.country && formik.errors.country}
                // error={formik.errors.country}
                // helperText={formik.errors.country}
                onBlur={formik.handleBlur}
              />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable
            lableText={
              "How many years have you been working in your current business?"
            }
          />
          <CommonDropdown
            onBlur={formik.handleBlur}
            id={"yearsWorkingIn"}
            name={"yearsWorkingIn"}
            options={Year}
            value={formData?.employmentDetails?.yearsWorkingIn}
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    yearsWorkingIn: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("yearsWorkingIn", newValue);
            }}
            error={
              formik.touched.yearsWorkingIn &&
              Boolean(formik.errors.yearsWorkingIn)
            }
            helperText={
              formik.touched.yearsWorkingIn && formik.errors.yearsWorkingIn
            }
            // error={formik.errors.yearsWorkingIn}
            // helperText={formik.errors.yearsWorkingIn}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select your designation"} />
          <GlobalInput
            type={""}
            onBlur={formik.handleBlur}
            placeholder={"Enter the Designation"}
            id={"designation"}
            name={"designation"}
            value={formData?.employmentDetails?.designation}
            onChange={(event, newValue) => {
              if (/^(?![ ]).*$/.test(event.target.value)) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      designation: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={
              formik.touched.designation && Boolean(formik.errors.designation)
            }
            helperText={formik.touched.designation && formik.errors.designation}
            // error={formik.errors.designation}
            // helperText={formik.errors.designation}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable
            lableText={"Please enter your gross annual sales/turnover"}
          />
          <GlobalInput
            type="tel"
            inputMode="numeric"
            onBlur={formik.handleBlur}
            placeholder={"Enter Annual Turnover"}
            id={"turnover"}
            name={"turnover"}
            value={formData?.employmentDetails?.turnover}
            onChange={(e, newValue) => {
              if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      turnover: e.target.value,
                    },
                  })
                );
                const number = parseFloat(e.target.value);
                if (!isNaN(number) && isFinite(number)) {
                  setTurnoverInWords(numberToWords.toWords(number));
                } else {
                  setTurnoverInWords("");
                }
                formik.handleChange(e);
              }
            }}
            error={formik.touched.turnover && Boolean(formik.errors.turnover)}
            helperText={formik.touched.turnover && formik.errors.turnover}
            // error={formik.errors.turnover}
            // helperText={formik.errors.turnover}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter your monthly profit"} />
          <GlobalInput
            type="tel"
            inputMode="numeric"
            onBlur={formik.handleBlur}
            placeholder={"Enter Your Monthly Profit"}
            id={"monthlyProfit"}
            name={"monthlyProfit"}
            value={formData?.employmentDetails?.monthlyProfit}
            onChange={(e, newValue) => {
              if (/^\d*$/.test(e.target.value) && e.target.value.length <= 7) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      monthlyProfit: e.target.value,
                    },
                  })
                );
                const number = parseFloat(e.target.value);
                if (!isNaN(number) && isFinite(number)) {
                  setMonthlyProfitInWords(numberToWords.toWords(number));
                } else {
                  setMonthlyProfitInWords("");
                }
                formik.handleChange(e);
              }
            }}
            error={
              formik.touched.monthlyProfit &&
              Boolean(formik.errors.monthlyProfit)
            }
            helperText={
              formik.touched.monthlyProfit && formik.errors.monthlyProfit
            }
            // error={formik.errors.monthlyProfit}
            // helperText={formik.errors.monthlyProfit}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter your GST No."} />
          <GlobalInput
            type={""}
            onBlur={formik.handleBlur}
            placeholder={"Enter GST Number"}
            id={"gst"}
            name={"gst"}
            value={formData?.employmentDetails?.gst}
            onChange={(event, newValue) => {
              const inputValue = event.target.value.toUpperCase();
              if (event.target.value.length <= 15) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      gst: inputValue,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={formik.touched.gst && Boolean(formik.errors.gst)}
            helperText={formik.touched.gst && formik.errors.gst}
            // error={formik.errors.gst}
            // helperText={formik.errors.gst}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable
            lableText={"With which bank is the Business/Current account"}
          />
          <CommonDropdown
            id={"currentAccountBankId"}
            name={"currentAccountBankId"}
            options={BankListData ? BankListData : ""}
            onBlur={formik.handleBlur}
            value={
              BankListData?.find(
                (option) =>
                  formData?.employmentDetails?.currentAccountBankId ===
                  option.value
              )?.label
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    currentAccountBankId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("currentAccountBankId", newValue);
            }}
            error={
              formik.touched.currentAccountBankId &&
              Boolean(formik.errors.currentAccountBankId)
            }
            helperText={
              formik.touched.currentAccountBankId &&
              formik.errors.currentAccountBankId
            }
            // error={formik.errors.currentAccountBankId}
            // helperText={formik.errors.currentAccountBankId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable
            lableText={"With which bank is the Primary/Saving account?"}
          />
          <CommonDropdown
            id={"savingAccountBankId"}
            name={"savingAccountBankId"}
            options={BankListData ? BankListData : ""}
            onBlur={formik.handleBlur}
            value={
              BankListData?.find(
                (option) =>
                  formData?.employmentDetails?.savingAccountBankId ===
                  option.value
              )?.label
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    savingAccountBankId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("savingAccountBankId", newValue);
            }}
            error={
              formik.touched.currentAccountBankId &&
              Boolean(formik.errors.currentAccountBankId)
            }
            helperText={
              formik.touched.currentAccountBankId &&
              formik.errors.currentAccountBankId
            }
            // error={formik.errors.savingAccountBankId}
            // helperText={formik.errors.savingAccountBankId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CommonButton buttonText={"Save"} />
        </Grid>
      </Grid>
    </form>
  );
};

export default SelfEmployed;
