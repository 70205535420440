import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import offer1 from "../../../../../../../images/offer1.png";
import { useApiCallMutation } from "../../../../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../../../../config/apiEndPointConfig";
import {
  setIsLoading,
  setOffersList,
} from "../../../../../../../store/reducers";
function Offer2() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const promotionalOfferData = useSelector((state) => state.form?.offersList);
  const isLoading = useSelector((state) => state?.form?.isLoading);
  useEffect(() => {
    getPromotionalOffers();
  }, []);
  const [getPromotionalOfferApi, getPromotionalOfferApiData] =
    useApiCallMutation({});
  const getPromotionalOffers = () => {
    getPromotionalOfferApi({
      endPoint: apiEndPointsConfig?.getPromotionalOffer,
      method: "POST",
      data: {},
    });
    dispatch(setIsLoading(true));
  };
  useEffect(() => {
    if (getPromotionalOfferApiData?.isSuccess) {
      dispatch(
        setOffersList(getPromotionalOfferApiData?.data?.data?.promotionalOffers)
      );
      dispatch(setIsLoading(false));
    }
  }, [getPromotionalOfferApiData]);
  useEffect(() => {
    window.onpopstate = () => {
      navigate("/");
    };
  }, []);
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const salaried = [
    {
      title: "Get Personal Loan For Self Employed",
      buttonText: "Request Loan",
    },
  ];
  const cardStyling = {
    border: "1px solid #949495",
    borderRadius: "10px",
    // padding: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "all .4s ease",
    // width: { xs: "90%", sm: "75%", md: "100%", lg: "70%" },
    width: isMobile ? "100%" : "220px",
    // minHeight: isMobile ? "150px" : "220px",
  };

  return (
    <>
      <Container
        maxWidth={"md"}
        sx={{ padding: { xs: "0 5%", sm: "0 9%", md: "0 7.7%" } }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "26px" },
                fontWeight: "700",
                marginTop: "30px",
                marginBottom: "15px",
                color: "#3B5BBA",
              }}
            >
              Congratulations
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: "lighter",
                color: "#949495",
                marginBottom: "20px",
              }}
            >
              Hi, Welcome back! Your application is in our hands, and we're
              working hard to make it happen. We won't disappoint you! In the
              meantime, check out some exclusive offers just for you!
            </Typography>
          </Box>
          <Box width={"100%"}>
            <Typography
              sx={{
                fontSize: { xs: "20px" },
                fontWeight: "lighter",
                color: "#3B5BBA",
              }}
            >
              Sponsored Ads-
            </Typography>
            <Box
              sx={{ flexWrap: "wrap" }}
              padding={"20px 40px"}
              gap={"20px"}
              justifyContent="center"
              alignItems="center"
              display={"flex"}
            >
              {isLoading ? (
                <CircularProgress />
              ) : promotionalOfferData.length > 0 ? (
                promotionalOfferData.map((e) => {
                  return (
                    <Link target="_blank" to={e.destinationUrl}>
                      <Card
                        sx={{
                          ...cardStyling,
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={e.bannerUrl}
                          alt="offer1"
                        />
                      </Card>
                    </Link>
                  );
                })
              ) : (
                "No Offers "
              )}
            </Box>
          </Box>
        </Grid>
      </Container>
    </>
  );
}

export default Offer2;
