import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import support from "../../images/Support.svg";
import email from "../../images/mark_email_unread.svg";
import call from "../../images/call.svg";

const Support = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "450px" }, height: "250px" }}>
        <img src={support} style={{ width: "100%", height: "100%" }} alt="" />
      </Box>

      <Typography
        sx={{ fontSize: { xs: "30px", sm: "40px" }, fontWeight: 700 }}
      >
        Support
      </Typography>
      <Typography sx={{ fontSize: "12px", color: "#343435" }}>
        Have questions, hit us up at
      </Typography>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          textAlign: "center",
          alignItems: { xs: "center" },
          justifyContent: "space-evenly",
          padding: "25px",
          width: "60%",
          border: "1px dashed #E1E1E1",
          mt: 2,
        }}
      >
        <Typography sx={{display:"flex",alignItems:"center",justifyContent:"center",fontSize:{xs:"12px",sm:"15px"}}}>
          <img src={email} alt="" />
          support@credmudra.com
        </Typography>
        <Typography sx={{display:"flex",alignItems:"center",justifyContent:"center",fontSize:{xs:"12px",sm:"15px"}}}>
          <img src={call} alt="" />
          866-540-6005
        </Typography>
      </Box>
      <Box
        mt={2}
        sx={{
          display: { xs: "block", md: "flex" },
          gap: "40px",
          padding: {xs:"20px",sm:"30px"},
          fontSize:{xs:"14px"}
        }} 
      >
        <li>
          {" "}
          <b>Sat</b> 10am - 6pm IST
        </li>
        <li>
          <b>Mon - Fri</b> 9am - 7pm IST
        </li>
        <li>
          <b>Sun</b> Closed
        </li>
      </Box>
    </Box>
  );
};

export default Support;
