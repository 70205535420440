import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { useEffect } from "react";
import { logoutAllTabs } from "./routes/Authentication";

function App() {
  useEffect(() => {
    logoutAllTabs()
   }, [])
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
