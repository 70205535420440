import React, { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import EditIcon from "../../images/editIcon.svg";
import { Box, CircularProgress, Typography } from "@mui/material";
import userImg from "../../images/user__1__480.png";
import { mdiLightbulbOn } from "@mdi/js";
import Icon from "@mdi/react";
import { useDispatch, useSelector } from "react-redux";
import { setprofillePercentage } from "../../store/reducers";
import useProfilePercentage from "../../utils/ProfilePercentage";
const ProfileImage = () => {
  const inputRef = useRef(null);
  const percentageFilled = useProfilePercentage();
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null);
  const firstName = useSelector(
    (state) => state.form.formData.personalInfo.firstName
  );
  const userDetails =
    sessionStorage.getItem("leadData") !== undefined
      ? JSON.parse(sessionStorage.getItem("leadData"))
      : "";
  const publicLeadId =
    sessionStorage.getItem("publicLeadId") !== undefined
      ? sessionStorage.getItem("publicLeadId")
      : "";

  const handleEditClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const percentageCompleted = publicLeadId ? 100 : percentageFilled;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: { sm: "wrap", lg: "nowrap" },
        // width: { sm: "150px", md: "252px", lg: "270px" },
        justifyContent: { sm: "center" },
        alignItems: { sm: "center" },
        gap: { sm: "10px" },
        width: "100%",
      }}
    >
      <Box>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={handleFileChange}
          capture="environment"
        />
        <div style={{ position: "relative" }}>
          {/* <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <IconButton
                sx={{
                  width: { sm: 18, md: 24 },
                  height: { sm: 18, md: 24 },
                  borderRadius: "50%",
                  bgcolor: "white",
                  "&:hover": {
                    bgcolor: "white",
                  },
                }}
                aria-label="edit"
                size="small"
                onClick={handleEditClick}
              >
                <img src={EditIcon} alt="Edit" />
              </IconButton>
            }
          >
            <Avatar
              alt="User Avatar"
              src="/static/images/avatar/1.jpg"
              sx={{ width: { sm: 56, md: 88 }, height: { sm: 56, md: 88 } }}
            />
          </Badge> */}
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            // badgeContent={
            //   <IconButton
            //     sx={{
            //       width: 24,
            //       height: 24,
            //       borderRadius: "50%",
            //       bgcolor: "white",
            //       "&:hover": {
            //         bgcolor: "white",
            //       },
            //     }}
            //     aria-label="edit"
            //     size="small"
            //     onClick={handleEditClick}
            //   >
            //     <img src={EditIcon} alt="Edit" />
            // </IconButton>
            // }
          >
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%) rotate(-90deg)", // Rotate by -90 degrees
              }}
              variant="determinate"
              value={percentageCompleted}
              size={86}
              thickness={2}
            />
            <Avatar
              alt="User Avatar"
              // src={previewImage ? previewImage : "/static/images/avatar/1.jpg"}
              src={userImg}
              sx={{
                width: 80,
                height: 80,
                // border: `2px solid ${borderWidth}`,
                borderRadius: "50%",
              }}
            />
          </Badge>
        </div>
      </Box>
      <Box>
        <Typography
          fontWeight={700}
          sx={{
            fontSize: { sm: "16px", md: "18px", lg: "20px" },
            fontFamily: "Lato",
            textAlign: { sm: "center" },
          }}
        >
          {publicLeadId
            ? `Hi, ${userDetails?.firstName}`
            : firstName
            ? `Hi, ${firstName}`
            : "Hi, User"}
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: "14px", lg: "16px" },
            fontFamily: "Lato",
            textAlign: { sm: "center" },
          }}
        >
          {percentageCompleted}% Profile Complete
        </Typography>
      </Box>
    </Box>
  );
};
export default ProfileImage;
