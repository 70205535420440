import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiInformationOutline } from "@mdi/js";
import { mdiLogout } from "@mdi/js";
import { mdiChevronUp } from "@mdi/js";
import { mdiChevronDown } from "@mdi/js";
import CommanTypo from "../../components/commanTypo";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userImg from "../../images/user__1__480.png";
import useProfilePercentage from "../../utils/ProfilePercentage";
import { resetForm } from "../../store/reducers";
import { logout } from "../../routes/Authentication";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiCallServices";
import apiEndPointsConfig from "../../config/apiEndPointConfig";
import LoanApplicationModal from "../../modals/LoanApplicationModal";
// import Logo from "../../../src/images/credmudra-logo-file-republic-day-theme (2).png";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const percentageFilled = useProfilePercentage();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width:600px)");
  const firstName = useSelector(
    (state) => state.form.formData.personalInfo.firstName
  );

  const [windowOpen, windowOpenData] = useApiCallMutation();

  const userDetails =
    sessionStorage.getItem("leadData") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("leadData"))
      : "";
  const publicLeadId =
    sessionStorage.getItem("publicLeadId") !== "undefined"
      ? sessionStorage.getItem("publicLeadId")
      : "";

  const employmentType = useSelector(
    (state) => state.form.formData.employmentType
  );
  const percentage = useProfilePercentage();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    dispatch(resetForm());
    sessionStorage.clear();
    navigate("/login");
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const OnBannerClick = () => {
    windowOpen({
      endPoint: apiEndPointsConfig?.windowOpen,
      method: "GET",
    });
  };

  useEffect(() => {
    if (windowOpenData.isSuccess) {
      if (windowOpenData.data?.data?.open) {
        if (percentage === 100) {
          if (employmentType === "Self Employed") {
            navigate("/profiling/dashboard/businessLoan");
          } else if (employmentType === "Salaried") {
            navigate("/profiling/dashboard/personalLoan");
          }
        }
      } else {
        setIsWindowOpen(true);
        navigate("/profiling/dashboard/products");
      }
    }
  }, [windowOpenData]);

  const percentageCompleted = publicLeadId ? 100 : percentageFilled;
  const [logoutUserApi, logoutUserData] = useApiCallMutation();

  const logoutFunc = () => {
    logoutUserApi({
      endPoint: apiEndPointsConfig?.logoutUser,
      method: "GET",
    });
    logout();
  };

  return (
    <>
      <AppBar
        sx={{
          boxShadow: "none",
          backgroundColor: "#3653A9",
          zIndex: "10000",
        }}
      >
        <Toolbar sx={{ paddingX: { xs: 2, md: "0 4.3%" } }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            paddingX={{ xs: "0px", sm: "24px", md: "48px" }}
          >
            <Box
              sx={{
                width: { xs: "140px", sm: "170px", md: "200px" },
                paddingLeft: { xs: "0px", sm: "50px", md: "37px" },
              }}
            >
              <img
                onClick={() => {
                  OnBannerClick();
                }}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/logo_images/credmudra_logo_new.png"
                }
                // src={Logo}
                alt="Credmudra Logo"
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                paddingRight: { xs: "0px", md: "50px" },
              }}
            >
              {/* Profile name */}
              {!isMobile && (
                <Typography
                  sx={{ color: "#FFF", marginRight: "8px", fontSize: "15px" }}
                >
                  {publicLeadId
                    ? `Hi, ${userDetails?.firstName}`
                    : firstName
                    ? `Hi, ${firstName}`
                    : "Hi, User"}
                </Typography>
              )}

              <Box
                sx={{
                  display: "flex",
                  height: { xs: "32px", sm: "42px" },
                  width: { xs: "32px", sm: "42px" },
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={userImg}
                  alt="Profile"
                  style={{
                    width: "42px",

                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                {/* Expand icon */}
              </Box>
              {/* Profile image */}
              <IconButton
                onClick={handleMenu}
                size="large"
                sx={{ p: 1, bgcolor: "transparent", color: "#FFF" }}
              >
                {open ? (
                  <Icon path={mdiChevronUp} size={1} color="#FFF" />
                ) : (
                  <Icon path={mdiChevronDown} size={1} color="#FFF" />
                )}
              </IconButton>
              {/* Dropdown */}
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                getContentAnchorEl={null}
                elevation={1}
                sx={{ marginTop: "10px" }}
              >
                <MenuItem onClick={handleClose}>
                  <Typography sx={{ color: "#3653A9", fontWeight: "bold" }}>
                    {publicLeadId
                      ? `Hi, ${userDetails?.firstName}`
                      : firstName
                      ? `Hi, ${firstName}`
                      : "Hi, User"}
                    {/* {firstName ? `Hi, ${firstName}` : "Hi, User"} */}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <CommanTypo
                    image={
                      process.env.PUBLIC_URL +
                      "/assets/Icons/solid_light-bulb.svg"
                    }
                    text={`${percentageCompleted}% profile complete`}
                    bgcolor={"none"}
                    attr={"none"}
                  />
                </MenuItem>
                <Divider />

                <Link
                  to="/profiling/profile/personaldetails"
                  style={{ textDecoration: "none", color: "#717172" }}
                >
                  <MenuItem onClick={handleClose}>
                    <Icon
                      path={mdiAccountCircleOutline}
                      color="#717172"
                      size={1}
                    />
                    <Typography sx={{ marginLeft: 1 }}>Profile</Typography>
                  </MenuItem>
                </Link>
                <Link
                  to="/support"
                  style={{ textDecoration: "none", color: "#717172" }}
                >
                  <MenuItem onClick={handleClose}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        path={mdiInformationOutline}
                        size={1}
                        color="#717172"
                      />
                      <Typography sx={{ marginLeft: 1, color: "#717172" }}>
                        Support
                      </Typography>
                    </Box>
                  </MenuItem>
                </Link>
                <MenuItem onClick={logoutFunc}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Icon path={mdiLogout} size={1} color="#717172" />
                    <Typography sx={{ marginLeft: 1, color: "#717172" }}>
                      Logout
                    </Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Logout Confirmation Dialog */}

      <Dialog open={logoutDialogOpen} onClose={handleLogoutCancel}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to logout?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} sx={{ color: " #3B5BBA" }}>
            Cancel
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            variant="contained"
            sx={{ backgroundColor: " #3B5BBA" }}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* <LoanApplicationModal isOpen={isWindowOpen} setIsOpen={setIsWindowOpen} /> */}
    </>
  );
};

export default Header;
