import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import InfoModal from "../InfoModal";

const LoanApplicationModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={isOpen}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
      }}
    >
      <Box
        sx={{
          width: { xs: "95%", sm: "50%", lg: "30%" },
          height: { xs: "300px", lg: "450px" },
          // borderBottomRightRadius: "20px",
          // borderBottomLeftRadius: "20px",
          borderRadius: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "60%",
            backgroundImage: "linear-gradient(#3B5BBA, #334D99)",
            // borderBottomRightRadius: "40px",
            // borderBottomLeftRadius: "40px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: {
                  xs: "22px",
                  sm: "25px",
                  md: "28px",
                  lg: "28px",
                  xl: "32px",
                },
                fontWeight: "700",
              }}
            >
              Loan Application in Progress
            </Typography>
          </Box>
          <Box
            sx={{
              padding: { xs: "10px", md: "20px 80px" },
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px", lg: "16px", xl: "18px" },
              }}
            >
              You have applied for a loan within the last 24 hours, Please try
              after 24 hours.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "30px 0px", lg: "60px 0px" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3550A1",
              padding: { xs: "10px 20px", lg: "15px 30px" },
              fontSize: { xs: "12px", lg: "16px" },
              borderRadius: "15px",
            }}
            onClick={() => {
              setIsOpen(false);
              // navigate("/profiling/dashboard/products");
            }}
          >
            Go To Dashboard
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LoanApplicationModal;
