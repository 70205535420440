import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import InfoModal from "../InfoModal";
import Congratulation from "../../images/congratulations.svg";
import CrossArrow from "../../images/CrossIcon.svg";

const CongratulationModal = ({ isOpen, setIsOpen, onApply }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    sessionStorage.setItem("isCongratution", false);
    setIsOpen(false);
  };

  const onBtnClick = () => {
    onApply();
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
      }}
    >
      <Box
        sx={{
          width: { xs: "50%", sm: "50%", lg: "30%" },
          height: { xs: "300px", lg: "450px" },
          // borderBottomRightRadius: "20px",
          // borderBottomLeftRadius: "20px",
          borderRadius: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "60%",
            backgroundImage: "linear-gradient(#3B5BBA, #334D99)",
            // borderBottomRightRadius: "40px",
            // borderBottomLeftRadius: "40px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Box
            sx={{ position: "relative", top: "-14%", left: "48%" }}
            onClick={handleClose}
          >
            <img src={CrossArrow} alt="cancle" width={"100%"} />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: {
                  xs: "22px",
                  sm: "25px",
                  md: "28px",
                  lg: "28px",
                  xl: "32px",
                },
                fontWeight: "700",
              }}
            >
              Congratulations{" "}
              <span>
                <img
                  src={Congratulation}
                  alt=""
                  //   style={{
                  //     marginTop: isMobile ? 0 : "-25px",
                  //     width: "70%",
                  //   }}
                />
              </span>
            </Typography>
          </Box>
          <Box
            sx={{
              padding: { xs: "10px", md: "20px 80px" },
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px", lg: "16px", xl: "18px" },
              }}
            >
              Thanks for taking the first step! You're pre-approved for a loan.
              Click 'Apply Now' to see your options.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "30px 0px", lg: "60px 0px" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3550A1",
              padding: { xs: "10px 20px", lg: "15px 30px" },
              fontSize: { xs: "12px", lg: "16px" },
              borderRadius: "15px",
            }}
            onClick={() => {
              onBtnClick();
              // navigate("/profiling/dashboard/products");
            }}
          >
            Apply Now
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CongratulationModal;
