import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Cookies from "js-cookie";
import Logo from "../../../../src/images/credmudra_logo_new.webp"
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Bowser from "bowser";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApiCallMutation } from "../../../services/apiCallServices";
import apiEndPointsConfig from "../../../config/apiEndPointConfig";
import { setFormData } from "../../../store/reducers";
import { userNumberSchema } from "../../../utils/validateSchema";
import { getInitialUserNumberValues } from "../../../utils/initialValues";
import GlobalInputLable from "../../../components/globalInputLable";
import GlobalInput from "../../../components/globalInput";
import Loader from "../../../components/Loader/page";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getBaseUrl } from "../../../config/baseUrls";
const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:900px)");
  const isSmallScreen = useMediaQuery("(max-width: 576px)");
  const logoWidth = isSmallScreen ? "100px" : "245px";
  const baseUrl = getBaseUrl();
  //utms tracking
  const getQueryParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };
  const currentPath = location.pathname;
  const extraDetails = async () => {
    const browser = Bowser.getParser(navigator.userAgent);
    let position = null;
    let ipAddress = "";
    try {
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      if (!ipResponse.ok) {
        throw new Error("Failed to fetch IP address");
      }
      const ipData = await ipResponse.json();

      ipAddress = ipData.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
    try {
      position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    } catch (error) {}
    dispatch(
      setFormData({
        extras: {
          ...formData.extras,
          browser:
            browser?.parsedResult.browser.name +
            " " +
            browser?.parsedResult.browser.version,
          operatingSystem: browser?.parsedResult.os.name,
          ipAddress: ipAddress,
          userAgent: "",
          location: "",
          timestamp: position?.timestamp,
        },
      })
    );

    const extras = {
      ...formData.extras,
      browser:
        browser?.parsedResult.browser.name +
        " " +
        browser?.parsedResult.browser.version,
      operatingSystem: browser?.parsedResult.os.name,
      ipAddress: ipAddress,
      userAgent: "",
      location: "",
      timestamp: position?.timestamp,
    };
    sessionStorage.setItem(
      "extras",
      JSON.stringify({
        ...formData.extras,
        browser:
          browser?.parsedResult.browser.name +
          " " +
          browser?.parsedResult.browser.version,
        operatingSystem: browser?.parsedResult.os.name,
        ipAddress: ipAddress,
        userAgent: "",
        location: "",
        timestamp: position?.timestamp,
      })
    );
  };
  const set_utm = () => {
    const utm_id = getQueryParam("utm_id");
    const utm_url = getQueryParam("utm_url");
    const utm_source = getQueryParam("utm_source");
    const utm_medium = getQueryParam("utm_medium");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_term = getQueryParam("utm_term");
    const utm_content = getQueryParam("utm_content");

    //setting utms in cookies
    const utmsData = localStorage.getItem("utms");
    if (!utmsData) {
      localStorage.setItem(
        "utms",
        JSON.stringify({
          id: utm_id,
          url: utm_url,
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: utm_content,
          clickId: "",
        }),
        { expires: 30, secure: true }
      );
    } else if (utmsData && utm_source !== null) {
      localStorage.setItem(
        "utms",
        JSON.stringify({
          id: utm_id,
          url: utm_url,
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: utm_content,
          clickId: "",
        }),
        { expires: 30, secure: true }
      );
    }
    const postUtmsData = JSON.parse(localStorage.getItem("utms"));
    dispatch(
      setFormData({
        utm: {
          id: postUtmsData.id || utm_id,
          url: postUtmsData.url || utm_url,
          source: postUtmsData.source || utm_source,
          medium: postUtmsData.medium || utm_medium,
          campaign: postUtmsData.campaign || utm_campaign,
          term: postUtmsData.term || utm_term,
          content: postUtmsData.content || utm_content,
          clickId: "",
        },
      })
    );
  };
  //annonymous Api Call
  const [annonymousApi, annonymousApiData] = useApiCallMutation();
  const getAnonymousUserId = async () => {
    const postUtmsData = JSON.parse(localStorage.getItem("utms"));
    const utm_id = getQueryParam("utm_id");
    const utm_url = getQueryParam("utm_url");
    const utm_source = getQueryParam("utm_source");
    const utm_medium = getQueryParam("utm_medium");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_term = getQueryParam("utm_term");
    const utm_content = getQueryParam("utm_content");

    const browser = Bowser.getParser(navigator.userAgent);
    var position = "";
    var ipAddress = "";
    try {
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      if (!ipResponse.ok) {
        throw new Error("Failed to fetch IP address");
      }
      const ipData = await ipResponse.json();

      ipAddress = ipData.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        position = pos;
      },
      (err) => {
        console.log(`Error getting location: ${err.message}`);
      }
    );
    const data = {
      utm: {
        id: postUtmsData.id || utm_id,
        url: postUtmsData.url || utm_url,
        source: postUtmsData.source || utm_source,
        medium: postUtmsData.medium || utm_medium,
        campaign: postUtmsData.campaign || utm_campaign,
        term: postUtmsData.term || utm_term,
        content: postUtmsData.content || utm_content,
        clickId: "",
      },
      extra: {
        browser:
          browser?.parsedResult.browser.name +
          " " +
          browser?.parsedResult.browser.version,
        operatingSystem: browser?.parsedResult.os.name,
        ipAddress: ipAddress,
        timestamp: "",
        userAgent: "",
        location: position?.timestamp,
      },
      path: currentPath,
    };
    annonymousApi({
      endPoint: apiEndPointsConfig?.anonymousUser,
      method: "POST",
      data: { data },
    });
  };
  useEffect(() => {
    if (annonymousApiData?.isSuccess) {
      sessionStorage.setItem(
        "anonymousId",
        annonymousApiData?.data?.data?.anonymousId
      );
    }
  }, [annonymousApiData]);
  useEffect(() => {
    const anonymousId = sessionStorage.getItem("anonymousId");
    extraDetails();
    set_utm();
    if (!anonymousId) {
      getAnonymousUserId();
    }
  }, []);
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  useEffect(() => {
    const publicLeadId =
      sessionStorage.getItem("publicLeadId") !== undefined
        ? sessionStorage.getItem("publicLeadId")
        : "";
    const userDetails =
      localStorage.getItem("number") !== undefined
        ? JSON.parse(localStorage.getItem("number"))
        : "";
    if (publicLeadId) {
      dispatch(setFormData({ ...formData, contactNo: userDetails?.contactNo }));
    }
  }, []);
  //Api Initialization
  const [SendOtpApi, sendOptData] = useApiCallMutation();
  const [Read, setRead] = useState(false);
  const toggleRead = () => {
    setRead(true);
  };
  const offRead = () => {
    setRead(false);
  };
  //onsubmit
  const handleSubmit = async () => {
    const anonymousId = sessionStorage.getItem("anonymousId") || "";
    const data = {
      contactNo: formData?.contactNo,
      resend: false,
      anonymousId: anonymousId || "",
    };
    SendOtpApi({
      endPoint: apiEndPointsConfig.SendOtp,
      method: "POST",
      data: {
        data,
      },
    });
  };
  useEffect(() => {
    if (sendOptData.isSuccess) {
      sessionStorage.setItem("number", formData?.contactNo);
      navigate("/validate-otp");
    }
  }, [sendOptData]);

  const formik = useFormik({
    initialValues: getInitialUserNumberValues(formData),
    validationSchema: userNumberSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const user = sessionStorage.getItem("accessToken");
    const number = sessionStorage.getItem("number");
    if (user) {
      navigate(-1);
    } else if (number) {
      navigate("/validate-otp");
    }
  });

  const CircleIcon = {
    color: "#4769D0",
    fontSize: "15px",
    marginRight: "8px",
  };
  const ListStyling = {
    padding: "0px",
    fontSize: { xs: "16px", sm: "12px" },
    margin: { xs: "0px 0px 5px" },
    fontFamily: "Inter,sans-serif",
    color: "#000",
  };

  return (
    <>
      {sendOptData?.isLoading && <Loader />}
      <Box sx={{ width: "100%" }}>
        <Grid container sx={{ mb: { xs: 4, md: 0 } }}>
          <Grid
            item
            sx={{
              background:
                "linear-gradient(4deg, #6582DB -39.43%, #3156C7 45.69%)",
              display: "grid",

              height: { md: "100vh", xs: "200px", lg: "100vh" },
            }}
            xs={12}
            sm={12}
            md={6}
          >
            <Box
              sx={{
                position: "absolute",
                mt: { xs: 1, sm: 3, md: 5 },
                ml: { xs: 2, sm: 3, md: 5 },
                width: { xs: "30%", sm: "20%", md: "22%", lg: "12%" },
              }}
            >
              <img width={"100%"} alt="logo" src={Logo} />
            </Box>
            <Box
              sx={{
                backgroundImage: `url( ${process.env.PUBLIC_URL}/assets/commonImages/sidebackgroundImg.png)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "25%", sm: "20%", md: "30%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: { md: "100vh", xs: "200px", lg: "100vh" },
              }}
            >
              <Box
                sx={{
                  height: { xs: "80%", md: "40%", lg: "50%" },
                }}
              >
                <img
                  height={"100%"}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/commonImages/Group 1000005215.png"
                  }
                  alt="logo"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Container
                maxWidth={"md"}
                sx={{
                  ...(isDesktop && {
                    overflowY: "scroll",
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                  }),
                  height: "100vh",

                  width: {
                    xs: "100%",
                    sm: "80%",
                    md: "70%",
                  },

                  paddingLeft: { xs: "10px", sm: "0px" },
                }}
              >
                <Box
                  sx={{
                    paddingLeft: { xs: "10px", sm: "30px" },
                    paddingTop: { xs: "0px", md: "15%", lg: "15%" },
                  }}
                >
                  {/* <Box sx={{ mt: { xs: "10px", md: "0px" } }}>
                    <img style={{ width: logoWidth }} alt="logo" src={Logo} />
                  </Box> */}
                  <Box mt={4}>
                    <Box sx={{ mt: { xs: "10px", sm: 7 } }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: { xs: "1.5rem", sm: "2rem" },
                        }}
                      >
                        Login &#9995;{" "}
                      </Typography>
                      <Typography
                        mt={1}
                        sx={{ fontSize: { xs: "13px", sm: "16px" } }}
                      >
                        Find the Best Personal Loan Offers from Over 30 Lenders
                        Personalised to Your Needs
                      </Typography>
                    </Box>
                    <Box>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            marginTop: "10px",
                          }}
                        >
                          <GlobalInputLable
                            lableText={"Enter your mobile number"}
                            align={"left"}
                          />
                          <GlobalInput
                            type="tel"
                            inputMode="numeric"
                            placeholder={"Enter Your Mobile Number"}
                            id="contactNo"
                            name="contactNo"
                            value={formData?.contactNo}
                            style={{ fontSize: "0.6rem" }}
                            onChange={(e) => {
                              if (
                                /^\d*$/.test(e.target.value) &&
                                e.target.value.length <= 10
                              ) {
                                dispatch(
                                  setFormData({
                                    contactNo: e.target.value,
                                  })
                                );
                                formik.handleChange(e);
                              }
                            }}
                            error={
                              formik.touched.contactNo &&
                              Boolean(formik.errors.contactNo)
                            }
                            helperText={
                              formik.touched.contactNo &&
                              formik.errors.contactNo
                            }
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "9px",
                            // width: { xs: "100%", sm: "440px" },
                            marginTop: "10px",
                          }}
                        >
                          <Checkbox
                            sx={{ padding: "0" }}
                            id="termsAndCondition"
                            name="termsAndCondition"
                            onChange={(e) => {
                              dispatch(
                                setFormData({
                                  termsAndCondition: e.target.checked,
                                })
                              );
                              formik.setFieldValue(
                                "termsAndCondition",
                                e.target.checked
                              );
                            }}
                            onBlur={formik.handleBlur}
                            checked={formData?.termsAndCondition}
                          />
                          {/* <Typography
                          sx={{
                            color: "#666",
                            fontSize: { xs: "0.8rem", md: "13px", lg: "13px" },
                          }}
                        >
                         By continuing, you agree to our
                          <Link
                            target="_blank"
                            style={{ color: "#3B5BBA" }}
                            to="https://www.credmudra.com/privacy-policy"
                          >
                            {" "}
                            Privacy Policy{" "}
                          </Link>{" "}
                          and
                          <Link
                            target="_blank"
                            style={{ color: "#3B5BBA" }}
                            to="https://www.credmudra.com/terms-and-conditions"
                          >
                            {" "}
                            Terms & Conditions{" "}
                          </Link>{" "}
                          I grant permission to Credmudra, its Lending Partners, and affiliated entities to contact me for various communication via Phone, SMS, E-mail, and WhatsApp for loans, credit cards, or other relevant information or promotions. 
                      
                        {Read ? (
                      <>
                      Additionally,  I hereby consent to Boost.Money and EMKAY CONSULTANTS LIMITED being appointed as my authorized representative to receive my Credit Information from Experian for the purpose of Credit Assessment to advise me on the financial journey, on ongoing basis for not exceeding a period of six (6) months. <br />I also agree
                        to{" "}
                        <Link
                          target="_blank"
                          style={{ color: "#3B5BBA" }}
                          to="https://www.credmudra.com/experian-Terms-and-conditions"
                        >
                          Experian's Terms and Conditions.
                        </Link>
                        <br />
                        <Link
                          target="_blank"
                          style={{ color: "#3B5BBA" }}
                          onClick={offRead}
                          to={""}
                        >
                          Read Less{" "}
                        </Link>
                      </>
                    ) : (
                      <Link
                      target="_blank"
                      style={{ color: "#3B5BBA" }}
                        onClick={toggleRead}
                        to={""}
                      >
                        Read More{" "}
                      </Link>
                    )}
                        </Typography> */}
                          <Typography
                            sx={{ fontSize: "14px", color: "#AAAAAA" }}
                          >
                            By continuing, you agree to our{" "}
                            <Link
                              target="_blank"
                              style={{ color: "#3B5BBA" }}
                              to={`${baseUrl}privacy-policy`}
                            >
                              Privacy Policy
                            </Link>{" "}
                            and {"  "}
                            <Link
                              target="_blank"
                              style={{ color: "#3B5BBA" }}
                              to={`${baseUrl}terms-and-conditions`}
                            >
                              Terms & Conditions
                            </Link>
                            {"  "}.{"  "} I grant permission to Credmudra, its
                            Lending Partners, and affiliated entities to contact
                            me for various communication via Call SYNC, SMS,
                            E-mail, and WhatsApp for loans, credit cards, or
                            other relevant information or promotions.{" "}
                            {Read ? (
                              <>
                                Additionally, I hereby consent to Boost.Money
                                and EMKAY CONSULTANTS LIMITED being appointed as
                                my authorized representative to receive my
                                Credit Information from Experian for the purpose
                                of Credit Assessment to advise me on the
                                financial journey, on ongoing basis for not
                                exceeding a period of six (6) months.
                                <br />I also agree to{" "}
                                <Link
                                  target="_blank"
                                  style={{ color: "#3B5BBA" }}
                                  to={`${baseUrl}experian-terms-and-conditions`}
                                >
                                  Experian's Terms and Conditions.
                                </Link>
                                <br />
                                <Link
                                  style={{ color: "#3B5BBA" }}
                                  onClick={offRead}
                                  to={""}
                                >
                                  Read Less{" "}
                                </Link>
                              </>
                            ) : (
                              <Link
                                style={{ color: "#3B5BBA" }}
                                onClick={toggleRead}
                                to={""}
                              >
                                Read More{" "}
                              </Link>
                            )}
                          </Typography>
                        </Grid>
                        {formik.errors.termsAndCondition &&
                          formik.touched.termsAndCondition && (
                            <div
                              style={{
                                color: "#d32f2f",
                                fontSize: "0.75rem",
                                marginTop: "4px",
                                marginLeft: "14px",
                                marginRight: "14px",
                              }}
                            >
                              {formik.errors.termsAndCondition}
                            </div>
                          )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          lg={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              backgroundColor: "#3B5BBA",
                              width: "100%",
                              height: "48px",
                              mt: { xs: "13px", sm: 8 },
                              fontSize: { xs: "13px", sm: "14px" },
                            }}
                          >
                            Login
                          </Button>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    paddingTop: "60px ",
                  }}
                >
                  <Container sx={{ display: "block", paddingRight: "0px" }}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "700",
                          marginBottom: "15px",
                          marginTop: "20px",
                          color: "#243771",
                        }}
                      >
                        Features and Benefits of Personal Loans:
                      </Typography>
                      <List>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Personal loans come with no restrictions on how the
                            funds are used.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            The loan amount can go up to Rs. 40 lakh, or even
                            higher based on the discretion of the lenders.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Repayment tenure ranges from a minimum of 3 months
                            to a maximum of 5 years, banks/NBFCs offering longer
                            durations.
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Minimal documentation is required to apply for a
                            personal loan.
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Quick disbursals ensure fast access to the funds.{" "}
                          </Typography>
                        </ListItem>

                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Individuals with excellent credit profiles may
                            qualify for pre-approved or pre-qualified personal
                            loans, which come with instant disbursal.
                          </Typography>
                        </ListItem>
                      </List>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "700",
                          marginBottom: "15px",
                          color: "#243771",
                          marginTop: "20px",
                        }}
                      >
                        Eligibility Criteria for Personal Loans:
                      </Typography>

                      <List>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Age: 18 - 60 years{" "}
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Income: Minimum Rs 15,000/month for salaried
                            applicants
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Credit Score: Preferably 750 and above as having
                            higher credit scores increase the chances of your
                            loan approval at lower interest rates
                          </Typography>
                        </ListItem>
                        <ListItem sx={{ ...ListStyling }}>
                          <CheckCircleOutlineIcon sx={{ ...CircleIcon }} />
                          <Typography
                            sx={{
                              marginLeft: "10px",

                              fontSize: "12px",
                              color: "#404040",
                            }}
                          >
                            Eligible only for individuals receiving their salary
                            in a bank account
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "700",
                          marginBottom: "15px",
                          marginTop: "20px",
                          color: "#243771",
                        }}
                      >
                        APR (Annual Percentage Rate) on Personal Loans
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Credmudra isn't a lender itself but connects borrowers
                        with different lending partners. These partners offer
                        personal loans with varying APRs, typically ranging from
                        11.29% to 35%. The actual rates may depend on the
                        borrower's financial situation and loan requirements.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Loan terms usually range from a few weeks to a maximum
                        of 60 months (5 years), but they can vary between
                        lenders. It's important for borrowers to carefully
                        review the loan agreement provided by the lender before
                        accepting any offers. This agreement includes the final
                        APR, fees, and specific terms and conditions, ensuring
                        borrowers understand their financial obligations.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Assume you have availed a personal loan of ₹5,00,000
                        lakhs @ 11.50% p.a. with a repayment tenure of 5 years.
                        The processing fee charged for this loan is 1.5% of the
                        loan amount, i.e. ₹7,500. Therefore, the APR for your
                        personal loan will be 12.16%. The EMI will be ₹10,996.
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Principal Amount: ₹5,00,000{" "}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Interest Charges (@11.5%): ₹1,59,778{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Loan Processing Fees (@1.5%): ₹7,500{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        EMI per month: ₹ 10,996{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        Total Amount paid after 5 Years: ₹ 6,67,278{" "}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        *Interest rate and processing fees varies as per your
                        product depending on the lender's policy.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        <b>Disclaimer: </b> Credmudra functions as a loan
                        aggregator and provides services on behalf of its
                        partners, duly authorized for the purpose.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginBottom: "20px",
                        }}
                      >
                        The registered address is B No.08 Nirupam, PH-II Nirupam
                        state, Ahmedpur kalan Hujur, Bhopal, Madhya Pradesh
                        462026.
                      </Typography>
                    </Box>
                  </Container>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
