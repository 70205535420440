import React from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Icon from "@mdi/react";
import { mdiCheck } from "@mdi/js";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";

function CommonRadioButtons({
  options,
  id,
  name,
  onChange,
  error,
  helperText,
  value,
  matchKey
}) {
  return (
    <>
      <RadioGroup
        aria-label="Your plan"
        id={id}
        name={name}
        sx={{
          // border: error ? "1px solid #d32f2f" : "none",
          borderRadius: "8px",
        }}
      // defaultValue={options[0]?.label}
      >
        <List
          sx={{
            minWidth: 240,
            "--List-gap": "0.5rem",
            "--ListItem-paddingY": "1.2rem",
            "--ListItem-radius": "8px",
            "--ListItemDecorator-size": "32px",
          }}
        >
          {options.map((item, index) => (
            <ListItem
              variant="outlined"
              key={item.label}
              sx={{
                boxShadow: "sm",
              }}
            >
              <Radio
                checkedIcon={<Icon path={mdiCheck} size={1} />}
                overlay
                checked={
                  matchKey === "label" ?
                    item.label === value : item.value === value
                }
                value={item?.label}
                label={item?.label}
                onChange={onChange}
                sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                slotProps={{
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        inset: -1,
                        border: "2px solid",
                        borderColor: theme.vars.palette.primary[500],
                      }),
                    }),
                  }),
                }}
              />
            </ListItem>
          ))}
        </List>
        {error && (
          <div
            style={{ color: "#d32f2f", fontSize: "0.75rem", marginTop: "4px", marginLeft: "14px" }}
          >
            {helperText}
          </div>
        )}
      </RadioGroup>

    </>
  );
}

export default CommonRadioButtons;
