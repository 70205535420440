export const isStepCompleted = (formData, stepName) => {
  switch (stepName) {
    case "PersonalDetails": {
      return !!(
        formData?.personalInfo?.firstName &&
        formData?.personalInfo?.lastName &&
        formData?.personalInfo?.emailId &&
        formData?.personalInfo?.dateOfBirth &&
        formData?.personalInfo?.genderId &&
        formData?.personalInfo?.maritalStatus &&
        formData?.personalInfo?.qualificationId &&
        formData?.panCard &&
        formData?.aadharNo
      );
    }
    case "EmploymentDetails": {
      if (formData?.employmentType === "Salaried") {
        return !!(
          formData?.employmentDetails?.companyTypeId &&
          formData?.employmentDetails?.industryTypeId &&
          formData?.employmentDetails?.companyName &&
          formData?.employmentDetails?.designation &&
          formData?.employmentDetails?.address &&
          formData?.employmentDetails?.pinCode &&
          formData?.employmentDetails?.cityId &&
          formData?.employmentDetails?.stateId &&
          formData?.employmentDetails?.countryId &&
          formData?.employmentDetails?.yearsWorkingIn &&
          formData?.employmentDetails?.income &&
          formData?.employmentDetails?.salaryMode &&
          formData?.employmentDetails?.bankId
        );
      } else if (formData?.employmentType === "Self Employed") {
        return !!(
          formData?.employmentDetails?.businessOwnedId &&
          formData?.employmentDetails?.yearsWorkingIn &&
          formData?.employmentDetails?.businessTypeId &&
          formData?.employmentDetails?.industryTypeId &&
          formData?.employmentDetails?.companyName &&
          formData?.employmentDetails?.address &&
          formData?.employmentDetails?.pinCode &&
          formData?.employmentDetails?.cityId &&
          formData?.employmentDetails?.stateId &&
          formData?.employmentDetails?.countryId &&
          formData?.employmentDetails?.turnover &&
          // formData?.employmentDetails?.gst &&
          formData?.employmentDetails?.monthlyProfit &&
          formData?.employmentDetails?.currentAccountBankId &&
          formData?.employmentDetails?.savingAccountBankId
        );
      } else {
        return false;
      }
    }
    case "ResidenceDetails": {
      return !!(
        formData?.address?.addressLine1 &&
        formData?.address?.addressLine2 &&
        formData?.address?.pinCode &&
        formData?.address?.cityId &&
        formData?.address?.stateId &&
        formData?.address?.countryId &&
        formData?.address?.residenceTypeId &&
        formData?.address?.yearsLivingIn
      );
    }
    case "OtherDetails": {
      return !!(
        formData.others.totalEmiPaidMonthly !== null &&
        formData.others.totalEmiPaidMonthly !== ""
      );
    }
  }
};
