import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoModal = (isOpen) => {
  return (
    <Modal
      open={isOpen}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
      }}
    >
      <Box
        sx={{
          width: { xs: "95%", sm: "50%", lg: "25%" },
          height: { xs: "300px", lg: "450px" },
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "60%",
            backgroundImage: "linear-gradient(#3B5BBA, #334D99)",
            borderBottomRightRadius: "40px",
            borderBottomLeftRadius: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <InfoOutlinedIcon
              sx={{ fontSize: { xs: "40px", lg: "45px", xl: "55px" } }}
            />
          </Box>
          <Box
            sx={{
              padding: { xs: "10px", md: "20px 80px" },
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "14px", lg: "16px", xl: "18px" },
              }}
            >
              We recommend that you fill your profile details first, to get the
              best offers tailored to your needs.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "30px 0px", lg: "60px 0px" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3550A1",
              padding: { xs: "10px 20px", lg: "15px 30px" },
              fontSize: { xs: "12px", lg: "16px" },
              borderRadius: "15px",
            }}
          >
            Go To Profile
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InfoModal;
