import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setExtraDetails,
  setFormData,
  setUtmDetails,
} from "../../../../../../../store/reducers";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../../../../config/apiEndPointConfig";
import { Link, useNavigate } from "react-router-dom";

const Offer = () => {
  const utmDetails = useSelector((state) => state?.form?.utmDetails);
  const employmentType = useSelector(
    (state) => state?.form?.formData.employmentType
  );
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const employTypes = sessionStorage.getItem("employmentType");
  useEffect(() => {
    dispatch(
      setFormData({
        // leadId: "",
        // stage: "",
        // contactNo: "",
        termsAndCondition: true,
        loan: {
          reason: null,
          amount: 1000,
          tenure: 1,
        },

        // panCard: "",
        // aadharNo: "",
        // emailId: "",

        // employmentType: "",

        // employmentDetails: {
        //   companyTypeId: "",
        //   industryTypeId: "",
        //   companyName: "",
        //   designation: "",
        //   address: "",
        //   pinCode: "",
        //   countryId: "",
        //   stateId: "",
        //   cityId: "",
        //   yearsWorkingIn: "",
        //   businessTypeId: "",
        //   professionTypeId: "",
        //   turnover: "",
        //   monthlyProfit: "",
        //   income: "",
        //   salaryMode: "",
        //   bankId: "",
        //   currentAccountBankId: "",
        //   savingAccountBankId: "",
        //   businessOwnedId: "",
        //   gst: "",
        // },
        // personalInfo: {
        //   emailId: "",
        //   firstName: "",
        //   lastName: "",
        //   genderId: "",
        //   dateOfBirth: "",
        //   qualificationId: "",
        //   maritalStatus: "",
        // },
        // address: {
        //   addressLine1: "",
        //   addressLine2: "",
        //   pinCode: "",
        //   countryId: "",
        //   stateId: "",
        //   cityId: "",
        //   residenceTypeId: "",
        //   yearsLivingIn: "",
        // },
        // finance: {
        //   panCard: "",
        //   aadharNo: "",
        // },
        // others: {
        //   totalEmiPaidMonthly: 0,
        //   interestedToGetCreditCard: true,
        // },
        // utm: {
        //   id: "",
        //   url: "",
        //   source: "",
        //   medium: "",
        //   campaign: "",
        //   term: "",
        //   content: "",
        //   clickId: "",
        // },
      })
    );
  }, []);
  const data = {
    leadId: sessionStorage.getItem("tempLead"),
  };
  //get Additional Details
  const GetAdditionalDetails = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getAdditionalInfo,
    method: "POST",
    data: { data },
  });
  useEffect(() => {
    if (GetAdditionalDetails?.isSuccess) {
      // sessionStorage.setItem("extraDetails", GetAdditionalDetails);
      const { extra, utm } = GetAdditionalDetails?.data?.data;
      dispatch(setExtraDetails(extra));
      dispatch(setUtmDetails(utm));
    }
  }, [GetAdditionalDetails?.isSuccess]);

  //googleImpression api call
  const [GoogleImpressionApi, googleImpressionApiData] = useApiCallMutation();
  useEffect(() => {
    googleImpression();
  }, []);
  const googleImpression = () => {
    const data = {
      leadId: sessionStorage.getItem("tempLead"),
    };
    GoogleImpressionApi({
      endPoint: apiEndPointsConfig?.googleExitImpressionStatus,
      method: "POST",
      data: { data },
    });
  };

  useEffect(() => {
    window.onpopstate = () => {
      sessionStorage.clear()
      navigate("/");
    };
  }, []);
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const salaried = [
    {
      title: "Get Personal Loan For Self Employed",
      buttonText: "Request Loan",
    },
    {
      title: "Get Personal Loan For Bad Credit",
      buttonText: "Request Loan",
    },
    {
      title: "Get Personal Loan For Housewife",
      buttonText: "Request Loan",
    },
    {
      title: "Get Personal Loan For Students",
      buttonText: "Request Loan",
    },
    {
      title: "Get Personal Loan For Doctors",
      buttonText: "Request Loan",
    },
  ];
  const Selfemployed = [
    {
      title: "  Self Employed Home Loans",
      buttonText: "Request Loan",
    },
    {
      title: " Personal Loan For Self Employed",
      buttonText: "Request Loan",
    },
    {
      title: "   Funding for Small Businesses",
      buttonText: "Request Loan",
    },
    {
      title: " Starting Small Business Ideas",
      buttonText: "Request Loan",
    },
    {
      title: " Loans for Self Employed with Bad Credit",
      buttonText: "Request Loan",
    },
    {
      title: "  Loans for Small Businesses",
      buttonText: "Request Loan",
    },
  ];

  const cardStyling = {
    border: "1px solid #949495",
    borderRadius: "20px",
    // padding: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "all .4s ease",
    // width: { xs: "90%", sm: "75%", md: "100%", lg: "70%" },
    width: "100%",
    minHeight: isMobile ? "150px" : "250px",
  };

  const cardHeadingStyling = {
    fontSize: "18px",
    fontWeight: "700",
    color: "#3B5BBA",
    lineHeight: "1.2",
  };

  const cardButtonStyling = {
    background: "#3B5BBA",
    borderRadius: "10px",
    color: "#fff",
    display: "inline-block",
    fontSize: "13px",
    padding: "12px 15px",
    textDecoration: "none",
  };
  return (
    <>
      {(employTypes || employmentType) == "Self Employed" ? (
        <Box ref={formRef} sx={{ padding: "30px 0px" }}>
          <Container
            maxWidth={"md"}
            sx={{ padding: { xs: "0 5%", sm: "0 9%", md: "0 7.7%" } }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              spacing={{ xs: 12 }}
            >
              <Grid item xs={12} md={12}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "26px" },
                        fontWeight: "700",
                        marginBottom: "15px",
                        color: "#3B5BBA",
                      }}
                    >
                      Congratulations
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "20px" },
                        fontWeight: "lighter",
                        color: "#949495",
                        marginBottom: "50px",
                      }}
                    >
                      Your application has been submitted successfully. Based on
                      your information, we recommended suitable offers for you
                      from our partners.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "20px" },
                        fontWeight: "lighter",
                        color: "#3B5BBA",
                        marginBottom: "35px",
                      }}
                    >
                      Sponsored Ads-
                    </Typography>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {Selfemployed.map((card, index) => (
                        <Grid key={index} item xs={12} md={6} lg={6}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Card
                              sx={{
                                ...cardStyling,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",

                                  justifyContent: "flex-end",
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Icons/logoicon2.svg"
                                  }
                                  alt="Bottom Left"
                                />
                              </Box>
                              <CardContent>
                                <Typography
                                  sx={{
                                    ...cardHeadingStyling,
                                  }}
                                >
                                  {card.title}
                                </Typography>
                              </CardContent>
                              <Box
                                sx={{
                                  display: "flex",

                                  justifyContent: "flex-start",
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Icons/logoicon1.svg"
                                  }
                                  alt="Top Right"
                                />
                              </Box>
                            </Card>
                            <Box>
                              <Link
                                target="_blank"
                                to={`https://search.zaptkg.com/c/PJmAeN7B1L56yLjx?
                            tg1=${utmDetails?.utmId || ""}
                            &tg2=${utmDetails?.utmSource || ""}
                            &tg3=${utmDetails?.utmMedium || ""}    
                            &tg4=${utmDetails?.utmCampaign || ""}
                            &tg5=${utmDetails?.utmTerm || ""}
                            &txt=${card.title}&src=dp`}
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    ...cardButtonStyling,
                                  }}
                                >
                                  {card.buttonText}
                                </Button>
                              </Link>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      ) : (
        <Box ref={formRef} sx={{ padding: "40px 0" }}>
          <Container
            maxWidth={"xl"}
            sx={{ padding: { xs: "0 5%", sm: "0 9%", md: "0 7.7%" } }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
              spacing={{ xs: 12, md: 4 }}
            >
              <Grid item xs={12} md={12}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", md: "28px" },
                        fontWeight: "900",
                        marginBottom: "15px",
                        color: "#243771",
                      }}
                    >
                      Congratulations
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "20px" },
                        fontWeight: "lighter",
                        color: "#949495",
                        marginBottom: "50px",
                      }}
                    >
                      Your application has been submitted successfully. Based on
                      your information, we recommended suitable offers for you
                      from our partners.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "20px" },
                        fontWeight: "lighter",
                        color: "#3653A9",
                        marginBottom: "35px",
                      }}
                    >
                      Sponsored Ads-
                    </Typography>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {salaried.map((card, index) => (
                        <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Card
                              sx={{
                                ...cardStyling,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",

                                  justifyContent: "flex-end",
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Icons/logoicon2.svg"
                                  }
                                  alt="Bottom Left"
                                />
                              </Box>
                              <CardContent>
                                <Typography
                                  sx={{
                                    ...cardHeadingStyling,
                                  }}
                                >
                                  {card.title}
                                </Typography>
                              </CardContent>
                              <Box
                                sx={{
                                  display: "flex",

                                  justifyContent: "flex-start",
                                }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Icons/logoicon1.svg"
                                  }
                                  alt="Top Right"
                                />
                              </Box>
                            </Card>
                            <Box>
                              <Link
                                target="_blank"
                                to={`https://search.zaptkg.com/c/PJmAeN7B1L56yLjx?
                            tg1=${utmDetails?.utmId || ""}
                            &tg2=${utmDetails?.utmSource || ""}
                            &tg3=${utmDetails?.utmMedium || ""}    
                            &tg4=${utmDetails?.utmCampaign || ""}
                            &tg5=${utmDetails?.utmTerm || ""}
                            &txt=${card.title}&src=dp`}
                              >
                                <Button
                                  variant="contained"
                                  sx={{
                                    ...cardButtonStyling,
                                  }}
                                >
                                  {card.buttonText}
                                </Button>
                              </Link>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};
export default Offer;
