import React from "react";
import { useFormik } from "formik";
import { Box, Container, Grid, Typography } from "@mui/material";
import GlobalInputLable from "../../../../components/globalInputLable";
import CommonButton from "../../../../components/commonButton";
import CommonRadioButtons from "../../../../components/commonRadioButtons";
import InfoBox from "../../../../components/infobox";

function ManageProfile() {
  const formik = useFormik({
    initialValues: {},
    // validationSchema: personalDetailsSchema,
    // onSubmit: handleSubmit,
  });

  const unsubscribe = [
    { value: 1, label: "Yes, Unsubscribe" },
    { value: 2, label: "No, Keep subscription" },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        height: {xs:"auto",md:"100%"},
        // border: "0.2px solid #dfdfdf",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "center",
        gap:"50px",
        paddingTop:"30px"
      }}
    >
      <Container maxWidth="sm">
        <Typography
          sx={{
            color: "#3B5BBA",
            fontSize: { xs: "20px", md: "24px" },
            fontWeight: "700",
            marginBottom: "40px",
            textAlign: "center",
            fontFamily: "Lato",
          }}
        >
          Unsubscribe from email marketing
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowGap={4}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={"Are you sure you want to unsubscribe?"}
              />
              <CommonRadioButtons
                id={"unsubscribe"}
                name={"unsubscribe"}
                options={unsubscribe}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CommonButton buttonText={"Proceed"} />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          /> */}
        </Box>
      </Box>
    </Box>
  );
}

export default ManageProfile;
