import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import GlobalInput from "../../../../components/globalInput";
import GlobalInputLable from "../../../../components/globalInputLable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoBox from "../../../../components/infobox";

const Queries = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSubmit = (values) => {
  };

  const faqs = [
    {
      question: "Can I Apply for a Personal Loan of Rs 2000 Online?",
      answer:
        "Yes, there are several online loan apps that offer personal loans of Rs 2000.",
    },
    {
      question: "What is the Interest Rate on a Personal Loan of Rs 2000?",
      answer:
        "The interest rate on a personal loan of Rs 2000 can range from 10% to 30% per annum.",
    },
    {
      question:
        "What are the Documents Required to Apply for a Personal Loan of Rs 2000?",
      answer:
        "You need to submit proof of ID, address proof, income proof, and a passport-size photograph.",
    },
    {
      question: "How Quickly can I Get a ₹2000 Loan?",
      answer:
        "Depending on the app, the process can be as quick as a few hours.",
    },
    {
      question: " Is it Safe to use these Loan Apps?",
      answer: "Yes, as long as they are registered and have good user reviews.",
    },
    {
      question: "Can I Repay the loan before the due date?",
      answer:
        "Most apps allow early repayment but check if there are any charges for the same.",
    },
  ];

  const formik = useFormik({
    initialValues: {
      profile: "",
      contactNo: "",
    },
    onSubmit: handleSubmit,
  });

  return (
    <Box
      sx={{
        // border: "0.2px solid #dadada",
        borderRadius: "10px",
        // margin: { xs: "20px 0px", sm: "20px", md: "20px 90px" },
        // padding: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "100%",
        gap: "30px",
      }}
    >
      {/* <Grid item xs={12} sm={7}> */}
      <Container maxWidth={"sm"} sx={{ padding: "0px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            {faqs.map((faq, index) => (
              <Accordion
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`)}
                sx={{
                  marginTop: "20px",
                  border: "none !important",
                  "&::before": {
                    content: "''",
                    height: "0px",
                  },
                }}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}d-content`}
                  id={`panel${index + 1}d-header`}
                >
                  <Typography
                    sx={{
                      color: "#243771",
                      fontSize: { xs: "18px", md: "21px" },
                      // fontWeight: "700",
                      fontFamily: "Lato",
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", lg: "16px" },
                      color: "#AAAAAA",
                      fontFamily: "Lato",
                    }}
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
        {/* </Grid> */}
      </Container>
      {/* <Box>
        <InfoBox
          iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
          text="Your data is securely encrypted. We never share it with third parties. See our"
          boldText="Privacy Policy ."
        />
        <InfoBox
          iconSrc={process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"}
          text="No impact to your credit score"
        />
      </Box> */}
    </Box>
  );
};

export default Queries;
