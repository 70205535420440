// useProfilePercentage.js

import { useSelector } from "react-redux";

const useProfilePercentage = () => {
  const formData = useSelector((state) => state.form.formData);

  // Define common fields
  const commonFields = [
    "personalInfo.emailId",
    "personalInfo.firstName",
    "personalInfo.lastName",
    "personalInfo.genderId",
    "personalInfo.dateOfBirth",
    "personalInfo.qualificationId",
    "personalInfo.maritalStatus",
    "employmentType",
    "employmentDetails.companyName",
    // "employmentDetails.countryId",
    "employmentDetails.pinCode",
    "employmentDetails.address",
    "employmentDetails.designation",
    // "employmentDetails.stateId",
    "employmentDetails.cityId",
    "employmentDetails.yearsWorkingIn",
    "address.addressLine1",
    "address.addressLine2",
    "address.pinCode",
    // "address.countryId",
    // "address.stateId",
    "address.cityId",
    "address.residenceTypeId",
    "address.yearsLivingIn",
    "finance.panCard",
    "finance.aadharNo",
    "others.totalEmiPaidMonthly",
    "others.interestedToGetCreditCard",
  ];

  const salariedSpecificFields = [
    "employmentDetails.industryTypeId",
    "employmentDetails.companyTypeId",
    "employmentDetails.income",
    "employmentDetails.salaryMode",
    "employmentDetails.bankId",
  ];

  const selfEmpSpecificFields = [
    "employmentDetails.industryTypeId",
    "employmentDetails.businessTypeId",
    "employmentDetails.turnover",
    "employmentDetails.monthlyProfit",
    "employmentDetails.savingAccountBankId",
    "employmentDetails.businessOwnedId",
    "employmentDetails.gst",
    "employmentDetails.currentAccountBankId",
  ];

  const fieldsToCheckSalaried = [...commonFields, ...salariedSpecificFields];
  const fieldsToCheckSelfEmp = [...commonFields, ...selfEmpSpecificFields];


  const countFilledFields = (fields) => {
    let count = 0;
    for (const field of fields) {
      const value = getField(formData, field);
      if (value !== undefined && value !== null && value !== "") {
        count++;
      }
    }
    return count;
  };

  const getField = (obj, path) => {
    return path.split(".").reduce((acc, curr) => {
      if (acc && acc[curr] !== undefined) {
        return acc[curr];
      }
      return undefined;
    }, obj);
  };

  const totalFields =
    formData.employmentType === "Salaried"
      ? fieldsToCheckSalaried.length
      : formData.employmentType === "Self Employed"
      ? fieldsToCheckSelfEmp.length
      : fieldsToCheckSalaried.length;


  const numberOfFilledFields =
    formData.employmentType === "Salaried"
      ? countFilledFields(fieldsToCheckSalaried)
      : formData.employmentType === "Self Employed"
      ? countFilledFields(fieldsToCheckSelfEmp)
      : countFilledFields(fieldsToCheckSalaried);


  const percentageFilled =
    totalFields !== 0
      ? Math.round((numberOfFilledFields / totalFields) * 100)
      : 0;

  return percentageFilled;
};

export default useProfilePercentage;
