import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import GlobalInputLable from "../../../../../../components/globalInputLable";
import GlobalInput from "../../../../../../components/globalInput";
import CommanTypo from "../../../../../../components/commanTypo";
import CommonDropdown from "../../../../../../components/commonDropDown";
import InfoBox from "../../../../../../components/infobox";
import { getInitialLoanValues } from "../../../../../../utils/initialValues";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiCallServices";
import { useDispatch, useSelector } from "react-redux";
import apiEndPointsConfig from "../../../../../../config/apiEndPointConfig";
import {
  setFormData,
  setIsLoading,
  setLoanReason,
} from "../../../../../../store/reducers";
import numberToWords from "number-to-words";
import { loanSchema } from "../../../../../../utils/validateSchema";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorFocus } from "../../../../../../utils/errorFocus";
import CircularProgress from "@mui/material/CircularProgress";
import { getBaseUrl } from "../../../../../../config/baseUrls";
import Submit from "../submitPage";
const Index = ({ updateTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amountInWords, setAmountInWords] = useState();
  const [isSubmitPage, setIsSubmitPage] = useState(false);
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  const isLoading = useSelector((state) => state?.form?.isLoading);
  //Loan Reason Api
  const LoanReasonListData = useSelector(
    (state) => state?.form?.loanReasonList
  );
  const GetLoanReasonList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getLoanReason,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetLoanReasonList?.isSuccess &&
      GetLoanReasonList?.data?.data !== null
    ) {
      const modifyres = GetLoanReasonList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setLoanReason(modifyres));
    }
  }, [GetLoanReasonList?.isSuccess]);

  //get check Eligibility Api Call
  const [CheckEligibilityApi, checkEligibilityApiData] = useApiCallMutation();
  useEffect(() => {
    if (checkEligibilityApiData.isSuccess) {
      dispatch(setIsLoading(false));
      // sessionStorage.setItem("isCompleted", true);
      setIsSubmitPage(true);
      // updateTab(1);
      // navigate("/profiling/dashboard/preapprovedoffer");
    }
  }, [checkEligibilityApiData]);
  const checkEligibility = async () => {
    const data1 = {
      leadId: sessionStorage.getItem("tempLead"),
      employmentDetails: {
        companyTypeId: formData?.employmentDetails?.companyTypeId,
        industryTypeId: formData?.employmentDetails?.industryTypeId,
        companyName: formData?.employmentDetails?.companyName,
        designation: formData?.employmentDetails?.designation,
        address: formData?.employmentDetails?.address,
        pinCode: formData?.employmentDetails?.pinCode,
        countryId: formData?.employmentDetails?.countryId,
        stateId: formData?.employmentDetails?.stateId,
        cityId: formData?.employmentDetails?.cityId,
        yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
        businessTypeId: formData?.employmentDetails?.businessTypeId,
        professionTypeId: formData?.employmentDetails?.professionTypeId,
        turnover: formData?.employmentDetails?.turnover,
        monthlyProfit: formData?.employmentDetails?.monthlyProfit,
        income: formData?.employmentDetails?.income,
        salaryMode: formData?.employmentDetails?.salaryMode,
        bankId: formData?.employmentDetails?.bankId,
        currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
        savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
        businessOwnedId: formData?.employmentDetails?.businessOwnedId,
        gst: formData?.employmentDetails?.gst,
      },
      termsAndCondition: formData?.termsAndCondition,
      loan: {
        reason: "Home Renovation",
        amount: formData?.loan?.amount,
        tenure: formData?.loan?.tenure,
        otherReason: formData?.loan?.otherReason,
      },
      finance: {
        panCard: formData?.panCard,
        aadharNo: formData?.aadharNo,
      },
      personalInfo: {
        emailId: formData?.personalInfo?.emailId,
        firstName: formData?.personalInfo?.firstName,
        lastName: formData?.personalInfo?.lastName,
        genderId: formData?.personalInfo?.genderId,
        dateOfBirth: formData?.personalInfo?.dateOfBirth,
        qualificationId: formData?.personalInfo?.qualificationId,
        maritalStatus: formData?.personalInfo?.maritalStatus,
      },
      employmentType: formData?.employmentType,
      others: {
        totalEmiPaidMonthly: 0,
        interestedToGetCreditCard: formData?.others?.interestedToGetCreditCard,
      },
      address: {
        addressLine1: formData?.address?.addressLine1,
        addressLine2: formData?.address?.addressLine2,
        pinCode: formData?.address?.pinCode,
        countryId: formData?.address?.countryId,
        stateId: formData?.address?.stateId,
        cityId: formData?.address?.cityId,
        residenceTypeId: formData?.address?.residenceTypeId,
        yearsLivingIn: formData?.address?.yearsLivingIn,
      },
    };
    const data = { ...data1 };
    CheckEligibilityApi({
      endPoint: apiEndPointsConfig?.CheckEligibility,
      method: "POST",
      data: { data },
    });
  };
  //handleSubmit
  const handleSubmit = () => {
    dispatch(setIsLoading(true));
    checkEligibility();
    // setTimeout(() => {
    //   dispatch(setIsLoading(false));
    // updateTab(1);
    // }, 15000);
  };
  const formik = useFormik({
    initialValues: getInitialLoanValues(formData),
    validationSchema: loanSchema,
    onSubmit: handleSubmit,
  });

  // error Focus
  useEffect(() => {
    if (formik?.isSubmitting) {
      errorFocus(formik?.errors);
    }
  }, [formik?.isSubmitting, formik?.errors]);

  return (
    <>
      {isSubmitPage ? (
        <Submit updateTab={updateTab} />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <Container maxWidth={"sm"} sx={{ padding: "0px" }}>
            {isLoading && (
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 9999,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <form onSubmit={formik.handleSubmit}>
              <Grid container rowGap={4}>
                {/* <Grid
              item
              xs={12}
              sx={{
                mt: 6,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable
                lableText={"Could you share the purpose of this loan?"}
              />
              <CommanTypo
                text={
                  "This information allows us to connect you with suitable partners that align with your specific needs"
                }
              />
              <CommonDropdown
                id={"reason"}
                name={"reason"}
                options={LoanReasonListData || []}
                onBlur={formik.handleBlur}
                value={
                  LoanReasonListData?.find(
                    (option) => formData?.loan?.reason === option.label
                  )?.label
                }
                onChange={(event, newValue) => {
                  if (newValue?.label === "Others") {
                    dispatch(
                      setFormData({
                        loan: {
                          ...formData.loan,
                          reason: newValue?.label,
                          otherReason: newValue?.label,
                        },
                      })
                    );
                  } else {
                    dispatch(
                      setFormData({
                        loan: {
                          ...formData.loan,
                          reason: newValue?.label,
                        },
                      })
                    );
                  }

                  formik.setFieldValue("reason", newValue);
                }}
                error={formik.touched.reason && Boolean(formik.errors.reason)}
                helperText={formik.touched.reason && formik.errors?.reason}
              />
            </Grid> */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 3,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <GlobalInputLable
                    lableText={
                      "Let us know the amount you’re considering for the loan?"
                    }
                  />
                  <CommanTypo
                    text={"We promise your financial secrets are safe with us"}
                  />
                  <GlobalInput
                    type="tel"
                    inputMode="numeric"
                    placeholder={"₹ 50000"}
                    id="amount"
                    name="amount"
                    value={formData?.loan?.amount}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        if (e.target.value >= 0 && e.target.value <= 1500000) {
                          dispatch(
                            setFormData({
                              loan: {
                                ...formData.loan,
                                amount: e.target.value,
                              },
                            })
                          );
                          const number = parseFloat(e.target.value);
                          if (!isNaN(number) && isFinite(number)) {
                            setAmountInWords(numberToWords.toWords(number));
                          } else {
                            setAmountInWords("");
                          }

                          formik.handleChange(e);
                        }
                      }
                    }}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors?.amount}
                    onBlur={formik.handleBlur}
                  />
                  {amountInWords ? (
                    <Typography
                      sx={{ color: "#3B5BBA", fontSize: "15px" }}
                      variant="subtitle1"
                    >
                      {amountInWords?.charAt(0).toUpperCase() +
                        amountInWords?.slice(1)}{" "}
                      Only
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 3,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <GlobalInputLable
                    lableText={
                      "How long would you like your loan duration to be?"
                    }
                  />
                  <CommanTypo
                    text={
                      "No pressure, an  approximate figure will suffice for us to proceed."
                    }
                  />
                  <GlobalInput
                    type="tel"
                    inputMode="numeric"
                    placeholder={"No. Of Months"}
                    id="tenure"
                    name="tenure"
                    onChange={(e) => {
                      if (
                        !isNaN(e.target.value) &&
                        !/\s/.test(e.target.value)
                      ) {
                        if (e.target.value >= 0 && e.target.value <= 60) {
                          dispatch(
                            setFormData({
                              loan: {
                                ...formData.loan,
                                tenure: e.target.value,
                              },
                            })
                          );
                          formik.handleChange(e);
                        }
                      }
                    }}
                    error={
                      formik.touched.tenure && Boolean(formik.errors.tenure)
                    }
                    helperText={formik.touched.tenure && formik.errors?.tenure}
                    onBlur={formik.handleBlur}
                    value={formData?.loan?.tenure}
                  />
                </Grid>
                {/* <Grid container rowGap={1}>
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    color: "#AAAAAA",
                  }}
                >
                  <Checkbox
                    name="termsAndCondition"
                    id="termsAndCondition"
                    onChange={(e) => {
                      dispatch(
                        setFormData({
                          termsAndCondition: e.target.checked,
                        })
                      );
                      formik.setFieldValue(
                        "termsAndCondition",
                        e.target.checked
                      );
                    }}
                    value={formData.loan.termsAndCondition}
                    onBlur={formik.handleBlur}
                    checked={formData.termsAndCondition}
                    error={formik.errors.termsAndCondition}
                    helperText={formik.errors.termsAndCondition}
                  />
                  <Typography sx={{ fontSize: "14px", color: "#AAAAAA" }}>
                    I hereby declare that I have read, understood and agree to
                    the
                    {"  "}
                    <Link
                      target="_blank"
                      style={{ fontWeight: "600", color: "#3653A9" }}
                      to={`${baseUrl}terms-and-conditions`}
                    >
                      Terms & Conditions
                    </Link>
                    {"  "}
                    and the {"  "}
                    <Link
                      target="_blank"
                      style={{ fontWeight: "600", color: "#3653A9" }}
                      to={`${baseUrl}privacy-policy`}
                    >
                      Privacy Policy
                    </Link>
                    .{"  "}And I hereby consent to Boost.Money and EMKAY
                    CONSULTANTS LIMITED being appointed as my authorized
                    representative to receive my Credit Information from
                    Experian for the purpose of Credit Assessment to advise me
                    on the financial journey, on ongoing basis for not exceeding
                    a period of six (6) months.{" "}
                    {Read ? (
                      <>
                        This authorization remains valid until the objective of
                        obtaining the Bureau Score for connecting with
                        Credmudra's lending partner is achieved, or up to six
                        months from the consent collection date. <br />
                        Additionally, I grant permission to Credmudra, its
                        Lending Partners, and affiliated entities to contact me
                        for various communication from Credmudra via SMS,
                        E-mail, and WhatsApp for loans, credit cards, or other
                        relevant information or promotions. <br />I also agree
                        to{" "}
                        <Link
                          target="_blank"
                          style={{ fontWeight: "600", color: "#3653A9" }}
                          to={`${baseUrl}experian-terms-and-conditions`}
                        >
                          Experian's Terms and Conditions.
                        </Link>
                        <br />
                        <Link
                          style={{ fontWeight: "600", color: "#3653A9" }}
                          onClick={offRead}
                          to={""}
                        >
                          Read Less{" "}
                        </Link>
                      </>
                    ) : (
                      <Link
                        style={{ fontWeight: "600", color: "#3653A9" }}
                        onClick={toggleRead}
                        to={""}
                      >
                        Read More{" "}
                      </Link>
                    )}
                  </Typography>
                </Box>
                {formik.errors.termsAndCondition &&
                  formik.touched.termsAndCondition && (
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        marginTop: "4px",
                        marginLeft: "14px",
                        marginRight: "14px",
                      }}
                    >
                      {formik.errors.termsAndCondition}
                    </div>
                  )}
              </Grid>
            </Grid> */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    // id="cred_submit_btn"
                    sx={{
                      display: "flex",
                      width: "108px",
                      height: "48px",
                      padding: "12px 20px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      flexShrink: 0,
                      background: "var(--Primary-Blue-state500, #3B5BBA)",
                      borderRadius: "8px",
                      "&:hover": { bgcolor: "#3B5BBA" },
                      color: " #FFF",
                    }}
                    type="submit"
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
          <Box>
            <Box display={"flex"} gap={"5px"} margin={"8px"}>
              {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
            </Box>
            {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
              <InfoBox
                iconSrc={
                  process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
                }
                text="No impact to your credit score"
              />
            </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Index;
