import React from 'react'
import { Box, Button, Checkbox, Divider, Grid, TextField, Typography } from '@mui/material'
import LoginPng from "../../../../src/images/enter-otp.png"
import Logo from "../../../../src/images/credmudra-logo-file-republic-day-theme (2).png"

const Signup = () => {
    return (
        <Box sx={{ width: "100%" }}>
            <Grid container >
                <Grid item
                    sx={{
                        backgroundColor: '#6582DB',
                        display: "grid",
                        placeItems: "center",
                        height: { md: '832px', xs: '290px' }
                    }}
                    xs={12} md={6}>
                    <Box sx={{ height: { xs: "290px", md: "360px" } }}>
                        <img height={"100%"} src={LoginPng} alt='logo'
                        />
                    </Box>

                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ display: "grid", placeItems: { xs: "center", sm: "center", md: "baseline" }, }} >
                    <Box sx={{ width: { xs: "300px", md: "360px" }, ml: { xs: 0, sm: 0, md: 10, lg: 18, } }}>
                        <Box sx={{ mt: "84px", }}  >
                            <img
                                height={48}
                                width={254}
                                alt="logo"
                                src={Logo}
                            />
                        </Box>
                        <Box mt={4}>
                            <Typography variant='h5' sx={{ fontWeight: 700, }}>Sign up  &#9995; </Typography>
                            <Typography mt={1} variant='body1'>To view saved searches, track submitted applications</Typography>
                        </Box>
                        <Box sx={{ width: { xs: "300px", md: "360px" }, mt: 6 }}>
                            <Typography variant='h6' sx={{ fontWeight: 600, lineHeight: "28.8px", mb: 1 }}>Enter your mobile number</Typography>
                            <TextField fullWidth id="outlined-basic" label="Mobile number" variant="outlined" />
                        </Box>

                        <Box sx={{ display: "flex" }}>
                            <Checkbox />
                            <Typography sx={{ mt: 2 }}>I agree with the
                                <span style={{ color: '#3B5BBA' }}> Terms & Conditions</span>
                                of Credmudra</Typography>
                        </Box>

                        <Button variant="contained" sx={{ width: { xs: "300px", md: "360px" }, height: "48px", mt: 8 }}>Signup</Button>
                        <Box sx={{
                            mt: 4
                        }}>
                            <Divider>or</Divider>
                        </Box>
                        <Button variant="contained" sx={{
                            mt: 4,
                            width: { xs: "300px", md: "360px" }, height: "48px", backgroundColor: "#F5F5F5", color: "#343435B2", '&:hover': {
                                backgroundColor: '#F5F5F5',
                            }
                        }}>Sign up with Google</Button>
                        <Typography variant='body1' sx={{ color: "#343435", mt: 4 }}>Returning user?
                            <span style={{ color: '#3B5BBA' }}>Login here</span>
                        </Typography>

                    </Box>

                </Grid>
            </Grid>
        </Box >
    )
}

export default Signup