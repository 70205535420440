import { Box, Typography } from '@mui/material'
import React from 'react'

const CommanTypo = ({ text, bgcolor, image, attr }) => {
  const backgroundColor = bgcolor ? bgcolor : "#EBEFF8";
  const srcImage = image ? image : process.env.PUBLIC_URL + "/assets/Icons/lightbulb.svg";
  return (
    <Box
      sx={{
        bgcolor: backgroundColor,
        borderRadius: "10px",
        padding: attr ? attr : "25px 20px",
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        alignItems: "center",
        textAlign: "center",
        color: "#3B5BBA",
      }}
    >
      {image && <img
        src={srcImage}
        alt="lightbulb"
        width="17px"
      />
      }    <Typography sx={{ fontSize: "15px" }}>
        {text}
      </Typography>
    </Box>
  )
}

export default CommanTypo
