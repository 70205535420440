import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Rating from "@mui/material/Rating";
import CHECKCIRCLE from "../../../../../../images/check_circle.svg";
import InfoBox from "../../../../../../components/infobox";
import SELECTLENDER from "../../../../../../images/selectlender1.svg";
import apiEndPointsConfig from "../../../../../../config/apiEndPointConfig";
import { useApiCallMutation } from "../../../../../../services/apiCallServices";
import { useNavigate } from "react-router-dom";
import CasheLogo from "../../../../../../Leanders/cashe.png";
import FaircentLogo from "../../../../../../Leanders/faircent.png";
import FibeLogo from "../../../../../../Leanders/fibe.png";
import KisshtLogo from "../../../../../../Leanders/kissht.png";
import Kreditbee from "../../../../../../Leanders/kreditbee.png";
import LendingkartLogo from "../../../../../../Leanders/lendingkart.png";
import LoantapLogo from "../../../../../../Leanders/loantap.png";
import MoneytapLogo from "../../../../../../Leanders/moneytap.png";
import MoneyviewLogo from "../../../../../../Leanders/moneyview.png";
import MoneywideLogo from "../../../../../../Leanders/moneywide.png";
import MpocketLogo from "../../../../../../Leanders/mpocket.png";
import NiraLogo from "../../../../../../Leanders/nira.png";
import PaysenseLogo from "../../../../../../Leanders/paysense.png";
import ProtiumLogo from "../../../../../../Leanders/protium.png";
import StashfinLogo from "../../../../../../Leanders/stashfin.png";
import ZypeLogo from "../../../../../../Leanders/zype.png";
import Indifi from "../../../../../../Leanders/indifi.png";
import Truebalance from "../../../../../../Leanders/truebalance.png";
import Incred from "../../../../../../Leanders/incred.svg";
import Upwards from "../../../../../../Leanders/upwards.png";
import prefr from "../../../../../../Leanders/prefr.webp";
import fatakPay from "../../../../../../Leanders/fatak_pay.png";
import abfl from "../../../../../../Leanders/abfl.png";
import privo from "../../../../../../Leanders/privoLogo.png";
import sbl from "../../../../../../Leanders/sbl.png";
import olyv from "../../../../../../Leanders/olyv.png";
import { setFormData } from "../../../../../../store/reducers";
import { useDispatch } from "react-redux";
import Marketplace from "./marketPlace";
import Offer from "./offer";
import Offer2 from "./offer2";

const TextStyling = {
  fontSize: "10px",
  fontFamily: "Inter,sans-serif",
  color: "#262250",
  mt: "10px",
  textAlign: "left",
};

const SelectLender = ({ updateTab }) => {
  const [leadStatus, setLeadStatus] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("leadEligibility")) {
      setLeadStatus(sessionStorage.getItem("leadEligibility"));
    }
  }, [sessionStorage.getItem("leadEligibility")]);
  return (
    <>
      {leadStatus === "marketplace" ? (
       <Marketplace setLeadStatus={setLeadStatus} updateTab={updateTab} />
      ) : ''}
      {leadStatus==="googleExitPage"?<Offer/>:''}

      {leadStatus==="offerPage"?<Offer2 />:''}
    </>
  );
};

export default SelectLender;
