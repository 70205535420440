export const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("dev")) {
      return "https://dev.credmudra.com/";
    } else if (hostname.includes("uat")) {
      return "https://uat.credmudra.com/";
    } else {
      return "https://www.credmudra.com/"; 
    }
  };
  