export const errorFocus = (errors) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length > 0) {
    const firstErrorField = document.getElementsByName(errorKeys[0])?.[0];
    if (firstErrorField) {
      firstErrorField.focus({ preventScroll: true });
      firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
};
