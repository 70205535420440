import React, { useEffect, useRef } from "react";
import GlobalInput from "../../../../components/globalInput";
import { useFormik } from "formik";
import { Box, Container, FormHelperText, Grid } from "@mui/material";
import GlobalInputLable from "../../../../components/globalInputLable";
import CommonButton from "../../../../components/commonButton";
import { useNavigate } from "react-router-dom";
import CommonDropdown from "../../../../components/commonDropDown";
import apiEndPointsConfig from "../../../../config/apiEndPointConfig";
import { getInitialAddressDetailsValues } from "../../../../utils/initialValues";
import { addressDetailsSchema } from "../../../../utils/validateSchema";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiCallServices";
import {
  setFormData,
  setPersonalAddress,
  setPincodeError,
  setResidenceType,
} from "../../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import InfoBox from "../../../../components/infobox";
import { errorFocus } from "../../../../utils/errorFocus";
import { isStepCompleted } from "../../../../utils/commonFunctions";

function ResidenceDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  const PinCodeErrorData = useSelector((state) => state?.form?.pinCodeError);
  const PersonalAddressData = useSelector(
    (state) => state?.form?.personalAddress
  );
  const residenceTypesListData = useSelector(
    (state) => state?.form?.residenceTypesList
  );
  //api calls
  const GetResidenceTypeList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getResidenceTypes,
    method: "POST",
    data: {},
  });

  useEffect(() => {
    if (!isStepCompleted(formData, "PersonalDetails")) {
      navigate("/profiling/profile/personaldetails");
    } else if (!isStepCompleted(formData, "EmploymentDetails")) {
      navigate("/profiling/profile/employmentdetails");
    }
  }, []);
  useEffect(() => {
    if (
      GetResidenceTypeList?.isSuccess &&
      GetResidenceTypeList?.data?.data !== null
    ) {
      const modifyres = GetResidenceTypeList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setResidenceType(modifyres));
    }
  }, [GetResidenceTypeList?.isSuccess]);

  const Year = [
    { label: "1 Year", value: "1 Year" },
    { label: "1-2 Years", value: "1-2 Years" },
    { label: "3 Years", value: "3 Years" },
    { label: "3-5 Years", value: "3-5 Years" },
    { label: "5+ Years", value: "5+ Years" },
  ];
  const navigateToOtherDetails = () => {
    navigate("/profiling/profile/otherdetails");
  };
  //Drop-Off Api
  const [DropOffApi, dropOffApiData] = useApiCallMutation();
  const dropOffRequest = () => {
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      stage: "address details",
      address: {
        addressLine1: formData?.address?.addressLine1,
        addressLine2: formData?.address?.addressLine2,
        pinCode: formData?.address?.pinCode,
        countryId: formData?.address?.countryId,
        stateId: formData?.address?.stateId,
        cityId: formData?.address?.cityId,
        residenceTypeId: formData?.address?.residenceTypeId,
        yearsLivingIn: formData?.address?.yearsLivingIn,
      },
    };
    DropOffApi({
      endPoint: apiEndPointsConfig?.newDropOff,
      method: "POST",
      data: { data },
    });
  };
  //get pincode Address Api Call
  const [GetPinCodeAddressApi, getPinCodeAddressApiData] = useApiCallMutation();
  const getAddress = async (event) => {
    if (
      /^\d*$/.test(event.target.value) &&
      event.target.value.length <= 6 &&
      event.target.value !== "000000"
    ) {
      dispatch(
        setPincodeError({
          ...PinCodeErrorData,
          addressPincode: "",
        })
      );
      dispatch(
        setFormData({
          address: {
            ...formData.address,
            pinCode: event.target.value,
          },
        })
      );
      if (event.target.value?.length === 6) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            addressPincode: "",
          })
        );
        const data = {
          pinCode: event.target.value,
        };
        GetPinCodeAddressApi({
          endPoint: apiEndPointsConfig.getPincodesAddress,
          method: "POST",
          data: { data },
        });
      } else {
        dispatch(
          setFormData({
            address: {
              ...formData.address,
              pinCode: event.target.value,
              cityId: "",
              stateId: "",
              countryId: "",
            },
          })
        );
        dispatch(
          setPersonalAddress({
            city: "",
            state: "",
            country: "",
          })
        );
      }
    }
  };
  useEffect(() => {
    if (getPinCodeAddressApiData?.isSuccess) {
      if (getPinCodeAddressApiData?.data?.status.code === 200) {
        dispatch(
          setFormData({
            address: {
              ...formData.address,
              pinCode: formData?.address?.pinCode,
              cityId: getPinCodeAddressApiData?.data?.data?.city?.id,
              stateId: getPinCodeAddressApiData?.data?.data?.state?.id,
              countryId: getPinCodeAddressApiData?.data?.data?.country?.id,
            },
          })
        );
        dispatch(
          setPersonalAddress({
            city: getPinCodeAddressApiData?.data?.data?.city?.name,
            state: getPinCodeAddressApiData?.data?.data?.state?.name,
            country: getPinCodeAddressApiData?.data?.data?.country?.name,
          })
        );
      } else if (getPinCodeAddressApiData?.data?.status.code === 404) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            addressPincode: "Pincode does not exists",
          })
        );
        // formik.setFieldError("pinCode", "invalid pincode ");
      } else {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            addressPincode: "",
          })
        );
      }
    }
  }, [getPinCodeAddressApiData?.isSuccess]);
  //next submit
  const handleSubmit = async () => {
    if (PinCodeErrorData?.addressPincode === "") {
      const isExistUser = sessionStorage.getItem("isExistUser");
      if (!isExistUser) {
        await dropOffRequest();
      }
      navigateToOtherDetails();
    } else {
      document.getElementById("pinCode").focus();
    }
    navigateToOtherDetails();
  };

  const formik = useFormik({
    initialValues: getInitialAddressDetailsValues(formData),
    validationSchema: addressDetailsSchema,
    onSubmit: handleSubmit,
  });
  // error Focus
  useEffect(() => {
    if (formik?.isSubmitting) {
      errorFocus(formik?.errors);
    }
  }, [formik?.isSubmitting, formik?.errors]);
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <Box
      ref={formRef}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            rowGap={4}
            // sx={{ paddingBottom: { xs: "50px", md: "20px" } }}
          >
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable
                lableText={"Please enter your residential address."}
              />
              <GlobalInput
                type={""}
                placeholder={" Address"}
                id={"addressLine1"}
                name={"addressLine1"}
                value={formData?.address?.addressLine1}
                onChange={(event, newValue) => {
                  if (
                    /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(
                      event.target.value
                    )
                  ) {
                    dispatch(
                      setFormData({
                        address: {
                          ...formData.address,
                          addressLine1: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                error={
                  formik.touched.addressLine1 &&
                  Boolean(formik.errors.addressLine1)
                }
                helperText={
                  formik.touched.addressLine1 && formik.errors.addressLine1
                }
                // error={formik.errors.addressLine1}
                // helperText={formik.errors.addressLine1}
                onBlur={formik.handleBlur}
              />
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <GlobalInput
                  type={""}
                  placeholder={"Landmark"}
                  id={"addressLine2"}
                  name={"addressLine2"}
                  value={formData?.address?.addressLine2}
                  onChange={(event, newValue) => {
                    if (
                      /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(
                        event.target.value
                      )
                    ) {
                      dispatch(
                        setFormData({
                          address: {
                            ...formData.address,
                            addressLine2: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                  error={
                    formik.touched.addressLine2 &&
                    Boolean(formik.errors.addressLine2)
                  }
                  helperText={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                  }
                  // error={formik.errors.addressLine2}
                  // helperText={formik.errors.addressLine2}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  <GlobalInput
                    type="tel"
                    inputMode="numeric"
                    placeholder={"  Pincode"}
                    id={"pinCode"}
                    name={"pinCode"}
                    value={formData?.address?.pinCode}
                    onChange={(event) => {
                      getAddress(event);
                      formik.handleChange(event);
                    }}
                    error={
                      formik.touched.pinCode && Boolean(formik.errors.pinCode)
                    }
                    helperText={formik.touched.pinCode && formik.errors.pinCode}
                    onBlur={formik.handleBlur}
                  />
                  {PinCodeErrorData?.addressPincode ? (
                    <FormHelperText
                      sx={{
                        color: "rgb(211, 47, 47)",
                        fontSize: "0.75rem",
                        ml: "14px",
                      }}
                    >
                      {PinCodeErrorData?.addressPincode}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={6}>
                  <GlobalInput
                    disable={true}
                    type={""}
                    placeholder={"City"}
                    id={"city"}
                    name={"cityId"}
                    value={PersonalAddressData?.city}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    // error={formik.errors.city}
                    // helperText={formik.errors.city}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <GlobalInput
                    disable={true}
                    type={""}
                    placeholder={"State"}
                    id={"state"}
                    name={"state"}
                    value={PersonalAddressData?.state}
                    onChange={(event, newValue) => {
                      formik.handleChange(event);
                    }}
                    // error={formik.touched.state && Boolean(formik.errors.state)}
                    // helperText={formik.touched.state && formik.errors.state}
                    // error={formik.errors.state}
                    // helperText={formik.errors.state}
                    onBlur={formik.handleBlur}
                  />
                </Grid> */}

                {/* <Grid item xs={6}>
                  <GlobalInput
                    disable={true}
                    type={""}
                    placeholder={"Country"}
                    id={"country"}
                    name={"country"}
                    value={PersonalAddressData?.country}
                    onChange={(event, newValue) => {
                      formik.handleChange(event);
                    }}
                    // error={formik.touched.country && Boolean(formik.errors.country)}
                    // helperText={formik.touched.country && formik.errors.country}
                    // error={formik.errors.country}
                    // helperText={formik.errors.country}
                    onBlur={formik.handleBlur}
                  />
                </Grid> */}
                {/* <Grid item xs={6}>
            <GlobalInput
              type={""}
              placeholder={"State"}
              id={"state"}
              name={"state"}
             value={PersonalAddressData?.state}
              onChange={(event, newValue) => {
                formik.handleChange(event);
              }}
              error={formik.errors.state}
              helperText={formik.errors.state}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={6}>
            <GlobalInput
              type={""}
              placeholder={"Country"}
              id={"country"}
              name={"country"}
             value={PersonalAddressData?.country}
              onChange={(event, newValue) => {
                formik.handleChange(event);
              }}
              error={formik.errors.country}
              helperText={formik.errors.country}
              onBlur={formik.handleBlur}
            />
          </Grid> */}
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={"Please select your residence type"}
              />
              <CommonDropdown
                id={"residenceTypeId"}
                name={"residenceTypeId"}
                options={residenceTypesListData ? residenceTypesListData : ""}
                onBlur={formik.handleBlur}
                value={
                  residenceTypesListData?.find(
                    (option) =>
                      formData?.address?.residenceTypeId === option.value
                  )?.label
                }
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      address: {
                        ...formData.address,
                        residenceTypeId: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("residenceTypeId", newValue);
                }}
                error={
                  formik.touched.residenceTypeId &&
                  Boolean(formik.errors.residenceTypeId)
                }
                helperText={
                  formik.touched.residenceTypeId &&
                  formik.errors.residenceTypeId
                }
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={
                  "How many years have you been living at your current address?"
                }
              />

              <CommonDropdown
                id={"yearsLivingIn"}
                name={"yearsLivingIn"}
                options={Year}
                value={formData?.address?.yearsLivingIn}
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      address: {
                        ...formData.address,
                        yearsLivingIn: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("yearsLivingIn", newValue);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.yearsLivingIn &&
                  Boolean(formik.errors.yearsLivingIn)
                }
                helperText={
                  formik.touched.yearsLivingIn && formik.errors.yearsLivingIn
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CommonButton buttonText={"Save"} />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          />
        </Box> */}
      </Box>
    </Box>
  );
}

export default ResidenceDetails;
