import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel, TabContext } from "@mui/lab";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MobileSubNavigation from "../sitebar/mobileSubNavigation";
import { useSelector } from "react-redux";
import { isStepCompleted } from "../../utils/commonFunctions";

const MainSection = () => {
  const { userExist, formData } = useSelector((state) => state?.form);
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();

  useEffect(() => {
    const tabval = location.pathname.includes("profile") ? "1" : "2";
    setValue(tabval);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      navigate("/profiling/profile/personaldetails");
    } else if (newValue === "2") {
      navigate("/profiling/dashboard/products");
    }
  };

  const tabStyle = {
    "&.Mui-selected": {
      color: "#3B5BBA",
      fontWeight: "700",
    },
  };
  const indicatorStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: "#3B5BBA",
      display: isMobile ? "none" : "block",
    },
  };
  useEffect(() => {
    sessionStorage.removeItem('isCompleted')
  }, [])
  
  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box
          sx={{
            paddingX: !isMobile ? 3 : 0,
            borderBottom: isMobile ? 0 : 1,
            borderColor: "divider",
            mt: isMobile ? "10px" : "0px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary"
            sx={{ ...indicatorStyle, display: isMobile ? "none" : "block" }}
          >
            <Tab sx={tabStyle} label={isMobile ? "" : "Profile"} value="1" />
            <Tab
              sx={tabStyle}
              disabled={
                !(
                  isStepCompleted(formData, "PersonalDetails") &&
                  isStepCompleted(formData, "EmploymentDetails") &&
                  isStepCompleted(formData, "ResidenceDetails") &&
                  isStepCompleted(formData, "OtherDetails")
                )
              }
              label={isMobile ? "" : "Dashboard"}
              value="2"
            />
          </Tabs>
          {isMobile && (
            <MobileSubNavigation
              handleChange={handleChange}
              value={value}
              setValue={setValue}
            />
          )}
        </Box>
        <TabPanel value={value}>
          <Outlet />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default MainSection;
