import { TextField } from "@mui/material";
import React from "react";

function GlobalInput({
  name,
  id,
  onChange,
  placeholder,
  value,
  error,
  helperText,
  onBlur,
  type,
  dynamicInputProps,
  disable,
}) {
  return (
    <TextField
      disabled={disable}
      inputProps={{
        ...dynamicInputProps,
        style: {
          // background: "transparent",
          // padding: "20px 40px",
          display: type === "date" ? "block" : " flex",
          padding: "1.1rem",
          alignItems: "center",
          gap: "12px",
          background: "#FFF",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          borderColor: "var(--Stroke-1, #E1E1E1);",
        },
      }}
      size={"small"}
      variant="outlined"
      id={id}
      name={name}
      fullWidth
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      type={type}
    />
  );
}

export default GlobalInput;
