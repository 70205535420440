import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function CommonButton({ buttonText,attr }) {
  const UserExist = useSelector((state) => state?.form?.userExist);
  return (
    <>
      <Button
        type="submit"
        sx={{
          display: "flex",
          width: attr ? attr : "auto",
          height: "48px",
          padding: "12px 30px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          flexShrink: 0,
          background: "var(--Primary-Blue-state500, #3B5BBA)",
          borderRadius: "8px",
          "&:hover": { bgcolor: "#3B5BBA" },
          color: " #FFF",
        }}
      >
       
        {UserExist ? "Update" : buttonText}
      </Button>
    </>
  );
}

export default CommonButton;
