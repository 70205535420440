import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import GaugeComponent from "../../../../components/GuageComponent";
import { Link } from "react-router-dom";
import GetCreditScore from "./components/GetCreditScore";
import InfoBox from "../../../../components/infobox";

const CheckCreditScore = () => {
  const [showAnotherComponent, setShowAnotherComponent] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 960px)");
  const issmDesktop = useMediaQuery(
    "(min-width: 961px)and (max-width: 1200px)"
  );
  const islgDesktop = useMediaQuery(
    "(min-width: 1201px)and (max-width: 1600px)"
  );

  const gaugeRadius = isMobile
    ? "100%"
    : isTablet
    ? "80%"
    : issmDesktop
    ? "100%"
    : islgDesktop
    ? "120%"
    : "130%";
  const axisLineWidth = isMobile
    ? 40
    : isTablet
    ? 60
    : issmDesktop
    ? 60
    : islgDesktop
    ? 60
    : 100;
  const axisLinefs = isMobile
    ? "8px"
    : isTablet
    ? "9.5px"
    : islgDesktop
    ? "13px"
    : "14px";

  const distance = isMobile
    ? 0
    : isTablet
    ? 5
    : issmDesktop
    ? 6
    : islgDesktop
    ? 12
    : 20;

  const handleButtonClick = () => {
    setShowAnotherComponent(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
        mb: "30px",
      }}
    >
      {showAnotherComponent ? (
        <GetCreditScore />
      ) : (
        <Grid
          sx={{
            width: "100%",
            borderRadius: "10px",
            margin: { xs: "30px", sm: "0px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box textAlign={"center"} sx={{ mb: { lg: 5 } }}>
            <Typography
              sx={{
                fontSize: { xs: "18px", sm: "22px", lg: "25px" },
                fontWeight: "600",
                color: "#3B5BBA",
              }}
            >
              Check your credit score with Credmudra
            </Typography>
          </Box>
          <GaugeComponent
            data={{
              series: [
                {
                  type: "gauge",
                  startAngle: 180,
                  endAngle: 0,
                  center: ["50%", "65%"],
                  radius: gaugeRadius,
                  min: 0,
                  max: 1,
                  axisLine: {
                    lineStyle: {
                      width: axisLineWidth,
                      color: [
                        [0.2, "#9DADDA"],
                        [0.4, "#758CCF"],
                        [0.6, "#3B5BBA"],
                        [0.8, "#294083"],
                        [1, "#1d2d5d"],
                      ],
                    },
                  },
                  pointer: {
                    icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                    length: "60%",
                    width: 20,
                    offsetCenter: [0, "0%"],
                    itemStyle: {
                      color: "BLACK",
                    },
                  },
                  axisTick: {
                    length: 0,
                  },
                  splitLine: {
                    length: 0,
                  },
                  axisLabel: {
                    fontSize: axisLinefs,
                    color: "white",
                    fontWeight: 700,
                    // rotate: "tangential",
                    distance: distance,
                    formatter: function (value) {
                      if (value === 0.9) {
                        return "Good";
                      } else if (value === 0.1) {
                        return "Poor";
                      }
                      return "";
                    },
                  },
                  detail: {
                    show: "",
                  },
                  data: [
                    {
                      value: 0.7,
                    },
                  ],
                },
              ],
            }}
          />
          <Button
            onClick={handleButtonClick}
            variant="contained"
            sx={{
              padding: { xs: "5px 10px", md: "8px 12px", xl: "15px 30px" },
              backgroundColor: "#3B5BBA",
              // marginLeft: { md: "-35px" },
              fontSize: { xs: "10px", md: "12px", xl: "15px" },
            }}
          >
            Get free credit score now
          </Button>
        </Grid>
      )}
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default CheckCreditScore;
