import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PersonalLoan from "./components/PersonalLoan/index";
import NoData from "./components/PersonalLoan/components/NoDtata";
import BusinessLoan from "./components/BusinessLoan";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";

const ConditionalTooltipTab = ({ label, value, disabled, tooltip }) => (
  <Tooltip title={disabled ? tooltip : ""} arrow>
    <span>
      <Tab label={label} value={value} disabled={disabled} />
    </span>
  </Tooltip>
);

const PreviousCredit = () => {
  const employmentType = useSelector(
    (state) => state.form.formData.employmentType
  );
  const [value, setValue] = React.useState("1");
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    sessionStorage.removeItem('timerCheck')
    if (employmentType === "Self Employed") {
      setValue("2");
    }
  }, [employmentType]);

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <ConditionalTooltipTab
              label="Personal Loan"
              value="1"
              disabled={!(employmentType === "Salaried")}
              tooltip="This option is unavailable for Self Employees"
            />
            <ConditionalTooltipTab
              label="Business Loan"
              value="2"
              disabled={!(employmentType === "Self Employed")}
              tooltip="This option is unavailable for Salaried Employees"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: { xs: "10px", md: "20px" } }}>
          <PersonalLoan loanType={"Salaried"} />
        </TabPanel>
        <TabPanel value="2">
          <PersonalLoan loanType={"Self Employed"} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default PreviousCredit;
