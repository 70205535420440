import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommonRadioButtons from "../../../../components/commonRadioButtons";

import CommonButton from "../../../../components/commonButton";
import Salaried from "./salaried";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { employmentTypeSchema } from "../../../../utils/validateSchema";
import { getInitialEmployeTypeValues } from "../../../../utils/initialValues";
import { setFormData } from "../../../../store/reducers";
import SelfEmployed from "./selfEmployed";
import InfoBox from "../../../../components/infobox";
import { useNavigate } from "react-router-dom";
import { isStepCompleted } from "../../../../utils/commonFunctions";
const EmploymentDetails = () => {
  const formData = useSelector((state) => state?.form?.formData);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const navigate = useNavigate();

  const [showNextContainer, setShowNextContainer] = useState(false);

  const employmentOptions = [
    { value: 1, label: "Salaried" },
    { value: 2, label: "Self Employed" },
  ];

  const handleSubmit = async () => {
    setShowNextContainer(true);
  };

  const formik = useFormik({
    initialValues: getInitialEmployeTypeValues(formData),
    validationSchema: employmentTypeSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (
      formData?.personalInfo?.firstName &&
      !isStepCompleted(formData, "PersonalDetails")
    ) {
      navigate("/profiling/profile/personaldetails");
    }
  }, [formData]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <Container maxWidth={"sm"}>
        <Grid container rowGap={4}>
          <Grid
            ref={formRef}
            item
            xs={12}
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                color: " var(--Text-100, #343435)",
                textAlign: "center",
                fontFamily: "Lato",
                fontSize: { xs: "1.1rem", sm: "1.3rem", lg: "1.5rem" },
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.24px",
              }}
            >
              What should we call your profession?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            xl={12}
            sx={{
              // textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              gap: "15px",
              // bgcolor:"red"
            }}
          >
            <Box
              sx={{
                bgcolor: "#EBEFF8",
                borderRadius: "10px",
                // margin: "20px ",
                padding: "25px 20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
                textAlign: "center",
                color: "#3B5BBA",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  // bgcolor: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  {/* <video width="100" height="100" controls>
                    <source src="./credmudra.mp4" type="video/mp4" />
                  </video> */}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/commonImages/selfemployedprofetional.png"
                    }
                    alt=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontStyle: "italic",
                      fontWeight: "700",
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ fontSize: "17px" }}>Salaried</span>
                    <br />
                    You receive a fixed, regular payment, like a monthly
                    paycheck, from an employer.
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: "100%" }} />
              <Box
                sx={{
                  // bgcolor: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  {/* <video width="100" height="100" controls preload="auto">
                    <source src="https://www.youtube.com/watch?v=gi53QT-4d4Y" />
                  </video> */}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/commonImages/selfemployed.png"
                    }
                    alt=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontStyle: "italic",
                      fontWeight: "700",
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                  >
                    <span style={{ fontSize: "17px" }}>Self Employed</span>
                    <br />
                    You earn income by working for yourself, not a company. Your
                    income depends on the work you do and the clients you find.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <CommonRadioButtons
              id={"employmentType"}
              name={"employmentType"}
              options={employmentOptions}
              value={formData.employmentType}
              onChange={(e, newValue) => {
                dispatch(
                  setFormData({
                    employmentType: e.target.value,
                  })
                );
                sessionStorage.setItem("employmentType", e.target.value);

                formik.handleChange("employmentType")(e.target.value);
              }}
              matchKey={"label"}
            />
          </Grid>
          {/* <SelfEmployed /> */}
          {formData?.employmentType === "Salaried" && <Salaried />}
          {formData?.employmentType === "Self Employed" && <SelfEmployed />}
          {!formData.employmentType && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CommonButton buttonText={"Save"} />
            </Grid>
          )}
        </Grid>
      </Container>
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default EmploymentDetails;
