import { Box, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { TabContext, TabPanel } from "@mui/lab";
import LoanApplication from "./components/loanApplication";
import GetPreApprovedOffer from "./components/getPreApprovedOffer";
import SelectLender from "./components/selectLender";
// import ProceedToApply from "./components/proceedToApply";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLenderDetails } from "../../../../store/reducers";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../config/apiEndPointConfig";
import Submit from "./components/submitPage";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const [windowOpen, windowOpenData] = useApiCallMutation();

  //Get Temp Lead Api
  const GetTemporaryLead = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getTempLead,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    windowOpen({
      endPoint: apiEndPointsConfig?.windowOpen,
      method: "GET",
    });
    GetTemporaryLead.refetch();
  }, []);

  useEffect(() => {
    if (windowOpenData.isSuccess) {
      if (
        !windowOpenData.data?.data?.open &&
        location.state?.from !== "offerPage"
      ) {
        navigate("/profiling/dashboard/products");
      }
    }
  }, [windowOpenData]);
  useEffect(() => {
    if (GetTemporaryLead?.isSuccess) {
      sessionStorage.setItem(
        "tempLead",
        location.state?.id
          ? location.state?.id
          : GetTemporaryLead?.data?.data?.leadId
      );
    }
  }, [GetTemporaryLead]);

  //lead status api
  const [LeadStatusApi, LeadStatusApiData] = useApiCallMutation();
  const leadStatusFunction = () => {
    const data = {
      leadId: location.state?.id,
    };
    LeadStatusApi({
      endPoint: apiEndPointsConfig?.LeadStatus,
      method: "POST",
      data: { data },
    });
  };
  useEffect(() => {
    if (LeadStatusApiData?.isSuccess) {
      const response = JSON.stringify(LeadStatusApiData?.data);
      sessionStorage.setItem("Lenders", response);
      dispatch(setLenderDetails(LeadStatusApiData?.data));
      sessionStorage.setItem("leadEligibility", "marketplace");
      setValue(2);
      // if (LeadStatusApiData?.data?.data?.status?.length === 0) {
      //   sessionStorage.setItem("leadEligibility", false);
      //   navigateToSelectLender();
      // }
    }
  }, [LeadStatusApiData?.isSuccess]);

  let loanType = "";
  if (location.pathname === "/profiling/dashboard/personalLoan") {
    loanType = "Personal Loan";
  } else if (location.pathname === "/profiling/dashboard/businessLoan") {
    loanType = "Business Loan";
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabStyles = {
    fontWeight: 700,
    fontSize: "14px",
  };
  const selectedTabStyles = {
    ...tabStyles,
    borderBottom: "2px solid blue",
  };
  const indicatorStyle = {
    "& .MuiTabs-indicator": {
      backgroundColor: "blue",
    },
  };

  useEffect(() => {
    if (location.state?.from === "offerPage") {
      leadStatusFunction();
    }
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("publicLeadId") ||
      location.state?.from === "otp"
    ) {
      sessionStorage.setItem("leadEligibility", "marketplace");
      setValue(2);
    }
  }, []);

  return (
    <Box
      sx={{
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box>
        <Typography
          sx={{
            mb: 3,
            fontWeight: 700,
            fontSize: { xs: "23px", sm: "26px" },
            color: "#3B5BBA",
            textAlign: "center",
          }}
        >
          {loanType}
        </Typography>
      </Box>
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons={"false"}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary"
            indicatorColor="blue"
            sx={{
              indicatorStyle,
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            <Tab
              value={0}
              sx={value === 0 ? selectedTabStyles : tabStyles}
              label="Loan Application"
              disabled={value !== 0}
            />
            <Tab
              sx={value === 1 ? selectedTabStyles : tabStyles}
              label="Get Pre-approved Offer"
              disabled={value !== 1}
            />
            <Tab
              sx={value === 2 ? selectedTabStyles : tabStyles}
              label="Select Lender"
              disabled={value !== 2}
            />
          </Tabs>
        </Box>
        <TabPanel value={0} sx={{ padding: "0px" }}>
          <LoanApplication updateTab={setValue} />
        </TabPanel>
        <TabPanel value={1} sx={{ padding: "0px" }}>
          <GetPreApprovedOffer updateTab={setValue} />
        </TabPanel>
        <TabPanel sx={{ width: "100%", padding: "0px" }} value={2}>
          <SelectLender updateTab={setValue} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Index;
