import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    leadId: "",
    stage: "",
    contactNo: "",
    termsAndCondition: true,
    termsAndCondition1: true,
    loan: {
      reason: "",
      otherReason: "",
      amount: 1000,
      tenure: 1,
    },
    panCard: "",
    aadharNo: "",
    emailId: "",
    employmentType: "",
    employmentDetails: {
      companyTypeId: "",
      industryTypeId: "",
      companyName: "",
      designation: "",
      address: "",
      pinCode: "",
      countryId: "",
      stateId: "",
      cityId: "",
      yearsWorkingIn: "",
      businessTypeId: "",
      professionTypeId: "",
      turnover: "",
      monthlyProfit: "",
      income: "",
      salaryMode: "",
      bankId: "",
      currentAccountBankId: "",
      savingAccountBankId: "",
      businessOwnedId: "",
      gst: "",
    },
    personalInfo: {
      emailId: "",
      firstName: "",
      lastName: "",
      genderId: "",
      dateOfBirth: "",
      qualificationId: "",
      maritalStatus: "",
    },
    address: {
      addressLine1: "",
      addressLine1: "",
      pinCode: "",
      countryId: "",
      stateId: "",
      cityId: "",
      residenceTypeId: "",
      yearsLivingIn: "",
    },
    finance: {
      panCard: "",
      aadharNo: "",
    },
    others: {
      totalEmiPaidMonthly: 0,
      interestedToGetCreditCard: true,
    },
    utm: {
      id: "",
      url: "",
      source: "",
      medium: "",
      campaign: "",
      term: "",
      content: "",
      clickId: "",
    },
    // employmentDetails: {
    //   companyTypeId: "",
    //   industryTypeId: "",
    //   companyName: "",
    //   designation: "",
    //   address: "",
    //   pinCode: "",
    //   countryId: "",
    //   stateId: "",
    //   cityId: "",
    //   yearsWorkingIn: "",
    //   businessTypeId: "",
    //   professionTypeId: "",
    //   turnover: "",
    //   monthlyProfit: "",
    //   income: "",
    //   salaryMode: "",
    //   bankId: "",
    //   currentAccountBankId: "",
    //   savingAccountBankId: "",
    //   businessOwnedId: "",
    //   gst: "",
    // },
    extras: {
      browser: "",
      operatingSystem: "",
      ipAddress: "",
      timestamp: "",
      userAgent: "",
      location: "",
    },
  },
  isLoading: false,
  companyTypeList: [],
  businessOwnedList: [],
  businessTypeList: [],
  industryTypeList: [],
  bankList: [],
  qualificationsList: [],
  genderTypesList: [],
  residenceTypesList: [],
  loanReasonList: [],
  offersList:[],
  companyAddress: { city: "", state: "", country: "" },
  personalAddress: { city: "", state: "", country: "" },
  LendersDetails: {},
  loanType: "",
  employType: "",
  pinCodeError: { companyPincode: "", addressPincode: "" },
  userExist: false,
  isBottomNavigation: false,
  extraDetails: {},
  utmDetails: {},
  panCardError:"",

};

// Create a slice of the store
const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    setFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setBusinessOwned(state, action) {
      state.businessOwnedList = action.payload;
    },
    setBusinessType(state, action) {
      state.businessTypeList = action.payload;
    },
    setIndustryType(state, action) {
      state.industryTypeList = action.payload;
    },
    setBanks(state, action) {
      state.bankList = action.payload;
    },
    setCompanyType(state, action) {
      state.companyTypeList = action.payload;
    },
    setLoanReason(state, action) {
      state.loanReasonList = action.payload;
    },
    setQualifications(state, action) {
      state.qualificationsList = action.payload;
    },
    setGenderType(state, action) {
      state.genderTypesList = action.payload;
    },
    setResidenceType(state, action) {
      state.residenceTypesList = action.payload;
    },
    setCompanyAddress(state, action) {
      state.companyAddress = action.payload;
    },
    setPersonalAddress(state, action) {
      state.personalAddress = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setLenderDetails(state, action) {
      state.LendersDetails = action.payload;
    },
    setLoanType(state, action) {
      state.loanType = action.payload;
    },
    setEmployType(state, action) {
      state.employType = action.payload;
    },
    setPincodeError(state, action) {
      state.pinCodeError = action.payload;
    },
    setPancardError: (state, action) => {
      if(state.formData.panCard.length === 10) {
        state.panCardError = action.payload;
      } else {
        state.panCardError = "";
      }
    },
    setUserExist(state, action) {
      state.userExist = action.payload;
    },
    setIsBottomNavigation(state, action) {
      state.isBottomNavigation = action.payload;
    },
    setExtraDetails: (state, action) => {
      state.extraDetails = action.payload;
    },
    setUtmDetails: (state, action) => {
      state.utmDetails = action.payload;
    },
    setOffersList: (state, action) => {
      state.offersList = action.payload;
    },
    resetForm: () => initialState,
   
  },
});

export const {
  setFormData,
  setBusinessOwned,
  setBusinessType,
  setIndustryType,
  setBanks,
  setCompanyType,
  setLoanReason,
  setQualifications,
  setGenderType,
  setResidenceType,
  setCompanyAddress,
  setPersonalAddress,
  setIsLoading,
  setLenderDetails,
  setLoanType,
  setEmployType,
  setPincodeError,
  setUserExist,
  setIsBottomNavigation,
  setExtraDetails, 
  setUtmDetails,
  setPancardError,
  setOffersList,
  resetForm

} = formSlice.actions;

export default formSlice.reducer;
