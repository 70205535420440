import React from 'react';
import { Box, Typography } from '@mui/material';

const InfoBox = ({ iconSrc, text, boldText }) => {
    return (
        <Box
            sx={{
                display: "flex",
                color: "#404040",
                gap: "20px",
                paddingBottom: "10px",
            }}
        >
            <img
                src={iconSrc}
                alt="icon"
                width="15px"
            />
            <Typography sx={{ fontSize: "14px", textAlign: "left",fontFamily:"Lato" }}>
                {text}
                <span style={{ color: "#343435", fontWeight: "600" }}>
                    {" "}
                    {boldText}
                </span>
            </Typography>
        </Box>
    );
};

export default InfoBox;
