import React, { useEffect, useRef, useState } from "react";
import GlobalInput from "../../../../components/globalInput";
import { useFormik } from "formik";
import { personalDetailsSchema } from "../../../../utils/validateSchema";
import { Box, Container, FormHelperText, Grid } from "@mui/material";
import GlobalInputLable from "../../../../components/globalInputLable";
import CommonButton from "../../../../components/commonButton";
import { useNavigate } from "react-router-dom";
import CommanTypo from "../../../../components/commanTypo";
import CommonDropdown from "../../../../components/commonDropDown";
import CommonRadioButtons from "../../../../components/commonRadioButtons";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiCallServices";
import {
  setCompanyAddress,
  setFormData,
  setGenderType,
  setPancardError,
  setPersonalAddress,
  setQualifications,
} from "../../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import apiEndPointsConfig from "../../../../config/apiEndPointConfig";
import { getInitialPersonalDetailsValues } from "../../../../utils/initialValues";
import InfoBox from "../../../../components/infobox";
import { errorFocus } from "../../../../utils/errorFocus";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [isReinitialize, setIsReinitialize] = useState(true);
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  const panCardErrorData = useSelector((state) => state?.form?.panCardError);
  const QualificationsListData = useSelector(
    (state) => state?.form?.qualificationsList
  );
  const GenderTypesListData = useSelector(
    (state) => state?.form?.genderTypesList
  );
  const maritalStatus = [
    { value: 1, label: "Single" },
    { value: 2, label: "Married" },
  ];
  //qualification api call
  const GetQualificationsList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getQualifications,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetQualificationsList?.isSuccess &&
      GetQualificationsList?.data?.data !== null
    ) {
      const modifyres = GetQualificationsList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setQualifications(modifyres));
    }
  }, [GetQualificationsList?.isSuccess]);

  //gender api call
  const GetGenderTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getGenderTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetGenderTypesList?.isSuccess &&
      GetGenderTypesList?.data?.data !== null
    ) {
      const modifyres = GetGenderTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setGenderType(modifyres));
    }
  }, [GetGenderTypesList?.isSuccess]);

  //Drop-Off Api
  const [DropOffApi, dropOffApiData] = useApiCallMutation();
  const dropOffRequest = async () => {
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      stage: "personal details",
      personalInfo: {
        firstName: formData?.personalInfo?.firstName,
        lastName: formData?.personalInfo?.lastName,
        emailId: formData?.personalInfo?.emailId,
        dateOfBirth: formData?.personalInfo?.dateOfBirth,
        genderId: formData?.personalInfo?.genderId,
        maritalStatus: formData?.personalInfo?.maritalStatus,
        qualificationId: formData?.personalInfo?.qualificationId,
      },
      panCard: formData?.panCard,
      aadharNo: formData?.aadharNo,
    };
    DropOffApi({
      endPoint: apiEndPointsConfig?.newDropOff,
      method: "POST",
      data: { data },
    });
  };
  //User Pan Card Check Api

  //handleSubmit
  const handleSubmit = (e) => {
    if (panCardErrorData === "") {
      const isExistUser = sessionStorage.getItem("isExistUser");
      if (!isExistUser) {
        dropOffRequest();
      }
      navigate("/profiling/profile/employmentdetails");
    } else {
      document.getElementById("panCard").focus();
    }
  };
  const formik = useFormik({
    initialValues: getInitialPersonalDetailsValues(formData),
    validationSchema: personalDetailsSchema,
    onSubmit: handleSubmit,
    enableReinitialize: isReinitialize,
  });
  // error Focus
  useEffect(() => {
    if (formik?.isSubmitting) {
      errorFocus(formik?.errors);
    }
  }, [formik?.isSubmitting, formik?.errors]);
  const [userPancardCheckApi, userPancardCheckData] = useApiCallMutation();
  const userPancardCheckForm = async (event) => {
    if (
      /^(?![ ]).*$/.test(event.target.value) &&
      event.target.value.length <= 10
    ) {
      const inputValue = event.target.value.toUpperCase();
      dispatch(setPancardError(""));
      dispatch(
        setFormData({
          ...formData,
          panCard: inputValue,
          finance: {
            ...formData?.finance,
            panCard: inputValue,
          },
        })
      );
      dispatch(setPancardError(""));

      if (event.target.value?.length === 10) {
        // dispatch(setPancardError(""));
        const data = {
          panCard: inputValue,
        };
        userPancardCheckApi({
          endPoint: apiEndPointsConfig?.userPancardCheck,
          method: "POST",
          data: {
            data,
          },
        });
      } else {
        dispatch(setPancardError(""));
      }
    }
  };
  useEffect(() => {
    if (userPancardCheckData?.isSuccess) {
      const panCardAlreadyLinked =
        userPancardCheckData?.data?.data?.belongToAnotherContactNo;
      if (panCardAlreadyLinked) {
        dispatch(
          setPancardError("PAN card already linked to another mobile number")
        );
      } else {
        dispatch(setPancardError(""));
      }
    }
  }, [userPancardCheckData?.isSuccess, formik]);
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <Box
      ref={formRef}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <Container maxWidth={"sm"}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowGap={4}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable
                lableText={"Please provide your first name (as per PAN Card)"}
              />
              <GlobalInput
                type={""}
                placeholder={"Enter Your First Name"}
                id="firstName"
                name="firstName"
                onChange={(event, newValue) => {
                  setIsReinitialize(true);
                  if (/^[A-Za-z]*$/.test(event.target.value)) {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          firstName: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                dynamicInputProps={{ maxLength: 20 }}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                onBlur={formik.handleBlur}
                value={formData?.personalInfo?.firstName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable
                lableText={"Please provide your last name (as per PAN Card)"}
              />
              <GlobalInput
                type={""}
                placeholder={"Enter Your Last Name"}
                id="lastName"
                name="lastName"
                onChange={(event) => {
                  setIsReinitialize(false);
                  if (/^[A-Za-z]*$/.test(event.target.value)) {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          lastName: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                onBlur={formik.handleBlur}
                dynamicInputProps={{ maxLength: 20 }}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                value={formData?.personalInfo?.lastName}
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable lableText={"Please provide your PAN number"} />
              <CommanTypo
                text={
                  " Your PAN number is used to assess your creditworthiness"
                }
              />
              <GlobalInput
                type={""}
                placeholder={"Pan Number"}
                id="panCard"
                name="panCard"
                onChange={(e) => {
                  setIsReinitialize(false);
                  const inputValue = e.target.value.toUpperCase();
                  // if (
                  //   /^(?![ ]).*$/.test(e.target.value) &&
                  //   e.target.value.length <= 10
                  // ) {
                  //   dispatch(setPancardError(""));
                  //   dispatch(
                  //     setFormData({
                  //       ...formData,
                  //       panCard: inputValue,
                  //       finance: {
                  //         ...formData?.finance,
                  //         panCard: inputValue,
                  //       },
                  //     })
                  //   );
                  // }
                  // if (e.target.value?.length === 10) {
                  userPancardCheckForm(e);
                  // }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                dynamicInputProps={{ maxLength: 10 }}
                error={formik.touched.panCard && Boolean(formik.errors.panCard)}
                helperText={formik.touched.panCard && formik.errors.panCard}
                value={formData?.panCard}
              />
              {panCardErrorData ? (
                <FormHelperText
                  sx={{
                    color: "rgb(211, 47, 47)",
                    fontSize: "0.75rem",
                    ml: "14px",
                    mt: "-11px",
                  }}
                >
                  {panCardErrorData}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={
                  "Please provide the last 4 digits of your Aadhar number."
                }
              />
              <GlobalInput
                type="tel"
                inputMode="numeric"
                placeholder={"Last Four Digit.."}
                id="aadharNo"
                name="aadharNo"
                onChange={(e) => {
                  setIsReinitialize(false);
                  if (
                    /^\d*$/.test(e.target.value) &&
                    e.target.value.length <= 4
                  ) {
                    dispatch(
                      setFormData({
                        ...formData,
                        aadharNo: e.target.value,
                        finance: {
                          ...formData.finance,
                          aadharNo: e.target.value,
                        },
                      })
                    );
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formData?.aadharNo}
                error={
                  formik.touched.aadharNo && Boolean(formik.errors.aadharNo)
                }
                helperText={formik.touched.aadharNo && formik.errors.aadharNo}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable
                lableText={
                  "We'd love to have your email address for sending you exclusive updates."
                }
              />
              <CommanTypo
                text={"Your email address will be used for communication"}
              />
              <GlobalInput
                type={""}
                placeholder={"abc@xyz.com"}
                id="emailId"
                name="emailId"
                onChange={(event, newValue) => {
                  setIsReinitialize(false);
                  if (/^(?![ ]).*$/.test(event.target.value)) {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          emailId: event.target.value.trim(),
                        },
                      })
                    );
                    formik.setFieldValue("emailId", event.target.value.trim());
                  }
                }}
                // onBlur={formik.handleBlur}
                error={formik.touched.emailId && Boolean(formik.errors.emailId)}
                helperText={formik.touched.emailId && formik.errors.emailId}
                // error={formik.errors.emailId}
                // helperText={formik.errors.emailId}
                onBlur={formik.handleBlur}
                value={formData?.personalInfo?.emailId}
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={"Please provide your date of birth"}
              />
              <GlobalInput
                type={"date"}
                placeholder={"Dob"}
                id="dateOfBirth"
                name="dateOfBirth"
                onChange={(event) => {
                  setIsReinitialize(false);
                  const selectedDate = new Date(event.target.value);
                  const year = selectedDate.getFullYear();
                  if (year > 9999) {
                    event.target.value = event.target.value;
                  } else {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          dateOfBirth: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                error={
                  formik.touched.dateOfBirth &&
                  Boolean(formik.errors.dateOfBirth)
                }
                helperText={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                }
                // error={formik.errors.dateOfBirth}
                // helperText={formik.errors.dateOfBirth}
                onBlur={formik.handleBlur}
                value={formData?.personalInfo?.dateOfBirth}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={
                  "We’d love to know your gender, if you’re comfortable sharing"
                }
              />
              <CommonRadioButtons
                id={"genderId"}
                name={"genderId"}
                options={GenderTypesListData}
                onChange={(event, newValue) => {
                  setIsReinitialize(false);
                  const selectedValue = event.target.value;
                  const selectedOption = GenderTypesListData.find((option) => {
                    return option.label === selectedValue;
                  });
                  dispatch(
                    setFormData({
                      personalInfo: {
                        ...formData.personalInfo,
                        genderId: selectedOption?.value,
                      },
                    })
                  );
                  formik.handleChange("genderId")(selectedOption?.value);
                }}
                value={formData?.personalInfo?.genderId}
                // formData={formData}
                error={
                  formik.touched.genderId && Boolean(formik.errors.genderId)
                }
                helperText={formik.touched.genderId && formik.errors.genderId}
                matchKey={"value"}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={"Could you let us know your marital  status?"}
              />
              <CommonRadioButtons
                id={"maritalStatus"}
                name={"maritalStatus"}
                options={maritalStatus}
                onChange={(e) => {
                  setIsReinitialize(false);
                  dispatch(
                    setFormData({
                      personalInfo: {
                        ...formData?.personalInfo,
                        maritalStatus: e.target.value,
                      },
                    })
                  );
                  formik.handleChange("maritalStatus")(e?.target?.value);
                }}
                value={formData?.personalInfo?.maritalStatus}
                // value={formData?.personalInfo?.maritalStatus}
                error={
                  formik.touched.maritalStatus &&
                  Boolean(formik.errors.maritalStatus)
                }
                helperText={
                  formik.touched.maritalStatus && formik.errors.maritalStatus
                }
                matchKey={"label"}
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={"Tell us your academic qualification"}
              />
              <CommonDropdown
                // disableClear={true}
                id={"qualificationId"}
                name={"qualificationId"}
                options={QualificationsListData || []}
                onBlur={formik.handleBlur}
                value={
                  formData?.personalInfo?.qualificationId
                    ? QualificationsListData?.find(
                        (option) =>
                          formData?.personalInfo?.qualificationId ===
                          option.value
                      )?.label
                    : null
                }
                onChange={(event, newValue) => {
                  setIsReinitialize(false);
                  dispatch(
                    setFormData({
                      personalInfo: {
                        ...formData.personalInfo,
                        qualificationId: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("qualificationId", newValue?.value);
                }}
                error={
                  formik.touched.qualificationId &&
                  Boolean(formik.errors.qualificationId)
                }
                helperText={
                  formik.touched.qualificationId &&
                  formik.errors.qualificationId
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CommonButton buttonText={"Save"} />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalDetails;
