import { Box, Divider, useMediaQuery } from "@mui/material";
import React from "react";
import SiteBar from "../sitebar";
import { Outlet } from "react-router-dom";
import BottomNavigationBar from "../../components/bottomNavigation";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Dashboard = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const sideRoutes = [
    {
      name: "Apply Loan",
      isCompleted: "",
      route: "/profiling/dashboard/products",
    },
    {
      name: "Track Loans",
      isCompleted: "",
      route: "/profiling/dashboard/previous-credit",
    },
    {
      name: "Check Credit Score",
      isCompleted: "",
      route: "/profiling/dashboard/check-credit-score",
      isDisabled: true,
    },
    {
      name: "Queries",
      isCompleted: "",
      route: "/profiling/dashboard/queries",
    },
    {
      name: "Manage Profile",
      isCompleted: "",
      route: "/profiling/profile/personaldetails",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
        justifyContent: "space-between",
      }}
    >
      {!isMobile ? (
        <>
          <Box sx={{ width: { sm: "35%", lg: "25%" } }}>
            {" "}
            {/* Adjust width for mobile */}
            <SiteBar sideRoutes={sideRoutes} />
          </Box>
          <Divider
            sx={{ width: "50px", height: "auto" }}
            orientation="vertical"
            flexItem
          />
        </>
      ) : (
        <Box width={"100%"}>
          {" "}
          {/* Adjust width for mobile */}
          <BottomNavigationBar />
        </Box>
      )}

      <Box
        width={isMobile ? "100%" : "80%"}
        sx={{ paddingLeft: !isMobile && "40px" }}
      >
        <Box
          sx={{
            border: isMobile ? "none" : "0.2px solid #dfdfdf",
            borderRadius: "10px",
            padding: isMobile ? "0px" : "30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: {sm:'70vh'},
            overflowY: "scroll",
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
            overflowX: "hidden",
            // width: "90%",
            marginBottom: isMobile ? "30px" : "0px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "100%",
              overflowY: "visible",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
