import { Typography } from "@mui/material";
import React from "react";

function GlobalInputLable({lableText,align}) {
  return (
    <>
      <Typography
        sx={{
          color: " var(--Text-100, #343435)",
          textAlign:  align ? align : "center",
          fontFamily: "Lato",
          fontSize: {xs:"1.1rem",sm:"1.3rem",lg:"1.5rem"},
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          letterSpacing: "-0.24px",
        }}
        variant="subtitle1"
      >
       {lableText}
      </Typography>
    </>
  );
}

export default GlobalInputLable;
