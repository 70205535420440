import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import numberToWords from "number-to-words";
import { FormHelperText, Grid } from "@mui/material";
import CommonDropdown from "../../../../../components/commonDropDown";
import GlobalInputLable from "../../../../../components/globalInputLable";
import GlobalInput from "../../../../../components/globalInput";
import { getInitialSalariedValues } from "../../../../../utils/initialValues";
import { salariedSchema } from "../../../../../utils/validateSchema";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../../config/apiEndPointConfig";
import {
  setBanks,
  setCompanyAddress,
  setCompanyType,
  setFormData,
  setIndustryType,
  setPincodeError,
} from "../../../../../store/reducers";
import CommonRadioButtons from "../../../../../components/commonRadioButtons";
import CommonButton from "../../../../../components/commonButton";
import { errorFocus } from "../../../../../utils/errorFocus";

const Salaried = () => {
  const CompanyAddressData = useSelector(
    (state) => state?.form?.companyAddress
  );
  const PinCodeErrorData = useSelector((state) => state?.form?.pinCodeError);
  const [incomInWords, setIncomInWords] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector((state) => state?.form?.formData);
  const IndustryTypeListData = useSelector(
    (state) => state?.form?.industryTypeList
  );
  const CompanyTypeListData = useSelector(
    (state) => state?.form?.companyTypeList
  );
  const BankListData = useSelector((state) => state?.form?.bankList);

  const GetIndustryTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getIndustryTypes,
    method: "POST",
    data: {},
  });
  const GetCompanyTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getCompanyTypes,
    method: "POST",
    data: {},
  });
  const GetAllBankList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBanks,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetIndustryTypesList?.isSuccess &&
      GetIndustryTypesList?.data?.data !== null
    ) {
      const modifyres = GetIndustryTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setIndustryType(modifyres));
    }
  }, [GetIndustryTypesList?.isSuccess]);
  useEffect(() => {
    if (
      GetCompanyTypesList?.isSuccess &&
      GetCompanyTypesList?.data?.data !== null
    ) {
      const modifyres = GetCompanyTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setCompanyType(modifyres));
    }
  }, [GetCompanyTypesList?.isSuccess]);
  useEffect(() => {
    if (GetAllBankList?.isSuccess && GetAllBankList?.data?.data !== null) {
      const modifyres = GetAllBankList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBanks(modifyres));
    }
  }, [GetAllBankList?.isSuccess]);
  const Year = [
    { label: "1 Year", value: "1 Year" },
    { label: "1-2 Years", value: "1-2 Years" },
    { label: "3 Years", value: "3 Years" },
    { label: "3-5 Years", value: "3-5 Years" },
    { label: "5+ Years", value: "5+ Years" },
  ];
  const salaryMode = [
    { value: 1, label: "Bank Transfer" },
    { value: 2, label: "Cash" },
  ];
  const navigateToPersonalDetails = () => {
    navigate("/profiling/profile/residencedetails");
  };
  //getAddress API Call
  const [GetPinCodeAddressApi, getPinCodeAddressApiData] = useApiCallMutation();

  const getAddress = async (event) => {
    if (
      /^\d*$/.test(event.target.value) &&
      event.target.value.length <= 6 &&
      event.target.value !== "000000"
    ) {
      dispatch(
        setPincodeError({
          ...PinCodeErrorData,
          companyPincode: "",
        })
      );
      dispatch(
        setFormData({
          employmentDetails: {
            ...formData.employmentDetails,
            pinCode: event.target.value,
          },
        })
      );
      if (event.target.value?.length === 6) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "",
          })
        );
        const data = {
          pinCode: event.target.value,
        };
        GetPinCodeAddressApi({
          endPoint: apiEndPointsConfig.getPincodesAddress,
          method: "POST",
          data: { data },
        });
      } else {
        dispatch(
          setFormData({
            employmentDetails: {
              ...formData.employmentDetails,
              pinCode: event.target.value,
              cityId: "",
              stateId: "",
              countryId: "",
            },
          })
        );
        dispatch(
          setCompanyAddress({
            city: "",
            state: "",
            country: "",
          })
        );
      }
    }
  };
  useEffect(() => {
    if (getPinCodeAddressApiData?.isSuccess) {
      if (getPinCodeAddressApiData?.data?.status?.code === 200) {
        dispatch(
          setFormData({
            employmentDetails: {
              ...formData.employmentDetails,
              pinCode: formData?.employmentDetails?.pinCode,
              cityId: getPinCodeAddressApiData?.data?.data?.city?.id,
              stateId: getPinCodeAddressApiData?.data?.data?.state?.id,
              countryId: getPinCodeAddressApiData?.data?.data?.country?.id,
            },
          })
        );
        dispatch(
          setCompanyAddress({
            city: getPinCodeAddressApiData?.data?.data?.city?.name,
            state: getPinCodeAddressApiData?.data?.data?.state?.name,
            country: getPinCodeAddressApiData?.data?.data?.country?.name,
          })
        );
      } else if (getPinCodeAddressApiData?.data?.status.code === 404) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "Pincode does not exists",
          })
        );
        // formik.setFieldError("pinCode", "invalid pincode ");
      } else {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "",
          })
        );
      }
    }
  }, [getPinCodeAddressApiData?.isSuccess]);
  const [DropOffApi, dropOffApiData] = useApiCallMutation();
  const dropOffRequest = async () => {
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      stage: "employment details",
      employmentDetails: {
        companyTypeId: formData?.employmentDetails?.companyTypeId,
        industryTypeId: formData?.employmentDetails?.industryTypeId,
        companyName: formData?.employmentDetails?.companyName,
        designation: formData?.employmentDetails?.designation,
        address: formData?.employmentDetails?.address,
        pinCode: formData?.employmentDetails?.pinCode,
        countryId: formData?.employmentDetails?.countryId,
        stateId: formData?.employmentDetails?.stateId,
        cityId: formData?.employmentDetails?.cityId,
        yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
        // businessTypeId: formData?.employmentDetails?.businessTypeId,
        // professionTypeId: formData?.employmentDetails?.professionTypeId,
        // turnover: formData?.employmentDetails?.turnover,
        // monthlyProfit: formData?.employmentDetails?.monthlyProfit,
        income: formData?.employmentDetails?.income,
        salaryMode: formData?.employmentDetails?.salaryMode,
        bankId: formData?.employmentDetails?.bankId,
        // currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
        // savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
        // businessOwnedId: formData?.employmentDetails?.businessOwnedId,
        // gst: formData?.employmentDetails?.gst,
      },
      employmentType: formData?.employmentType,
    };
    DropOffApi({
      endPoint: apiEndPointsConfig?.newDropOff,
      method: "POST",
      data: { data },
    });
  };
  const handleSubmit = async () => {
    dispatch(
      setFormData({
        employmentDetails: {
          ...formData.employmentDetails,
          monthlyProfit: "0",
          turnover: "0",
        },
      })
    );
    if (PinCodeErrorData?.companyPincode === "") {
      const isExistUser = sessionStorage.getItem("isExistUser");
      if (!isExistUser) {
        await dropOffRequest();
      }
      navigateToPersonalDetails();
    } else {
      document.getElementById("pinCode").focus();
    }
    navigateToPersonalDetails();
  };
  const formik = useFormik({
    initialValues: getInitialSalariedValues(formData),
    validationSchema: salariedSchema,
    onSubmit: handleSubmit,
  });
  // error Focus
  useEffect(() => {
    if (formik?.isSubmitting) {
      errorFocus(formik?.errors);
    }
  }, [formik?.isSubmitting, formik?.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowGap={4}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",

            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select company type"} />
          <CommonDropdown
            id={"companyTypeId"}
            name={"companyTypeId"}
            options={CompanyTypeListData || []}
            value={
              formData?.employmentDetails?.companyTypeId
                ? CompanyTypeListData?.find(
                    (option) =>
                      formData?.employmentDetails?.companyTypeId ===
                      option.value
                  )?.label
                : null
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    companyTypeId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("companyTypeId", newValue);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyTypeId &&
              Boolean(formik.errors.companyTypeId)
            }
            helperText={
              formik.touched.companyTypeId && formik.errors.companyTypeId
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select industry"} />
          <CommonDropdown
            id={"industryTypeId"}
            name={"industryTypeId"}
            options={IndustryTypeListData ? IndustryTypeListData : ""}
            fullWidth
            value={
              formData?.employmentDetails?.industryTypeId
                ? IndustryTypeListData?.find(
                    (option) =>
                      formData?.employmentDetails?.industryTypeId ===
                      option.value
                  )?.label
                : null
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    industryTypeId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("industryTypeId", newValue);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.industryTypeId &&
              Boolean(formik.errors.industryTypeId)
            }
            helperText={
              formik.touched.industryTypeId && formik.errors.industryTypeId
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter your company name"} />
          <GlobalInput
            type={""}
            placeholder={"Enter the Company Name"}
            id={"companyName"}
            name={"companyName"}
            value={formData?.employmentDetails?.companyName}
            onChange={(event, newValue) => {
              if (/^(?![ ]).*$/.test(event.target.value)) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      companyName: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select your designation"} />
          <GlobalInput
            type={""}
            placeholder={"Enter the Designation"}
            id={"designation"}
            name={"designation"}
            value={formData?.employmentDetails?.designation}
            onChange={(event, newValue) => {
              if (/^(?![ ]).*$/.test(event.target.value)) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      designation: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={
              formik.touched.designation && Boolean(formik.errors.designation)
            }
            helperText={formik.touched.designation && formik.errors.designation}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter company address "} />
          <GlobalInput
            type={""}
            placeholder={"Enter the Company address "}
            id={"address"}
            name={"address"}
            value={formData?.employmentDetails?.address}
            onChange={(event, newValue) => {
              if (
                /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(event.target.value)
              ) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      address: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }
            }}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            // error={formik.errors.address}
            // helperText={formik.errors.address}
            onBlur={formik.handleBlur}
          />

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <GlobalInput
                type="tel"
                inputMode="numeric"
                placeholder={"  Pincode"}
                id={"pinCode"}
                name={"pinCode"}
                value={formData?.employmentDetails?.pinCode}
                onChange={(event) => {
                  getAddress(event);
                  formik.handleChange(event);
                }}
                error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
                // error={formik.errors.pinCode}
                // helperText={formik.errors.pinCode}
                onBlur={formik.handleBlur}
              />
              {PinCodeErrorData?.companyPincode ? (
                <FormHelperText
                  sx={{
                    color: "rgb(211, 47, 47)",
                    fontSize: "0.75rem",
                    ml: "14px",
                  }}
                >
                  {PinCodeErrorData?.companyPincode}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6}>
              <GlobalInput
                disable={true}
                type={""}
                placeholder={"City"}
                id={"city"}
                name={"cityId"}
                value={CompanyAddressData?.city}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                // error={formik.errors.city}
                // helperText={formik.errors.city}
                onBlur={formik.handleBlur}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <GlobalInput
                disable={true}
                type={""}
                placeholder={"State"}
                id={"state"}
                name={"state"}
                value={CompanyAddressData?.state}
                onChange={(event, newValue) => {
                  formik.handleChange(event);
                }}
                // error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}
                // error={formik.errors.state}
                // helperText={formik.errors.state}
                onBlur={formik.handleBlur}
              />
            </Grid> */}

            {/* <Grid item xs={6}>
              <GlobalInput
                disable={true}
                type={""}
                placeholder={"Country"}
                id={"country"}
                name={"country"}
                value={CompanyAddressData?.country}
                onChange={(event, newValue) => {
                  formik.handleChange(event);
                }}
                // error={formik.touched.country && Boolean(formik.errors.country)}
                // helperText={formik.touched.country && formik.errors.country}
                // error={formik.errors.country}
                // helperText={formik.errors.country}
                onBlur={formik.handleBlur}
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable
            lableText={
              "How many years have you been working in your current company?"
            }
          />
          <CommonDropdown
            id={"yearsWorkingIn"}
            name={"yearsWorkingIn"}
            options={Year}
            value={formData?.employmentDetails?.yearsWorkingIn}
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    yearsWorkingIn: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("yearsWorkingIn", newValue);
            }}
            error={
              formik.touched.yearsWorkingIn &&
              Boolean(formik.errors.yearsWorkingIn)
            }
            helperText={
              formik.touched.yearsWorkingIn && formik.errors.yearsWorkingIn
            }
            onBlur={formik.handleBlur}

            // error={formik.errors.yearsWorkingIn}
            // helperText={formik.errors.yearsWorkingIn}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please enter your monthly income"} />
          <GlobalInput
            type="tel"
            inputMode="numeric"
            placeholder={"Enter the monthly Income"}
            id={"income"}
            name={"income"}
            value={formData?.employmentDetails?.income}
            onChange={(e, newValue) => {
              if (/^\d*$/.test(e.target.value)) {
                dispatch(
                  setFormData({
                    employmentDetails: {
                      ...formData.employmentDetails,
                      income: e.target.value,
                    },
                  })
                );
                const number = parseFloat(e.target.value);
                if (!isNaN(number) && isFinite(number)) {
                  setIncomInWords(numberToWords.toWords(number));
                } else {
                  setIncomInWords("");
                }
                formik.handleChange(e);
              }
            }}
            error={formik.touched.income && Boolean(formik.errors.income)}
            helperText={formik.touched.income && formik.errors.income}
            // error={formik.errors.income}
            // helperText={formik.errors.income}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <GlobalInputLable lableText={"Please select your mode of salary"} />
          <CommonRadioButtons
            id={"salaryMode"}
            name={"salaryMode"}
            options={salaryMode}
            onChange={(e) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    salaryMode: e.target.value,
                  },
                })
              );
              formik.handleChange(e);
            }}
            value={formData?.employmentDetails?.salaryMode}
            matchKey={"label"}
          />
        </Grid>

        <Grid
          item
          sx={{
            // textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
          xs={12}
        >
          <GlobalInputLable lableText={"Please select your bank"} />
          <CommonDropdown
            id={"bankId"}
            name={"bankId"}
            options={BankListData ? BankListData : ""}
            onBlur={formik.handleBlur}
            value={
              formData?.employmentDetails?.bankId
                ? BankListData?.find(
                    (option) =>
                      formData?.employmentDetails?.bankId === option.value
                  )?.label
                : null
            }
            onChange={(event, newValue) => {
              dispatch(
                setFormData({
                  employmentDetails: {
                    ...formData.employmentDetails,
                    bankId: newValue?.value,
                  },
                })
              );
              formik.setFieldValue("bankId", newValue);
            }}
            error={formik.touched.bankId && Boolean(formik.errors.bankId)}
            helperText={formik.touched.bankId && formik.errors.bankId}
            // error={formik.errors.bankId}
            // helperText={formik.errors.bankId}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CommonButton buttonText={"Save"} />
        </Grid>
      </Grid>
    </form>
  );
};

export default Salaried;
