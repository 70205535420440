import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const GetCreditScore = () => {
  return (
    <Grid
      sx={{
        width: "100%",
        height: { xs: "60vh", md: "80vh" },
        // border: "0.2px solid grey",
        borderRadius: "10px",
        margin: { sm: "20px" },
        marginBottom: { xs: "50px", sm: "0px" },
        padding: { sm: "30px" },
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box textAlign={"center"}>
        <Typography
          sx={{
            fontSize: { xs: "18px", sm: "22px", lg: "25px" },
            fontWeight: "600",
            color: "#3B5BBA",
          }}
        >
          Check your credit score with Credmudra
        </Typography>
      </Box>
      <Box
        sx={{
          width: "200px",
          height: "180px",
          backgroundColor: "#EBEFF8",
          borderRadius: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontSize: "19px", fontWeight: 600 }}>
          Your credit score
        </Typography>
        <Typography
          sx={{ fontSize: "22px", fontWeight: 700, color: "#3B5BBA" }}
        >
          775/900
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography>
          The above credit score was generated at{" "}
          <span style={{ fontWeight: "600" }}> 23 Jan 2024</span>.
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{
          padding: { xs: "8px 12px", xl: "15px 30px" },
          backgroundColor: "#3B5BBA",
          fontSize: { xs: "12px", md: "14px", xl: "15px" },
          borderRadius: "15px",
        }}
      >
        Check credit score again
      </Button>
     
    </Grid>
  );
};

export default GetCreditScore;
