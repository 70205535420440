import React, { useEffect, useRef, useState } from "react";
import { init } from "echarts";
import * as echarts from "echarts";
import { useMediaQuery } from "@mui/material";

const GaugeComponent = ({ data }) => {
  const chartContainer = useRef(null);
  const [chartData, setChartData] = useState({});
  const isMobile = useMediaQuery("(max-width:600px)");
  const chartHeight = isMobile ? "25vh" : "35vh";

  useEffect(() => {
    if (Object.keys(data).length) {
      setChartData(data);
    }
  });

  useEffect(() => {
    const chart = echarts.init(chartContainer.current);

    window.addEventListener("resize", () => {
      chart.resize();
    });

    const options = {
      series: chartData?.series,
    };

    chart.setOption(options);
  }, [chartData]);

  return (
    <div
      ref={chartContainer}
      style={{
        width: "100%",
        height: chartHeight,
      }}
    ></div>
  );
};

export default GaugeComponent;
