import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommanTypo from "../../../../../../components/commanTypo";
import GlobalInputLable from "../../../../../../components/globalInputLable";
import GlobalInput from "../../../../../../components/globalInput";
import CircularProgress from "@mui/material/CircularProgress";
import InfoBox from "../../../../../../components/infobox";
import { useFormik } from "formik";
import GETPREAPPROVEDOFFER from "../../../../../../images/getpreapproveOffer.svg";
import getApproved2 from "../../../../../../images/getApproved2.svg";
import getApproved3 from "../../../../../../images/getApproved3.svg";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiCallServices";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setFormData,
  setIsLoading,
  setLenderDetails,
  setLoanReason,
} from "../../../../../../store/reducers";
import apiEndPointsConfig from "../../../../../../config/apiEndPointConfig";
import Cookies from "js-cookie";
import { getInitialSubmitValues } from "../../../../../../utils/initialValues";
import { submitSchema } from "../../../../../../utils/validateSchema";
import { getBaseUrl } from "../../../../../../config/baseUrls";
import CommonDropdown from "../../../../../../components/commonDropDown";
function Submit({ updateTab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [disabledState, setDsabledState] = useState(true);
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  const isLoading = useSelector((state) => state?.form?.isLoading);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const baseUrl = getBaseUrl();
  const [Read, setRead] = useState(false);
  const postUtmsData = localStorage.getItem("utms")
    ? JSON.parse(localStorage.getItem("utms"))
    : "";
  const extraDetails = sessionStorage.getItem("extras")
    ? JSON.parse(sessionStorage.getItem("extras"))
    : "";
  const toggleRead = () => {
    setRead(true);
  };
  const offRead = () => {
    setRead(false);
  };
  //Loan Reason Api
  const LoanReasonListData = useSelector(
    (state) => state?.form?.loanReasonList
  );
  const GetLoanReasonList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getLoanReason,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetLoanReasonList?.isSuccess &&
      GetLoanReasonList?.data?.data !== null
    ) {
      const modifyres = GetLoanReasonList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setLoanReason(modifyres));
    }
  }, [GetLoanReasonList?.isSuccess]);

  //lead status api
  // const [LeadStatusApi, LeadStatusApiData] = useApiCallMutation();
  // useEffect(() => {
  //   if (LeadStatusApiData?.isSuccess) {
  //     const response = JSON.stringify(LeadStatusApiData?.data);
  //     sessionStorage.setItem("Lenders", response);
  //     dispatch(setLenderDetails(LeadStatusApiData?.data));
  //     sessionStorage.setItem("leadEligibility", true);
  //     dispatch(setIsLoading(false));
  //     navigateToSelectLender();
  //     if (LeadStatusApiData?.data?.data?.status?.length === 0) {
  //       sessionStorage.setItem("leadEligibility", false);
  //       navigateToSelectLender();
  //     }
  //   }
  // }, [LeadStatusApiData?.isSuccess]);

  // const leadStatusFunction = () => {
  //   const data = {
  //     leadId: sessionStorage.getItem("tempLead"),
  //   };
  //   LeadStatusApi({
  //     endPoint: apiEndPointsConfig?.LeadStatus,
  //     method: "POST",
  //     data: { data },
  //   });
  // };
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  //generate api
  const [GenerateLeadApi, GenerateLeadApiData] = useApiCallMutation();
  const generateLeadApi = () => {
    const data = {
      leadId: sessionStorage.getItem("tempLead"),
      employmentDetails: {
        companyTypeId: formData?.employmentDetails?.companyTypeId,
        industryTypeId: formData?.employmentDetails?.industryTypeId,
        companyName: formData?.employmentDetails?.companyName,
        designation: formData?.employmentDetails?.designation,
        address: formData?.employmentDetails?.address,
        pinCode: formData?.employmentDetails?.pinCode,
        countryId: formData?.employmentDetails?.countryId,
        stateId: formData?.employmentDetails?.stateId,
        cityId: formData?.employmentDetails?.cityId,
        yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
        businessTypeId: formData?.employmentDetails?.businessTypeId,
        professionTypeId: formData?.employmentDetails?.professionTypeId,
        turnover: formData?.employmentDetails?.turnover,
        monthlyProfit: formData?.employmentDetails?.monthlyProfit,
        income: formData?.employmentDetails?.income,
        salaryMode: formData?.employmentDetails?.salaryMode,
        bankId: formData?.employmentDetails?.bankId,
        currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
        savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
        businessOwnedId: formData?.employmentDetails?.businessOwnedId,
        gst: formData?.employmentDetails?.gst,
      },
      termsAndCondition: formData?.termsAndCondition1,
      loan: {
        reason: formData?.loan?.reason,
        amount: formData?.loan?.amount,
        tenure: formData?.loan?.tenure,
        otherReason: formData?.loan?.otherReason,
      },
      finance: {
        panCard: formData?.panCard,
        aadharNo: formData?.aadharNo,
      },
      personalInfo: {
        emailId: formData?.personalInfo?.emailId,
        firstName: formData?.personalInfo?.firstName,
        lastName: formData?.personalInfo?.lastName,
        genderId: formData?.personalInfo?.genderId,
        dateOfBirth: formData?.personalInfo?.dateOfBirth,
        qualificationId: formData?.personalInfo?.qualificationId,
        maritalStatus: formData?.personalInfo?.maritalStatus,
      },
      employmentType: formData?.employmentType,
      others: {
        totalEmiPaidMonthly: 0,
        interestedToGetCreditCard: formData?.others?.interestedToGetCreditCard,
      },
      address: {
        addressLine1: formData?.address?.addressLine1,
        addressLine2: formData?.address?.addressLine2,
        pinCode: formData?.address?.pinCode,
        countryId: formData?.address?.countryId,
        stateId: formData?.address?.stateId,
        cityId: formData?.address?.cityId,
        residenceTypeId: formData?.address?.residenceTypeId,
        yearsLivingIn: formData?.address?.yearsLivingIn,
      },
      utm: {
        id: postUtmsData?.id || "",
        url: postUtmsData?.url || "",
        source: postUtmsData?.source || "",
        medium: postUtmsData?.medium || "",
        campaign: postUtmsData?.campaign || "",
        term: postUtmsData?.term || "",
        content: postUtmsData?.content || "",
        clickId: "",
      },
      extras: {
        browser: extraDetails?.browser,
        operatingSystem: extraDetails?.operatingSystem,
        ipAddress: extraDetails?.ipAddress,
        timestamp: extraDetails?.timestamp,
        userAgent: extraDetails?.userAgent,
        location: extraDetails?.location,
      },
      // utm: {
      //   id: formData?.utm?.id,
      //   url: formData?.utm?.url,
      //   source: formData?.utm?.source,
      //   medium: formData?.utm?.medium,
      //   campaign: formData?.utm?.campaign,
      //   term: formData?.utm?.term,
      //   content: formData?.utm?.content,
      //   clickId: formData?.utm?.clickId,
      // },
      // extras: {
      //   browser: formData?.extras?.browser,
      //   operatingSystem: formData?.extras?.operatingSystem,
      //   ipAddress: formData?.extras?.ipAddress,
      //   timestamp: formData?.extras?.timestamp,
      //   userAgent: formData?.extras?.userAgent,
      //   location: formData?.extras?.location,
      // },
    };
    GenerateLeadApi({
      endPoint: apiEndPointsConfig?.GenerateLead,
      method: "POST",
      data: { data },
    });
  };
  const handleSubmit = (e) => {
    dispatch(setIsLoading(true));
    generateLeadApi();
  };
  useEffect(() => {
    if (GenerateLeadApiData?.isSuccess) {
      const response = GenerateLeadApiData?.data?.data;
      if (
        GenerateLeadApiData?.data?.status?.code === 404 ||
        GenerateLeadApiData?.data?.status?.code === 400
      ) {
        alert("Provided leadId does not exist");
        dispatch(
          setFormData({
            leadId: "",
            stage: "",
            contactNo: "",
            termsAndCondition1: true,
            loan: {
              reason: null,
              amount: 1000,
              tenure: 1,
            },
            panCard: "",
            aadharNo: "",
            emailId: "",
            employmentType: "",
            employmentDetails: {
              companyTypeId: "",
              industryTypeId: "",
              companyName: "",
              designation: "",
              address: "",
              pinCode: "",
              countryId: "",
              stateId: "",
              cityId: "",
              yearsWorkingIn: "",
              businessTypeId: "",
              professionTypeId: "",
              turnover: "",
              monthlyProfit: "",
              income: "",
              salaryMode: "",
              bankId: "",
              currentAccountBankId: "",
              savingAccountBankId: "",
              businessOwnedId: "",
              gst: "",
            },
            personalInfo: {
              emailId: "",
              firstName: "",
              lastName: "",
              genderId: "",
              dateOfBirth: "",
              qualificationId: "",
              maritalStatus: "",
            },
            address: {
              addressLine1: "",
              addressLine2: "",
              pinCode: "",
              countryId: "",
              stateId: "",
              cityId: "",
              residenceTypeId: "",
              yearsLivingIn: "",
            },
            finance: {
              panCard: "",
              aadharNo: "",
            },
            others: {
              totalEmiPaidMonthly: 0,
              interestedToGetCreditCard: true,
            },
          })
        );
        dispatch(setIsLoading(false));
        navigate("/login");
      } else if (response?.displayOffer === "googleExitPage") {
        sessionStorage.setItem("leadEligibility", "googleExitPage");
        dispatch(setIsLoading(false));
        updateTab(2);
        navigate("/profiling/dashboard/marketplace");
      } else if (response?.displayOffer === "offerPage") {
        sessionStorage.setItem("leadEligibility", "offerPage");
        dispatch(setIsLoading(false));
        updateTab(2);
        navigate("/profiling/dashboard/marketplace");
      } else if (response?.displayOffer === "marketplace") {
        dispatch(setIsLoading(false));
        navigateToSelectLender();
      }
      // else if (response?.duplicate === true) {
      // sessionStorage.setItem("leadEligibility", false);
      // dispatch(setIsLoading(false));
      // updateTab(2);
      // } else if (GenerateLeadApiData?.data?.status?.code === 200) {
      //   dispatch(setIsLoading(false));
      //   navigateToSelectLender();
      // }
    }
  }, [GenerateLeadApiData]);

  const formik = useFormik({
    initialValues: getInitialSubmitValues(formData),
    validationSchema: submitSchema,
    onSubmit: handleSubmit,
  });

  const navigateToSelectLender = () => {
    updateTab(1);
    navigate("/profiling/dashboard/preapprovedoffer");
  };
  const checkFormValidity = () => {
    return (
      Object.keys(formik.errors).length === 0 &&
      formik.values.reason &&
      formik.values.termsAndCondition1
    );
  };
  useEffect(() => {
    setDsabledState(!checkFormValidity());
  }, [formik]);
  return (
    <Box
      ref={formRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowGap={4}>
            <Grid
              item
              xs={12}
              sx={{
                mt: 6,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable
                lableText={"Could you share the purpose of this loan?"}
              />
              <CommanTypo
                text={
                  "This information allows us to connect you with suitable partners that align with your specific needs"
                }
              />
              <CommonDropdown
                id={"reason"}
                name={"reason"}
                options={LoanReasonListData || []}
                onBlur={formik.handleBlur}
                value={
                  LoanReasonListData?.find(
                    (option) => formData?.loan?.reason === option.label
                  )?.label
                }
                onChange={(event, newValue) => {
                  if (newValue?.label === "Others") {
                    dispatch(
                      setFormData({
                        loan: {
                          ...formData.loan,
                          reason: newValue?.label,
                          otherReason: newValue?.label,
                        },
                      })
                    );
                  } else {
                    dispatch(
                      setFormData({
                        loan: {
                          ...formData.loan,
                          reason: newValue?.label,
                        },
                      })
                    );
                  }

                  formik.setFieldValue("reason", newValue);
                }}
                error={formik.touched.reason && Boolean(formik.errors.reason)}
                helperText={formik.touched.reason && formik.errors?.reason}
              />
            </Grid>
            <Grid container rowGap={1}>
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    color: "#AAAAAA",
                  }}
                >
                  <Checkbox
                    name="termsAndCondition1"
                    id="termsAndCondition1"
                    onChange={(e) => {
                      dispatch(
                        setFormData({
                          termsAndCondition1: e.target.checked,
                        })
                      );
                      formik.setFieldValue(
                        "termsAndCondition1",
                        e.target.checked
                      );
                    }}
                    value={formData.termsAndCondition1}
                    onBlur={formik.handleBlur}
                    checked={formData.termsAndCondition1}
                    error={formik.errors.termsAndCondition1}
                    helperText={formik.errors.termsAndCondition1}
                  />
                  <Typography sx={{ fontSize: "14px", color: "#AAAAAA" }}>
                    I hereby declare that I have read, understood and agree to
                    the
                    {"  "}
                    <Link
                      target="_blank"
                      style={{ fontWeight: "600", color: "#3653A9" }}
                      to={`${baseUrl}terms-and-conditions`}
                    >
                      Terms & Conditions
                    </Link>
                    {"  "}
                    and the {"  "}
                    <Link
                      target="_blank"
                      style={{ fontWeight: "600", color: "#3653A9" }}
                      to={`${baseUrl}privacy-policy`}
                    >
                      Privacy Policy
                    </Link>
                    .{"  "}And I hereby consent to Boost.Money and EMKAY
                    CONSULTANTS LIMITED being appointed as my authorized
                    representative to receive my Credit Information from
                    Experian for the purpose of Credit Assessment to advise me
                    on the financial journey, on ongoing basis for not exceeding
                    a period of six (6) months.{" "}
                    {Read ? (
                      <>
                        This authorization remains valid until the objective of
                        obtaining the Bureau Score for connecting with
                        Credmudra's lending partner is achieved, or up to six
                        months from the consent collection date. <br />
                        Additionally, I grant permission to Credmudra, its
                        Lending Partners, and affiliated entities to contact me
                        for various communication from Credmudra via SMS,
                        E-mail, and WhatsApp for loans, credit cards, or other
                        relevant information or promotions. <br />I also agree
                        to{" "}
                        <Link
                          target="_blank"
                          style={{ fontWeight: "600", color: "#3653A9" }}
                          to={`${baseUrl}experian-terms-and-conditions`}
                        >
                          Experian's Terms and Conditions.
                        </Link>
                        <br />
                        <Link
                          style={{ fontWeight: "600", color: "#3653A9" }}
                          onClick={offRead}
                          to={""}
                        >
                          Read Less{" "}
                        </Link>
                      </>
                    ) : (
                      <Link
                        style={{ fontWeight: "600", color: "#3653A9" }}
                        onClick={toggleRead}
                        to={""}
                      >
                        Read More{" "}
                      </Link>
                    )}
                  </Typography>
                </Box>
                {formik.errors.termsAndCondition1 &&
                  formik.touched.termsAndCondition1 && (
                    <div
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        marginTop: "4px",
                        marginLeft: "14px",
                        marginRight: "14px",
                      }}
                    >
                      {formik.errors.termsAndCondition1}
                    </div>
                  )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                id={disabledState ? "" : "cred_submit_btn"}
                sx={{
                  display: "flex",
                  width: "108px",
                  height: "48px",
                  padding: "12px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  background: disabledState
                    ? "#cdcdcd"
                    : "var(--Primary-Blue-state500, #3B5BBA)",
                  borderRadius: "8px",
                  "&:hover": { bgcolor: disabledState ? "#cdcdcd" : "#3B5BBA" },
                  color: "#FFF",
                }}
                type="submit"
              >
                SUBMIT
              </Button>
            </Grid>
          </Grid>
        </form>
        {isLoading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
              iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
              text="Your data is securely encrypted. We never share it with third parties. See our"
              boldText="Privacy Policy ."
            /> */}
        </Box>
        {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          />
        </Box> */}
      </Box>
    </Box>
  );
}

export default Submit;
