import { mdiBorderRadius } from "@mdi/js";
import { TabContext } from "@mui/lab";
import { Box, Tab, Tabs, tabsClasses } from "@mui/material";
import { color } from "echarts";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isStepCompleted } from "../../../utils/commonFunctions";
import { useSelector } from "react-redux";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const MobileSubNavigation = () => {
  const { formData } = useSelector((state) => state.form);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case "/profiling/profile/personaldetails":
        setValue(0);
        break;
      case "/profiling/profile/employmentdetails":
        setValue(1);
        break;
      case "/profiling/profile/residencedetails":
        setValue(2);
        break;
      case "/profiling/profile/otherdetails":
        setValue(3);
        break;
      case "/profiling/dashboard/products":
        setValue(0);
        break;
      case "/profiling/dashboard/previous-credit":
        setValue(1);
        break;
      case "/profiling/dashboard/check-credit-score":
        setValue(2);
        break;
      case "/profiling/dashboard/queries":
        setValue(3);
        break;
      case "/profiling/dashboard/manage-profile":
        setValue(4);
        break;
      default:
        setValue((prev) => prev);
    }
  }, [location]);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyles = {
    fontWeight: 700,
    fontSize: "12px",
    borderRadius: "4px",
    background: "var(--BG-1, #F5F5F5)",
    margin: "2px 5px",
    height: "0.2rem",
  };
  const selectedTabStyles = {
    ...tabStyles,
    "& .css-1kv5h6t-MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#fff",
    },
    backgroundColor: "#3B5BBA",
  };
  const indicatorStyle = {
    "& .MuiTabs-indicator": {
      background: "transperent",
    },
  };

  const profile = [
    { label: "Personal Details", route: "/profiling/profile/personaldetails" },
    {
      label: "Employment Details",
      route: "/profiling/profile/employmentdetails",
      isDisabled: !isStepCompleted(formData, "PersonalDetails"),
    },
    {
      label: "Residence Details",
      route: "/profiling/profile/residencedetails",
      isDisabled: !isStepCompleted(formData, "EmploymentDetails"),
    },
    {
      label: "Other Details",
      route: "/profiling/profile/otherdetails",
      isDisabled: !isStepCompleted(formData, "ResidenceDetails"),
    },
  ];

  const dashboard = [
    { label: "Apply Loan", route: "/profiling/dashboard/products" },
    { label: "Track Loan", route: "/profiling/dashboard/previous-credit" },
    {
      label: "Check Credit Score",
      route: "/profiling/dashboard/check-credit-score",
      isDisabled:true
    },
    { label: "Queries", route: "/profiling/dashboard/queries" },
    { label: "Manage Profile", route: "/profiling/profile/personaldetails" },
  ];

  const forwardNavigation = () => {
    if (location.pathname.includes("profile")) {
      switch (value) {
        case 1:
          setValue(0);
          return navigate("/profiling/profile/personaldetails");
        case 2:
          setValue(1);
          return navigate("/profiling/profile/employmentdetails");
        case 3:
          setValue(2);
          return navigate("/profiling/profile/residencedetails");
        default:
          setValue((prev) => prev);
      }
    } else if (location.pathname.includes("dashboard")) {
      switch (value) {
        case 1:
          setValue(0);
          return navigate("/profiling/dashboard/products");
        case 2:
          setValue(1);
          return navigate("/profiling/dashboard/previous-credit");

        case 3:
          setValue(1);
          return navigate("/profiling/dashboard/previous-credit");
          // setValue(2);
          // //  navigate("/profiling/dashboard/check-credit-score");
          // return
        case 4:
          setValue(3);
          return navigate("/profiling/dashboard/queries");
        default:
          setValue((prev) => prev);
      }
    }
  };
  const backwardNavigation = () => {
    if (location.pathname.includes("profile")) {
      switch (value) {
        case 0:
          setValue(1);
          return navigate("/profiling/profile/employmentdetails");
        case 1:
          setValue(2);
          return navigate("/profiling/profile/residencedetails");
        case 2:
          setValue(3);
          return navigate("/profiling/profile/otherdetails");
        default:
          setValue((prev) => prev);
      }
    } else if (location.pathname.includes("dashboard")) {
      switch (value) {
        case 0:
          setValue(1);
          return navigate("/profiling/dashboard/previous-credit");

        case 1:
          setValue(3);
          return navigate("/profiling/dashboard/queries");
         
          // setValue(2);
          // navigate("/profiling/dashboard/check-credit-score");
        case 2:
          setValue(3);
          return navigate("/profiling/dashboard/queries");
        case 3:
          setValue(4);
          return navigate("/profiling/profile/personaldetails");
        default:
          setValue((prev) => prev);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        display: "flex",
      }}
    >
      <Box onClick={forwardNavigation}>
        <img
          src={process.env.PUBLIC_URL + "/assets/Icons/rightArrow.png"}
          alt="Hello"
        />
      </Box>
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange1}
          variant="scrollable"
          scrollButtons="false"
          aria-label="scrollable auto tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              background: "transparent",
              color: "#ffffff",
            },
            // [`& .${tabsClasses.scrollButtons}`]: {
            //   "&.Mui-disabled": { opacity: 0.3 },
            // },
          }}
        >
          {(location.pathname.includes("profile") ? profile : dashboard).map(
            (item, index) => (
              <Tab
                key={item.label}
                style={{
                  minHeight: "30px",
                  padding: "10px 10px",
                  color: item?.isDisabled
                    ? "var(--Text-70, rgba(52, 52, 53, 0.70))"
                    : index === value
                    ? "#ffffff"
                    : "#6585E7",
                }}
                sx={index === value ? selectedTabStyles : tabStyles}
                label={item.label}
                value={index}
                disabled={item?.isDisabled}
                onClick={() => {
                  navigate(item?.route);
                }}
              />
            )
          )}
        </Tabs>
      </TabContext>
      <Box onClick={backwardNavigation}>
        <img
          src={process.env.PUBLIC_URL + "/assets/Icons/leftArrow.png"}
          alt="Hello"
        />
      </Box>
    </Box>
  );
};

export default MobileSubNavigation;
