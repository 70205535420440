import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import ProfileImage from "../../components/profileImage";
import Icon from "@mdi/react";
import { mdiAccountOutline } from "@mdi/js";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckCreditScore,
  Contactdetails,
  EmploymentDetails,
  ManageProfile,
  OtherDetails,
  PersonalDetails,
  PreviousCredit,
  Products,
  Queries,
  ResidenceDetails,
} from "../../utils/CommonSvgIcons/svgIcons";

const SiteBar = ({ sideRoutes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const DefaultIcon = ({ iconName, isActive }) => {
    switch (iconName) {
      case "Personal Details":
        return <PersonalDetails color={isActive} />;
      case "Contact Details":
        return <Contactdetails color={isActive} />;
      case "Employment Details":
        return <EmploymentDetails color={isActive} />;
      case "Residence Details":
        return <ResidenceDetails color={isActive} />;
      case "Other Details":
        return <OtherDetails color={isActive} />;
      case "Apply Loan":
        return <Products color={isActive} />;
      case "Track Loans":
        return <PreviousCredit color={isActive} />;
      case "Check Credit Score":
        return <CheckCreditScore color={isActive} />;
      case "Queries":
        return <Queries color={isActive} />;
      case "Manage Profile":
        return <ManageProfile color={isActive} />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <Grid>
        <ProfileImage />
      </Grid>
      <Box mt={4} sx={{ display: "flex" }}>
        <Divider sx={{ height: "auto" }} orientation="vertical" flexItem />
        <Box>
          <Grid container spacing={1} sx={{ display: "flex",cursor:"pointer" }}>
            {sideRoutes.map((item) => (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    height: "60px",
                    alignItems: "center",
                    background:
                      location.pathname == item?.route ? "#F5F8FF" : "",
                  }}
                  disabled={true}
                  onClick={() => {
                    if (!item.isDisabled) {
                      navigate(item?.route);
                    }
                  }}
                >
                  {location.pathname == item?.route && (
                    <Divider
                      sx={{ width: "3px" }}
                      color="#3B5BBA"
                      orientation="vertical"
                      flexItem
                    />
                  )}

                  <Box sx={{ ml: { sm: 2, md: 3 } }}>
                    <DefaultIcon
                      iconName={item.name}
                      isActive={
                        item.isDisabled
                          ? "grey"
                          : location.pathname == item?.route
                          ? "#3B5BBA"
                          : "#6585E7"
                      }
                    />
                  </Box>
                  <Box sx={{ ml: { sm: 2, md: 3 },display:'flex' }}>
                    <Typography
                      fontFamily={"Lato"}
                      fontSize={{ sm: "12px", md: "14px", lg: "18px" }}
                      color={
                        item.isDisabled
                          ? "grey"
                          : location.pathname == item?.route
                          ? "#3B5BBA"
                          : "#6585E7"
                      }
                    >
                      {item.name}
                    </Typography>
                   
                  </Box>
                  {item.name === "Check Credit Score" && (
                      <Box sx={{ width: '40px', height: '40px',marginBottom:'38px',marginLeft:{md:'20px',lg:'10px',xl:'100px'}}}>
                         <img
                       src={
                        process.env.PUBLIC_URL +
                        "/assets/Icons/comming.svg"
                      }
                       alt="Coming Soon"
                       style={{ width: '100%'}} 
                     />
                        </Box>
                    )}
                  <Box></Box>
                </Box>
                <Divider />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteBar;
