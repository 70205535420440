import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Loader from "../../../../components/Loader/page";
import { useApiCallMutation } from "../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../config/apiEndPointConfig";
import { useSelector } from "react-redux";
function PublicMarketPlace() {
  const navigate = useNavigate();
  const leadId = (() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("id");
  })();
  const [LeadDetailsApi, leadDetailsApiData] = useApiCallMutation();
  const getLeadDetails = () => {
    const data = {
      leadId: leadId,
    };
    LeadDetailsApi({
      endPoint: apiEndPointsConfig?.LeadDetails,
      method: "POST",
      data: { data },
    });
  };
  //get Lead details api call
  useEffect(() => {
    getLeadDetails();
  }, []);

  useEffect(() => {
    if (leadDetailsApiData?.isSuccess) {
      const response = JSON.stringify(leadDetailsApiData?.data?.data);
      sessionStorage.setItem("leadData", response);
      sessionStorage.setItem("publicLeadId", leadId);
      localStorage.setItem('number',response)
      navigate("/profiling/dashboard/personalLoan");      
    }
  }, [leadDetailsApiData]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  );
}

export default PublicMarketPlace;
