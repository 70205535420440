import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BusinessLoan,
  CreditCard,
  CreditScoreIcon,
  PersonalLoan,
} from "../../../../utils/CommonSvgIcons/svgIcons";
import comingSoon from "../../../../images/Group.svg";
import { Link, useNavigate } from "react-router-dom";
import InfoBox from "../../../../components/infobox";
import { useDispatch, useSelector } from "react-redux";
import { isStepCompleted } from "../../../../utils/commonFunctions";
import { useApiCallMutation } from "../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../config/apiEndPointConfig";
import LoanApplicationModal from "../../../../modals/LoanApplicationModal";
import { setIsLoading } from "../../../../store/reducers";
import CongratulationModal from "../../../../modals/CongratulationModal";

const Products = () => {
  const [cardNumber, setCardNUmber] = useState(null);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [isCongratulation, setIsCongratulation] = useState(false);
  const isLoading = useSelector((state) => state?.form?.isLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 900px)");
  const employmentType = useSelector(
    (state) => state.form.formData.employmentType
  );
  const { formData } = useSelector((state) => state.form);
  const [windowOpen, windowOpenData] = useApiCallMutation();
  useEffect(() => {
    if (formData?.personalInfo?.firstName) {
      if (!isStepCompleted(formData, "PersonalDetails")) {
        navigate("/profiling/profile/personaldetails");
      } else if (!isStepCompleted(formData, "EmploymentDetails")) {
        navigate("/profiling/profile/employmentdetails");
      } else if (!isStepCompleted(formData, "ResidenceDetails")) {
        navigate("/profiling/profile/residencedetails");
      } else if (!isStepCompleted(formData, "OtherDetails")) {
        navigate("/profiling/profile/otherdetails");
      }
    }
  }, [formData]);
  const linkData = [
    {
      href: "/profiling/dashboard/personalLoan",
      imageSrc: (
        <PersonalLoan
          color={employmentType === "Self Employed" ? "#000" : "#fff"}
        />
      ),
      label: "Personal Loan",
      disabled: employmentType === "Self Employed",
      title: "This option is unavailable for Self employed",
    },
    {
      href: "/profiling/dashboard/businessLoan",
      imageSrc: (
        <BusinessLoan color={employmentType === "Salaried" ? "#000" : "#fff"} />
      ),
      label: "Business Loan",
      disabled: employmentType === "Salaried",
      title: "This option is unavailable for Salaried Employees",
    },
    {
      // href: "/profiling/dashboard/credit-card",
      imageSrc: <CreditCard />,
      ComingSoon: comingSoon,
      label: "Credit Score",
      disabled: true,
    },
    {
      imageSrc: <CreditScoreIcon />,
      ComingSoon: comingSoon,
      label: "Credit Card",
      disabled: true,
    },
  ];

  useEffect(() => {
    const isCongratution = sessionStorage.getItem("isCongratution");
    debugger;
    if (isCongratution === "true") {
      setIsCongratulation(true);
    } else {
      setIsCongratulation(false);
    }
  }, []);

  useEffect(() => {
    if (windowOpenData.isSuccess) {
      sessionStorage.setItem("window", windowOpenData?.data?.data?.open);
      dispatch(setIsLoading(false));
      if (windowOpenData.data?.data?.open) {
        navigate(linkData[cardNumber].href);
      } else {
        setIsWindowOpen(true);
      }
    }
  }, [windowOpenData]);

  const handlePLBLNavigation = () => {
    sessionStorage.setItem("isCongratution", false);
    setIsCongratulation(false);
    if (employmentType === "Self Employed") {
      navigate("/profiling/dashboard/businessLoan");
    } else if (employmentType === "Salaried") {
      navigate("/profiling/dashboard/personalLoan");
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: { xs: "auto", md: "100%" },
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "center",
        gap: "50px",
        paddingTop: "30px",
      }}
    >
      <LoanApplicationModal isOpen={isWindowOpen} setIsOpen={setIsWindowOpen} />
      <Container
        maxWidth={"sm"}
        sx={{
          padding: "0px",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {isLoading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box textAlign={"center"}>
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "24px" },
              fontWeight: "700",
              color: "#3B5BBA",
              fontFamily: "Lato",
            }}
          >
            Explore our Range of Financial Solutions
          </Typography>
        </Box>

        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: "10px", md: "0px" },
            width: "100%",
          }}
        >
          {linkData.map((link, index) => (
            <Grid
              item
              key={index}
              xs={5}
              sm={5}
              md={6}
              lg={5}
              xl={5}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                width: "80%",
              }}
            >
              <Tooltip
                title={link?.title && (link?.disabled ? link?.title : "")}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={link?.disabled}
                    onClick={() => {
                      setCardNUmber(index);
                      windowOpen({
                        endPoint: apiEndPointsConfig?.windowOpen,
                        method: "GET",
                      });
                      dispatch(setIsLoading(true));
                    }}
                    sx={{
                      display: "flex",
                      backgroundColor: link?.disabled ? "#E1E1E1" : "#6585E7",
                      borderRadius: "14px",
                      height: { xs: "80px", md: "104px", xl: "120px" },
                      padding: "0px",
                      margin: "20px",
                      flexWrap: "wrap",
                      width: { xs: "80px", md: "120px", xl: "140px" },
                      flexDirection: "column",
                      cursor: link?.disabled ? "not-allowed" : "pointer",
                      ":hover": {
                        backgroundColor: link?.disabled ? "#E1E1E1" : "#6585E7",
                        cursor: link?.disabled ? "not-allowed" : "pointer",
                      },
                    }}
                  >
                    <Box
                      width={"100%"}
                      sx={{
                        display: link.ComingSoon ? "flex" : "none",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src={link.ComingSoon}
                        alt=""
                        style={{
                          marginTop: isMobile ? 0 : "-25px",
                          width: "70%",
                        }}
                      />
                    </Box>
                    {link.imageSrc}
                  </Button>
                </Box>
              </Tooltip>
              <Typography
                sx={{
                  fontSize: { xs: "9px", md: "12px" },
                  lineHeight: { xs: "10px", sm: "1" },
                  fontWeight: "500",
                  textAlign: "center",
                  color: "#343435",
                  textDecoration: "none !important",
                }}
              >
                {link.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          />
        </Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          />
        </Box>
      </Box> */}

      <CongratulationModal
        isOpen={isCongratulation}
        setIsOpen={setIsCongratulation}
        onApply={handlePLBLNavigation}
      />
    </Box>
  );
};

export default Products;
