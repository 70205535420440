import React from "react";
import "./loader.css"

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "98vw",
        overflow:'hidden'
      }}
    >
      <div className="loader"></div>
    </div>
  );
}

export default Loader;

