import { Paper, useMediaQuery } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import useProfilePercentage from "../../utils/ProfilePercentage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const CommonCarousel = () => {
  const isMobile = useMediaQuery("(max-width: 500px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const issmDesktop = useMediaQuery("(max-width: 1200px)");
  const ismdDesktop = useMediaQuery("(min-width: 1201px)");
  const percentage = useProfilePercentage();
  const navigate = useNavigate();
  const employmentType = useSelector(
    (state) => state.form.formData.employmentType
  );
  const getHeight = () => {
    if (isMobile) return 80;
    if (isTablet) return 100;
    if (issmDesktop) return 120;
    if (ismdDesktop) return 190;
  };
  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
      url: "/assets/Banners/Desktop/Image1.png",
      mobileUrl: "/assets/Banners/Mobile/Image3.png",
    },
    // {
    //   name: "Random Name #2",
    //   description: "Hello World!",
    //   url: "/assets/Banners/Desktop/Image2.png",
    //   mobileUrl: "/assets/Banners/Mobile/Image2.png",
    // },
    {
      name: "Random Name #3",
      description: "Hello World 2!",
      url: "/assets/Banners/Desktop/Image3.png",
      mobileUrl: "/assets/Banners/Mobile/Image1.png",
    },
    // {
    //   name: "Random Name #4",
    //   description: "Hello World 4!",
    //   url: "/assets/Banners/Desktop/Image4.png",
    //   mobileUrl: "/assets/Banners/Mobile/Image4.png",
    // },
    // {
    //   name: "Random Name #5",
    //   description: "Hello World 5!",
    //   url: "/assets/Banners/Desktop/Image5.png",
    //   mobileUrl: "/assets/Banners/Mobile/Image5.png",
    // },
  ];

  const OnBannerClick = () => {
    if (percentage === 100) {
      if (employmentType === "Self Employed") {
        navigate("/profiling/dashboard/products"); // Navigate to personal loan page
      } else if (employmentType === "Salaried") {
        navigate("/profiling/dashboard/products"); // Navigate to business loan page
      }
    }
  };

  function Item({ item }) {
    const imageUrl = isMobile ? item.mobileUrl : item.url;
    return (
      <Paper sx={{ boxShadow: "none" }}>
        <img
          // src={
          //   process.env.PUBLIC_URL +
          //   // "/assets/Advertise-images/website-button.png"
          //   "/assets/Advertise-images/website-banner.jpg"
          // }
          src={`${process.env.PUBLIC_URL}${imageUrl}`}
          alt="Picture of the author"
          style={{
            width: "100%",
            height: "auto",
            cursor: "pointer",
            borderRadius: "8px",
          }}
          onClick={() => OnBannerClick()}
        />
      </Paper>
    );
  }
  return (
    <Carousel indicators={false} height={getHeight()}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

export default CommonCarousel;
