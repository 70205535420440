import {
  Box,
  Container,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import SiteBar from "../sitebar";
import { Outlet, useNavigate } from "react-router-dom";
import BottomNavigationBar from "../../components/bottomNavigation";
import { isStepCompleted } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";

const Profile = () => {
  const navigate = useNavigate();
  const { formData } = useSelector((state) => state.form);
  const { isBottomNavigation } = useSelector((state) => state.form);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const sideRoutes = [
    {
      name: "Personal Details",
      isCompleted: "",
      route: "/profiling/profile/personaldetails",
      isDisabled: false,
    },
    {
      name: "Employment Details",
      isCompleted: "",
      route: "/profiling/profile/employmentdetails",
      isDisabled: !isStepCompleted(formData, "PersonalDetails"),
    },
    {
      // icon: "/assets/icons/corporate_fare.svg",
      name: "Residence Details",
      isCompleted: "",
      route: "/profiling/profile/residencedetails",
      isDisabled: !(
        isStepCompleted(formData, "EmploymentDetails") &&
        isStepCompleted(formData, "PersonalDetails")
      ),
    },
    {
      // icon: "/assets/icons/dynamic_feed.svg",
      name: "Other Details",
      isCompleted: "",
      route: "/profiling/profile/otherdetails",
      isDisabled: !(
        isStepCompleted(formData, "ResidenceDetails") &&
        isStepCompleted(formData, "EmploymentDetails") &&
        isStepCompleted(formData, "PersonalDetails")
      ),
    },
  ];
  useEffect(() => {
    const handleUnload = (event) => {
      const confirmationMessage =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    const handleLoad = () => {
      // sessionStorage.clear();
      // navigate("/");
    };

    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
        justifyContent: isMobile ? "center" : "space-between",
      }}
    >
      {!isMobile ? (
        <>
          <Box sx={{ width: { sm: "35%", lg: "25%" } }}>
            <SiteBar sideRoutes={sideRoutes} />
          </Box>
          <Divider
            sx={{ width: "50px", height: "auto" }}
            orientation="vertical"
            flexItem
          />
        </>
      ) : (
        <Box width={"100%"}>
          {isStepCompleted(formData, "PersonalDetails") &&
            isStepCompleted(formData, "EmploymentDetails") &&
            isStepCompleted(formData, "ResidenceDetails") &&
            isStepCompleted(formData, "OtherDetails") &&
            isBottomNavigation && <BottomNavigationBar />}
        </Box>
      )}

      <Box
        width={isMobile ? "100%" : "80%"}
        sx={{ paddingLeft: !isMobile && "40px" }}
      >
        <Box
          sx={{
            border: isMobile ? "none" : "0.2px solid #dfdfdf",
            borderRadius: "10px",
            padding: isMobile ? "0px" : "30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: { sm: "70vh" },
            overflowY: "scroll",
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
            marginBottom: isMobile ? "30px" : "0px",
          }}
        >
          <Box
            // sx={{
            //   overflowY: "scroll",
            //   height: "fitContent",
            //   "::-webkit-scrollbar": {
            //     display: "none"
            // }
            // }}
            sx={{
              minHeight: "100%",
              overflowY: "visible",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
