import { BottomNavigationAction, Box, Paper, Typography } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import React, { useEffect, useState } from "react";
import {
  ProfileIcon,
  Dashboard,
  CreditScore,
  Offer,
} from "../../utils/CommonSvgIcons/svgIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomNavigationBar = () => {
  const isUserExist = useSelector((state) => state?.form?.userExist);
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (location.pathname.includes("profile")) {
      setValue(3);
    } else if (location.pathname.includes("check-credit-score")) {
      setValue(2);
    } else if (location.pathname.includes("previous-credit")) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   if (!isUserExist) {
  //     setValue(3);
  //   }
  // }, []);
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: "99999",
      }}
      elevation={3}
    >
      <BottomNavigation showLabels value={value} onChange={handleChange} >
        <BottomNavigationAction
          label="Apply Loan"
          icon={
            <Dashboard
              color={
                !isUserExist ? "black" : value === 0 ? "#1976d2" : "#8AA7FF"
              }
            />
          }
          onClick={() => {
            navigate("/profiling/dashboard/products");
          }}
          sx={{
            color: !isUserExist ? "" : value === 0 ? "#1976d2" : "#8AA7FF",
          }}
          disabled={!isUserExist}
        />

        <BottomNavigationAction
          label="Track Loan"
          icon={
            <Offer
              color={
                !isUserExist ? "black" : value === 1 ? "#1976d2" : "#8AA7FF"
              }
            />
          }
          onClick={() => {
            navigate("/profiling/dashboard/previous-credit");
          }}
          sx={{
            color: !isUserExist ? "" : value === 1 ? "#1976d2" : "#8AA7FF",
          }}
          disabled={!isUserExist}
        />
        <BottomNavigationAction
        sx={{padding:'0px'}}
          label="Credit Score"
          icon={
            <Box textAlign="center">
              <Typography variant="caption" display="block" sx={{fontSize:'9px',lineHeight:'1',color:'#3653A9',fontWeight:'800'}}>
                Coming Soon
              </Typography>
              <CreditScore color="grey" />
            </Box>
          }
          disabled
        />

        <BottomNavigationAction
          label="profile"
          icon={<ProfileIcon color={value === 3 ? "#3C8BD9" : "#8AA7FF"} />}
          onClick={() => {
            navigate("/profiling/profile/personaldetails");
          }}
          sx={{
            color: value === 3 ? "#3C8BD9" : "#8AA7FF",
          }}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavigationBar;
