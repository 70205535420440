import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import formSlice from "./reducers/index"
import { ApiCallServices } from "../services/apiCallServices";
export const store = configureStore({
  reducer: {
    form:formSlice,
    [ApiCallServices.reducerPath]: ApiCallServices.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ApiCallServices.middleware),
});

setupListeners(store.dispatch)