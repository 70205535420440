import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./header";
import CommonCarousel from "../components/carousel";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setBanks,
  setBusinessOwned,
  setBusinessType,
  setCompanyAddress,
  setCompanyType,
  setFormData,
  setGenderType,
  setIndustryType,
  setIsBottomNavigation,
  setLoanReason,
  setPersonalAddress,
  setPincodeError,
  setQualifications,
  setResidenceType,
  setUserExist,
} from "../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../services/apiCallServices";
import apiEndPointsConfig from "../config/apiEndPointConfig";
import { isStepCompleted } from "../utils/commonFunctions";
import { logout } from "../routes/Authentication";

const Layout = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector((state) => state?.form?.formData);
  const PinCodeErrorData = useSelector((state) => state?.form?.pinCodeError);
  //Introspect Api
  // const [IntrospectApi,IntrospectApiData]=useApiCallMutation()
  // const token=sessionStorage.getItem('accessToken')
  // const data={
  //   token:token
  // }
  // useEffect(() => {
  //  introspect()
  // }, [])
  // const introspect=()=>{
  //   IntrospectApi({
  //     endPoint: apiEndPointsConfig?.userIntrospect,
  //     method: "POST",
  //     data: {data},
  //   })

  // }
  // useEffect(() => {
  //   if (IntrospectApi?.isSuccess && IntrospectApi?.data?.data?.isExpired) {
  //     const annonymousID = sessionStorage.getItem('anonymousId');
  //     sessionStorage.clear();
  //     sessionStorage.setItem('anonymousId',annonymousID)
  //     navigate("/login");
  //   }
  // }, [IntrospectApi]);
  const GetLoanReasonList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getLoanReason,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetLoanReasonList?.isSuccess &&
      GetLoanReasonList?.data?.data !== null
    ) {
      const modifyres = GetLoanReasonList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setLoanReason(modifyres));
    }
  }, [GetLoanReasonList?.isSuccess]);

  //qualification api call
  const GetQualificationsList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getQualifications,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetQualificationsList?.isSuccess &&
      GetQualificationsList?.data?.data !== null
    ) {
      const modifyres = GetQualificationsList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setQualifications(modifyres));
    }
  }, [GetQualificationsList?.isSuccess]);

  //gender api call
  const GetGenderTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getGenderTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetGenderTypesList?.isSuccess &&
      GetGenderTypesList?.data?.data !== null
    ) {
      const modifyres = GetGenderTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setGenderType(modifyres));
    }
  }, [GetGenderTypesList?.isSuccess]);
  //industry api
  const GetIndustryTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getIndustryTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetIndustryTypesList?.isSuccess &&
      GetIndustryTypesList?.data?.data !== null
    ) {
      const modifyres = GetIndustryTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setIndustryType(modifyres));
    }
  }, [GetIndustryTypesList?.isSuccess]);
  //company api
  const GetCompanyTypesList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getCompanyTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetCompanyTypesList?.isSuccess &&
      GetCompanyTypesList?.data?.data !== null
    ) {
      const modifyres = GetCompanyTypesList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setCompanyType(modifyres));
    }
  }, [GetCompanyTypesList?.isSuccess]);
  //bankList
  const GetAllBankList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBanks,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (GetAllBankList?.isSuccess && GetAllBankList?.data?.data !== null) {
      const modifyres = GetAllBankList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBanks(modifyres));
    }
  }, [GetAllBankList?.isSuccess]);
  // businesowned
  const GetBusinessOwnedList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBusinessOwned,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetBusinessOwnedList?.isSuccess &&
      GetBusinessOwnedList?.data?.data !== null
    ) {
      const modifyres = GetBusinessOwnedList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBusinessOwned(modifyres));
    }
  }, [GetBusinessOwnedList?.isSuccess]);
  //businessList
  const GetBusinessList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getBusinessTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (GetBusinessList?.isSuccess && GetBusinessList?.data?.data !== null) {
      const modifyres = GetBusinessList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setBusinessType(modifyres));
    }
  }, [GetBusinessList?.isSuccess]);
  // residence list
  const GetResidenceTypeList = useGetApiCallQuery({
    endPoint: apiEndPointsConfig?.getResidenceTypes,
    method: "POST",
    data: {},
  });
  useEffect(() => {
    if (
      GetResidenceTypeList?.isSuccess &&
      GetResidenceTypeList?.data?.data !== null
    ) {
      const modifyres = GetResidenceTypeList?.data?.data?.map((itm) => {
        return {
          value: itm.id,
          label: itm.name,
        };
      });
      dispatch(setResidenceType(modifyres));
    }
  }, [GetResidenceTypeList?.isSuccess]);

  //Api Initialization
  //Prepopulate Api
  const [PrePopulateFormDataApi, prePopulateFormData] = useApiCallMutation();
  const prePopulateForm = () => {
    PrePopulateFormDataApi({
      endPoint: apiEndPointsConfig?.userPrePopulateFormData,
      method: "GET",
    });
  };
  useEffect(() => {
    if (
      prePopulateFormData?.isSuccess &&
      prePopulateFormData?.data?.status?.code === 200
    ) {
      dispatch(setUserExist(true));
      debugger;
      sessionStorage.setItem("isExistUser", true);
      sessionStorage.setItem("isCongratution", false);
      dispatch(setIsBottomNavigation(true));
      const prePopulateData = {
        ...formData,
        // loan: {
        //   ...prePopulateFormData?.data?.data?.loan,
        // },
        termsAndCondition: prePopulateFormData?.data?.data?.termsAndCondition,
        employmentType: prePopulateFormData?.data?.data?.employmentType,
        panCard: prePopulateFormData?.data?.data?.panCard,
        aadharNo: prePopulateFormData?.data?.data?.finance?.aadharNo,
        employmentDetails: {
          ...prePopulateFormData?.data?.data.employmentDetails,
        },
        personalInfo: {
          ...prePopulateFormData?.data?.data?.personalInfo,
        },
        finance: {
          panCard: prePopulateFormData?.data?.data.finance.panCard,
          aadharNo: prePopulateFormData?.data?.data.finance.aadharNo,
        },
        address: {
          ...prePopulateFormData?.data?.data.address,
        },
        others: {
          ...prePopulateFormData?.data?.data?.others,
        },
      };
      dispatch(setFormData(prePopulateData));
      const data = {
        pinCode: prePopulateFormData?.data?.data?.address?.pinCode,
      };
      GetPinCodeAddressApi({
        endPoint: apiEndPointsConfig?.getPincodesAddress,
        method: "POST",
        data: { data },
      });
      if (location?.state && location?.state?.from !== "otp") {
        navigate("/profiling/dashboard/products");
      }
    } else if (
      (prePopulateFormData?.isSuccess &&
        prePopulateFormData?.data?.status?.code === 400) ||
      prePopulateFormData?.data?.status?.code === 404
    ) {
      const prePopulateData = {
        termsAndCondition: true,
        panCard: "",
        employmentType: "",
        finance: {
          panCard: "",
          aadharNo: "",
        },
        loan: {},
        employmentDetails: {
          ...formData?.employmentDetails,
          companyTypeId: "",
          industryTypeId: "",
          companyName: "",
          designation: "",
          address: "",
          pinCode: "",
          countryId: "",
          stateId: "",
          cityId: "",
          yearsWorkingIn: "",
          businessTypeId: "",
          professionTypeId: "",
          turnover: "",
          monthlyProfit: "",
          income: "",
          salaryMode: "",
          bankId: "",
          currentAccountBankId: "",
          savingAccountBankId: "",
          businessOwnedId: "",
          gst: "",
        },
        personalInfo: {
          ...formData?.personalInfo,
          firstName: "",
          lastName: "",
          genderId: "",
          dateOfBirth: "",
          qualificationId: "",
          maritalStatus: "",
        },
        address: {
          ...formData?.address,
          addressLine1: "",
          addressLine2: "",
          pinCode: "",
          countryId: "",
          stateId: "",
          cityId: "",
          residenceTypeId: "",
          yearsLivingIn: "",
        },
        others: {
          totalEmiPaidMonthly: 0,
          interestedToGetCreditCard: true,
        },
      };
      dispatch(setFormData(prePopulateData));
      dispatch(
        setCompanyAddress({
          city: "",
          state: "",
          country: "",
        })
      );
      dispatch(
        setPersonalAddress({
          city: "",
          state: "",
          country: "",
        })
      );
      if (location?.state) {
        navigate("/profiling/profile/personaldetails");
      }
    }
  }, [prePopulateFormData]);

  // getAddress API Call
  const [GetPinCodeAddressApi, getPinCodeAddressApiData] = useApiCallMutation();
  //getAddress
  useEffect(() => {
    if (getPinCodeAddressApiData?.isSuccess) {
      if (getPinCodeAddressApiData?.data?.status.code === 200) {
        dispatch(
          setPersonalAddress({
            city: getPinCodeAddressApiData?.data?.data?.city?.name,
            state: getPinCodeAddressApiData?.data?.data?.state?.name,
            country: getPinCodeAddressApiData?.data?.data?.country?.name,
          })
        );
        dispatch(
          setCompanyAddress({
            city: getPinCodeAddressApiData?.data?.data?.city?.name,
            state: getPinCodeAddressApiData?.data?.data?.state?.name,
            country: getPinCodeAddressApiData?.data?.data?.country?.name,
          })
        );
      } else if (getPinCodeAddressApiData?.data?.status.code === 404) {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "Pincode does not exists",
          })
        );
      } else {
        dispatch(
          setPincodeError({
            ...PinCodeErrorData,
            companyPincode: "",
          })
        );
      }
    }
  }, [getPinCodeAddressApiData?.isSuccess]);

  useEffect(() => {
    prePopulateForm();
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: { xs: "58px", sm: "80px", md: "80px" },
        }}
      >
        {/* <Box sx={{ width: { xs: "100%", sm: "50%", md: "40%" } }}> */}
        <Box sx={{ width: "80%" }}>
          <CommonCarousel />
        </Box>
      </Box>
      <Box paddingX={!isMobile ? 8 : 0}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
