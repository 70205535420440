import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Img from "../../../../../../images/image 31.png";
import GreenCheck from "../../../../../../images/check_circle_green.svg";
import InfoBox from "../../../../../../components/infobox";
import { Link, useNavigate } from "react-router-dom";
import { useApiCallMutation } from "../../../../../../services/apiCallServices";
import apiEndPointsConfig from "../../../../../../config/apiEndPointConfig";
import CasheLogo from "../../../../../../Leanders/cashe.png";
import FaircentLogo from "../../../../../../Leanders/faircent.png";
import FibeLogo from "../../../../../../Leanders/fibe.png";
import KisshtLogo from "../../../../../../Leanders/kissht.png";
import Kreditbee from "../../../../../../Leanders/kreditbee.png";
import LendingkartLogo from "../../../../../../Leanders/lendingkart.png";
import LoantapLogo from "../../../../../../Leanders/loantap.png";
import MoneytapLogo from "../../../../../../Leanders/moneytap.png";
import MoneyviewLogo from "../../../../../../Leanders/moneyview.png";
import MoneywideLogo from "../../../../../../Leanders/moneywide.png";
import MpocketLogo from "../../../../../../Leanders/mpocket.png";
import NiraLogo from "../../../../../../Leanders/nira.png";
import PaysenseLogo from "../../../../../../Leanders/paysense.png";
import ProtiumLogo from "../../../../../../Leanders/protium.png";
import StashfinLogo from "../../../../../../Leanders/stashfin.png";
import ZypeLogo from "../../../../../../Leanders/zype.png";
import Indifi from "../../../../../../Leanders/indifi.png";
import Truebalance from "../../../../../../Leanders/truebalance.png";
import Incred from "../../../../../../Leanders/incred.svg";
import Upwards from "../../../../../../Leanders/upwards.png";
import prefr from "../../../../../../Leanders/prefr.webp";
import fatakPay from "../../../../../../Leanders/fatak_pay.png";
import abfl from "../../../../../../Leanders/abfl.png";
import privo from "../../../../../../Leanders/privoLogo.png";
import sbl from "../../../../../../Leanders/sbl.png";
import olyv from "../../../../../../Leanders/olyv.png";
import NoData from "./components/NoDtata";
import Loader from "../../../../../../components/Loader/page";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import abhiLoan from "../../../../../../Leanders/abhiLoan.png";
import lendenClub from "../../../../../../Leanders/lenden.png";
import tataCapital from "../../../../../../Leanders/tata-logo-blue.svg";

const PersonalLoan = ({ loanType }) => {
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState(null);

  //API Call Initialize
  const [AppliedLoan, AppliedLoanData] = useApiCallMutation();

  useEffect(() => {
    if (loanType) {
      const data = {
        employmentType: loanType,
        index: 0,
        itemPerIndex: 100,
      };
      AppliedLoan({
        endPoint: apiEndPointsConfig?.getAppliedLoans,
        method: "POST",
        data: { data },
      });
    }
  }, [loanType]);

  const logo = (lender) => {
    const lowercaseName = lender?.toLowerCase();
    return lowercaseName === "cashe"
      ? CasheLogo
      : lowercaseName === "mpocket"
      ? MpocketLogo
      : lowercaseName === "fibe"
      ? FibeLogo
      : lowercaseName === "kreditbee"
      ? Kreditbee
      : lowercaseName === "moneytap"
      ? MoneytapLogo
      : lowercaseName === "lendingkart"
      ? LendingkartLogo
      : lowercaseName === "paysense"
      ? PaysenseLogo
      : lowercaseName === "moneyview"
      ? MoneyviewLogo
      : lowercaseName === "loantap"
      ? LoantapLogo
      : lowercaseName === "zype"
      ? ZypeLogo
      : lowercaseName === "moneywide"
      ? MoneywideLogo
      : lowercaseName === "stashfin"
      ? StashfinLogo
      : lowercaseName === "faircent"
      ? FaircentLogo
      : lowercaseName === "nira"
      ? NiraLogo
      : lowercaseName === "kissht"
      ? KisshtLogo
      : lowercaseName === "protium"
      ? ProtiumLogo
      : lowercaseName === "indifi"
      ? Indifi
      : lowercaseName === "truebalance"
      ? Truebalance
      : lowercaseName === "incred"
      ? Incred
      : lowercaseName === "upwards"
      ? Upwards
      : lowercaseName === "prefr"
      ? prefr
      : lowercaseName === "fatakpay"
      ? fatakPay
      : lowercaseName === "abfl"
      ? abfl
      : lowercaseName === "privo"
      ? privo
      : lowercaseName === "sbl"
      ? sbl
      : lowercaseName === "olyv"
      ? olyv
      : lowercaseName === "abhiloan"
      ? abhiLoan
      : lowercaseName === "lendenClub"
      ? lendenClub
      : lowercaseName === "tatacapital"
      ? tataCapital
      : "";
  };

  const features = [
    { img: GreenCheck, text: "APR: Good Possible Rates" },
    { img: GreenCheck, text: "Loan Terms: Flexible Loan Terms" },
    { img: GreenCheck, text: "Credit Score: Poor/Fair/Good Excellent" },
  ];

  const handleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <>
      {AppliedLoanData?.isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {AppliedLoanData?.data?.data?.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "90px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {AppliedLoanData?.data?.data?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid #E1E1E1",
                      padding: { xs: "20px", sm: "25px" },
                      borderRadius: "15px",
                      marginBottom: "20px",
                      ...(expandedIndex === index && {
                        height: "auto",
                        display: { xs: "block", lg: "flex" },
                        flexDirection: "column",
                      }),
                    }}
                  >
                    <Box
                      mb={2}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", md: "20px" },
                          fontWeight: 600,
                          color: "#3B5BBA",
                        }}
                      >
                        {item.reason}
                      </Typography>
                      <Box>
                        {expandedIndex === index ? (
                          <ExpandLess
                            style={{ cursor: "pointer" }}
                            onClick={() => handleExpand(index)}
                          />
                        ) : (
                          <ExpandMore
                            style={{ cursor: "pointer" }}
                            onClick={() => handleExpand(index)}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "initial", lg: "flex" },
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography
                          sx={{
                            fontSize: { xs: "13px", md: "14px", lg: "17px" },
                          }}
                        >
                          Requested Amount-
                        </Typography>
                        <Typography
                          sx={{
                            color: "#343435B2",
                            fontSize: { xs: "13px", md: "14px", lg: "17px" },
                          }}
                        >
                          {item.loanAmount}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography
                          sx={{
                            fontSize: { xs: "13px", md: "14px", lg: "17px" },
                          }}
                        >
                          Approved Offers-
                        </Typography>
                        <Box
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (item.approvedOffersCount > 0) {
                              navigate("/profiling/dashboard/personalLoan", {
                                state: { from: "offerPage", id: item?.leadId },
                              });
                            }
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: "13px", md: "14px", lg: "17px" },
                              color:
                                item.approvedOffersCount > 0 ? "#3B5BBA" : "",
                              fontWeight:
                                item.approvedOffersCount > 0 ? 600 : 600,
                              textDecoration: "underline",
                            }}
                          >
                            {item.approvedOffersCount + " Offers"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {expandedIndex === index &&
                      (item?.lenderClicked?.length > 0 ? (
                        <>
                          {item?.lenderClicked?.map((expandedItem, idx) => (
                            <Grid
                              key={idx}
                              container
                              sx={{
                                border: "1px solid #E1E1E1",
                                padding: "25px 10px",
                                borderRadius: "15px",
                                mt: 5,
                                display: "flex",
                                flexDirection: { xs: "column", lg: "row" },
                                alignItems: "center",
                                height: "auto",
                                gap: "5px",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Box>
                                  <img
                                    style={{ width: "100%" }}
                                    src={logo(expandedItem?.name)}
                                    alt=""
                                  />
                                </Box>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={5}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  lineHeight: { xs: "5px", md: "1px" },
                                  // ml: { md: 3 },
                                }}
                              >
                                {features.map((feature, featureIdx) => (
                                  <Box
                                    key={featureIdx}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                      mt: { xs: 1, md: 1 },
                                    }}
                                  >
                                    <img src={feature.img} alt="check" />
                                    <Typography
                                      sx={{
                                        fontSize: { xs: "10px", md: "12px" },
                                      }}
                                    >
                                      {feature.text}
                                    </Typography>
                                  </Box>
                                ))}
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                  display: "flex",
                                  // flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            m: { xs: 2, lg: 0 },
                          }}
                        >
                          {expandedItem.rating.toFixed(1)}/5{" "}
                          <Rating
                            name="read-only"
                            value={expandedItem.rating}
                            readOnly
                            size="small"
                          />
                        </Box> */}
                                <Typography>
                                  Status:{" "}
                                  <span style={{ color: "#3B5BBA" }}>
                                    {" "}
                                    Applied
                                  </span>
                                </Typography>
                                {/* <Button
                          variant="contained"
                          sx={{
                            padding: { xs: "5px 15px", md: "5px 10px" },
                            fontSize: { xs: "8px", md: "10px" },
                            whiteSpace: "nowrap",
                            mt: { xs: 2, md: 1 },
                            backgroundColor: "#3B5BBA",
                          }}
                        >
                          Apply Now
                        </Button> */}
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            color: "rgba(52, 52, 53, 0.7)",
                          }}
                        >
                          <Typography>Oopss!!</Typography>
                          <Typography>Your Offers list is Empty.</Typography>
                        </Box>
                      ))}
                    {/* "See More" button */}
                    {expandedIndex === index && item.offerRemaining > 0 && (
                      // <Link
                      //   style={{
                      //     display: "flex",
                      //     justifyContent: "center",
                      //     marginTop: "20px",
                      //     color: "#3B5BBA",
                      //   }}
                      // >
                      //   {`See ${item.approvedOffersCount} More Offers`}
                      // </Link>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          color: "#3B5BBA",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (item.approvedOffersCount > 0) {
                            navigate("/profiling/dashboard/personalLoan", {
                              state: { from: "offerPage", id: item?.leadId },
                            });
                          }
                        }}
                      >
                        <Typography>{`See ${item.offerRemaining} More Offers`}</Typography>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              {/* <Box
                xs={12}
                sx={{
                  paddingBottom: { xs: "50px", md: "0" },
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <InfoBox
                  iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
                  text="Your data is securely encrypted. We never share it with third parties. See our"
                  boldText="Privacy Policy ."
                />
                <InfoBox
                  iconSrc={
                    process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
                  }
                  text="No impact to your credit score"
                />
              </Box> */}
            </Box>
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  );
};

export default PersonalLoan;
