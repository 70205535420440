import { Autocomplete, TextField } from "@mui/material";
import React from "react";

function CommonDropdown({
  id,
  name,
  options,
  onBlur,
  value,
  onChange,
  helperText,
  error,
  disableClear,
}) {
  return (
    <Autocomplete
      disablePortal
      
      disableClearable={disableClear}
      id={id}
      name={name}
      options={options}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      fullWidth
      renderInput={(params) => (
        <TextField
          error={error}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              borderColor: "var(--Stroke-1, #E1E1E1);",
            },
          }}
          helperText={helperText}
          {...params}
          placeholder="Please Select"
        />
      )}
    />
  );
}

export default CommonDropdown;
