import * as React from "react";
import { createBrowserRouter, Link, Navigate } from "react-router-dom";
import Login from "../views/auth/logIn";
import Signup from "../views/auth/signUp";
import EnterOtp from "../views/auth/validateOtp";
import Layout from "../layout";
import MainSection from "../views/mainContainer";
import Profile from "../views/profile";
import Dashboard from "../views/dashboard";
import PersonalDetails from "../views/profile/components/personalDetails";
import EmploymentDetails from "../views/profile/components/employmentDetails";
import ContactDeatils from "../views/profile/components/contactDetails";
import PersonalLoan from "../views/dashboard/components/personalLoan";
import Products from "../views/dashboard/components/products";
import Authentication from "./Authentication";
import ResidenceDetails from "../views/profile/components/residencedetails";
import OtherDetails from "../views/profile/components/otherDetails";
import CheckCreditScore from "../views/dashboard/components/checkCreditScore";
import Queries from "../views/dashboard/components/queries";
import ManageProfile from "../views/dashboard/components/manageProfile";
import PreviousCredit from "../views/dashboard/components/previousCredit";
import Support from "../views/support";
import CreditCard from "../views/dashboard/components/products/components/creditCard";
import PublicMarketPlace from "../views/dashboard/components/publicMarketplace";
import NotFound from "../views/notFound";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Authentication>
        <Layout />
      </Authentication>
    ),
    children: [
      {
        path: "/",
        element: (
          <Navigate
            to="/profiling/profile/personaldetails"
            state={true}
            replace
          />
        ),
      },
      {
        path: "/profiling",
        element: (
          <Authentication>
            <MainSection />
          </Authentication>
        ),
        children: [
          {
            path: "/profiling/profile",
            element: (
              <Authentication>
                <Profile />
              </Authentication>
            ),
            children: [
              {
                path: "/profiling/profile/personaldetails",
                element: (
                  <Authentication>
                    <PersonalDetails />
                  </Authentication>
                ),
              },
              // {
              //   path: "/profiling/profile/contactdetails",
              //   element: <ContactDeatils />,
              // },
              {
                path: "/profiling/profile/employmentdetails",
                element: (
                  <Authentication>
                    <EmploymentDetails />
                  </Authentication>
                ),
              },
              {
                path: "/profiling/profile/residencedetails",
                element: (
                  <Authentication>
                    <ResidenceDetails />
                  </Authentication>
                ),
              },
              {
                path: "/profiling/profile/otherdetails",
                element: (
                  <Authentication>
                    <OtherDetails />
                  </Authentication>
                ),
              },
            ],
          },
          {
            path: "/profiling/dashboard",
            element: (
              <Authentication>
                <Dashboard />
              </Authentication>
            ),
            children: [
              {
                path: "/profiling/dashboard/products",
                element: <Products />,
              },
              {
                path: "/profiling/dashboard/personalLoan",
                element: <PersonalLoan />,
              },
              {
                path: "/profiling/dashboard/preapprovedoffer",
                element: <PersonalLoan />,
              },
              {
                path: "/profiling/dashboard/marketplace",
                element: <PersonalLoan />,
              },
              {
                path: "/profiling/dashboard/businessLoan",
                element: <PersonalLoan />,
              },
              {
                path: "/profiling/dashboard/previous-credit",
                element: <PreviousCredit />,
              },
              {
                path: "/profiling/dashboard/check-credit-score",
                element: <CheckCreditScore />,
              },
              // {
              //   path: "/profiling/dashboard/credit-card",
              //   element: <CreditCard />,
              // },
              {
                path: "/profiling/dashboard/queries",
                element: <Queries />,
              },
              {
                path: "/profiling/dashboard/manage-profile",
                element: <ManageProfile />,
              },
            ],
          },
        ],
      },
      {
        path: "/support",
        element: (
          <Authentication>
            <Support />
          </Authentication>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "sign-off",
    element: <Signup />,
  },
  {
    path: "/validate-otp",
    element: <EnterOtp />,
  },
  {
    path: "/get-registered/marketplace",
    element: <PublicMarketPlace />,
  },
  { path: "*", element: <NotFound /> },
]);
