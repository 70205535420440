import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Authentication = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = sessionStorage.getItem("accessToken");
  const isPublicLeadId = sessionStorage.getItem("publicLeadId");
  if (user) {
    return children;
  } else if (
    isPublicLeadId &&
    location.pathname === "/profiling/dashboard/personalLoan"
  ) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default Authentication;
const logoutChannel = new BroadcastChannel("logout");

export const logout = () => {
  logoutChannel.postMessage("logout");
  sessionStorage.clear();
  window.location.href = window.location.origin + "/";
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};
