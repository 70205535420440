import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CommanTypo from "../../../../../../components/commanTypo";
import GlobalInputLable from "../../../../../../components/globalInputLable";
import GlobalInput from "../../../../../../components/globalInput";
import CircularProgress from "@mui/material/CircularProgress";
import InfoBox from "../../../../../../components/infobox";
import { useFormik } from "formik";
import GETPREAPPROVEDOFFER from "../../../../../../images/getpreapproveOffer.svg";
import getApproved2 from "../../../../../../images/getApproved2.svg";
import getApproved3 from "../../../../../../images/getApproved3.svg";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiCallServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFormData,
  setLenderDetails,
} from "../../../../../../store/reducers";
import apiEndPointsConfig from "../../../../../../config/apiEndPointConfig";
import Animation from "./animation";
import Cookies from "js-cookie";
const Index = ({ updateTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  // const tempLead=sessionStorage.setItem('tempLead')
  //data from redux
  const formData = useSelector((state) => state?.form?.formData);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [timer, setTimer] = useState(60);

  const images = [
    {
      // src: GETPREAPPROVEDOFFER,
      text: "Credmudra makes it easier for individuals with varying credit histories to find suitable loan options",
      text2: "Did you know? ",
    },
    {
      // src: getApproved3,
      text: "Check out our blog for expert advice on loan management while you wait.",
      text2: "Explore Tips & Tricks ",
    },
    {
      // src: getApproved2,
      text: "Any questions? Reach out to our support team for personalized assistance.",
      text2: "We're Here to Help ",
    },
    {
      // src: getApproved2,
      text: "Subscribe to our newsletter for the latest in finance right to your inbox.",
      text2: "Stay Informed ",
    },
    {
      // src: getApproved2,
      text: "Your customized list of lenders is almost ready!",
      text2: "Nearly There",
    },
  ];
  useEffect(() => {
    sessionStorage.setItem("timerCheck", true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    let currentTimer = 60;
    const timerInterval = setInterval(() => {
      currentTimer -= 1;
      setTimer(currentTimer);
      if (currentTimer === 45) {
        navigateToSelectLender();
      }
      if (currentTimer <= 0) {
        clearInterval(timerInterval);
      }
    }, 1000);
  }, []);
  //lead status api
  const [LeadStatusApi, LeadStatusApiData] = useApiCallMutation();
  const leadStatusFunction = () => {
    const data = {
      leadId: sessionStorage.getItem("tempLead"),
    };
    LeadStatusApi({
      endPoint: apiEndPointsConfig?.LeadStatus,
      method: "POST",
      data: { data },
    });
  };
  useEffect(() => {
    setTimeout(() => {
      leadStatusFunction();
    }, 5000);
  }, []);
  useEffect(() => {
    if (LeadStatusApiData?.isSuccess) {
      const response = JSON.stringify(LeadStatusApiData?.data);
      sessionStorage.setItem("Lenders", response);
      dispatch(setLenderDetails(LeadStatusApiData?.data));
      sessionStorage.setItem("leadEligibility", "marketplace");
      // navigateToSelectLender();
      if (LeadStatusApiData?.data?.data?.status?.length === 0) {
        sessionStorage.setItem("leadEligibility", "googleExitPage");
        navigateToSelectLender();
      }
    }
  }, [LeadStatusApiData?.isSuccess]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const postUtmsData = localStorage.getItem("utms")
    ? JSON.parse(localStorage.getItem("utms"))
    : "";
  const extraDetails = sessionStorage.getItem("extras")
    ? JSON.parse(sessionStorage.getItem("extras"))
    : "";
  //generate api
  // const [GenerateLeadApi,GenerateLeadApiData] = useApiCallMutation();
  // useEffect(() => {
  //   const data = {
  //     leadId: sessionStorage.getItem("tempLead"),
  //     employmentDetails: {
  //       companyTypeId: formData?.employmentDetails?.companyTypeId,
  //       industryTypeId: formData?.employmentDetails?.industryTypeId,
  //       companyName: formData?.employmentDetails?.companyName,
  //       designation: formData?.employmentDetails?.designation,
  //       address: formData?.employmentDetails?.address,
  //       pinCode: formData?.employmentDetails?.pinCode,
  //       countryId: formData?.employmentDetails?.countryId,
  //       stateId: formData?.employmentDetails?.stateId,
  //       cityId: formData?.employmentDetails?.cityId,
  //       yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
  //       businessTypeId: formData?.employmentDetails?.businessTypeId,
  //       professionTypeId: formData?.employmentDetails?.professionTypeId,
  //       turnover: formData?.employmentDetails?.turnover,
  //       monthlyProfit: formData?.employmentDetails?.monthlyProfit,
  //       income: formData?.employmentDetails?.income,
  //       salaryMode: formData?.employmentDetails?.salaryMode,
  //       bankId: formData?.employmentDetails?.bankId,
  //       currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
  //       savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
  //       businessOwnedId: formData?.employmentDetails?.businessOwnedId,
  //       gst: formData?.employmentDetails?.gst,
  //     },
  //     termsAndCondition: formData?.termsAndCondition,
  //     loan: {
  //       reason: formData?.loan?.reason,
  //       amount: formData?.loan?.amount,
  //       tenure: formData?.loan?.tenure,
  //       otherReason: formData?.loan?.otherReason,
  //     },
  //     finance: {
  //       panCard: formData?.panCard,
  //       aadharNo: formData?.aadharNo,
  //     },
  //     personalInfo: {
  //       emailId: formData?.personalInfo?.emailId,
  //       firstName: formData?.personalInfo?.firstName,
  //       lastName: formData?.personalInfo?.lastName,
  //       genderId: formData?.personalInfo?.genderId,
  //       dateOfBirth: formData?.personalInfo?.dateOfBirth,
  //       qualificationId: formData?.personalInfo?.qualificationId,
  //       maritalStatus: formData?.personalInfo?.maritalStatus,
  //     },
  //     employmentType: formData?.employmentType,
  //     others: {
  //       totalEmiPaidMonthly: 0,
  //       interestedToGetCreditCard: formData?.others?.interestedToGetCreditCard,
  //     },
  //     address: {
  //       addressLine1: formData?.address?.addressLine1,
  //       addressLine2: formData?.address?.addressLine2,
  //       pinCode: formData?.address?.pinCode,
  //       countryId: formData?.address?.countryId,
  //       stateId: formData?.address?.stateId,
  //       cityId: formData?.address?.cityId,
  //       residenceTypeId: formData?.address?.residenceTypeId,
  //       yearsLivingIn: formData?.address?.yearsLivingIn,
  //     },
  //     utm: {
  //       id: postUtmsData?.id || "",
  //       url: postUtmsData?.url || "",
  //       source: postUtmsData?.source || "",
  //       medium: postUtmsData?.medium || "",
  //       campaign: postUtmsData?.campaign || "",
  //       term: postUtmsData?.term || "",
  //       content: postUtmsData?.content || "",
  //       clickId: "",
  //     },
  //     extras: {
  //       browser: extraDetails?.browser,
  //       operatingSystem: extraDetails?.operatingSystem,
  //       ipAddress: extraDetails?.ipAddress,
  //       timestamp: extraDetails?.timestamp,
  //       userAgent: extraDetails?.userAgent,
  //       location: extraDetails?.location,
  //     },
  //     // utm: {
  //     //   id: formData?.utm?.id,
  //     //   url: formData?.utm?.url,
  //     //   source: formData?.utm?.source,
  //     //   medium: formData?.utm?.medium,
  //     //   campaign: formData?.utm?.campaign,
  //     //   term: formData?.utm?.term,
  //     //   content: formData?.utm?.content,
  //     //   clickId: formData?.utm?.clickId,
  //     // },
  //     // extras: {
  //     //   browser: formData?.extras?.browser,
  //     //   operatingSystem: formData?.extras?.operatingSystem,
  //     //   ipAddress: formData?.extras?.ipAddress,
  //     //   timestamp: formData?.extras?.timestamp,
  //     //   userAgent: formData?.extras?.userAgent,
  //     //   location: formData?.extras?.location,
  //     // },
  //   };
  //   GenerateLeadApi({
  //     endPoint: apiEndPointsConfig?.GenerateLead,
  //     method: "POST",
  //     data: { data },
  //   });
  // }, []);
  // useEffect(() => {
  //   if (GenerateLeadApiData?.isSuccess) {
  //     const response = GenerateLeadApiData?.data?.data;
  //     if (
  //       GenerateLeadApiData?.data?.status?.code === 404 ||
  //       GenerateLeadApiData?.data?.status?.code === 400
  //     ) {
  //       alert("Provided leadId does not exist");
  //       dispatch(
  //         setFormData({
  //           leadId: "",
  //           stage: "",
  //           contactNo: "",
  //           termsAndCondition: true,
  //           loan: {
  //             reason: null,
  //             amount: 1000,
  //             tenure: 1,
  //           },
  //           panCard: "",
  //           aadharNo: "",
  //           emailId: "",
  //           employmentType: "",
  //           employmentDetails: {
  //             companyTypeId: "",
  //             industryTypeId: "",
  //             companyName: "",
  //             designation: "",
  //             address: "",
  //             pinCode: "",
  //             countryId: "",
  //             stateId: "",
  //             cityId: "",
  //             yearsWorkingIn: "",
  //             businessTypeId: "",
  //             professionTypeId: "",
  //             turnover: "",
  //             monthlyProfit: "",
  //             income: "",
  //             salaryMode: "",
  //             bankId: "",
  //             currentAccountBankId: "",
  //             savingAccountBankId: "",
  //             businessOwnedId: "",
  //             gst: "",
  //           },
  //           personalInfo: {
  //             emailId: "",
  //             firstName: "",
  //             lastName: "",
  //             genderId: "",
  //             dateOfBirth: "",
  //             qualificationId: "",
  //             maritalStatus: "",
  //           },
  //           address: {
  //             addressLine1: "",
  //             addressLine2: "",
  //             pinCode: "",
  //             countryId: "",
  //             stateId: "",
  //             cityId: "",
  //             residenceTypeId: "",
  //             yearsLivingIn: "",
  //           },
  //           finance: {
  //             panCard: "",
  //             aadharNo: "",
  //           },
  //           others: {
  //             totalEmiPaidMonthly: 0,
  //             interestedToGetCreditCard: true,
  //           },
  //         })
  //       );
  //       navigate("/login");
  //     } else if (response?.duplicate === true) {
  //       sessionStorage.setItem("leadEligibility", false);
  //       navigateToSelectLender();
  //     } else if (GenerateLeadApiData?.data?.status?.code === 200) {
  //       setTimeout(() => {
  //         leadStatusFunction();
  //       }, 10000);
  //     }
  //   }
  // }, [GenerateLeadApiData]);

  const formik = useFormik({
    initialValues: "",
    onSubmit: () => {},
  });
  const navigateToSelectLender = () => {
    // setTimeout(() => {
    // navigate("/profiling/dashboard/marketplace");
    updateTab(2);
    navigate("/profiling/dashboard/marketplace");
    // }, 15000);
  };
  return (
    <Box
      ref={formRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowGap={4}>
            <Grid
              item
              xs={12}
              sx={{
                mt: 6,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "24px" },
                  color: "#3B5BBA",
                  fontFamily: "Lato",
                }}
              >
                Loan For Debt Consolidation
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                // mt: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              {/* <Box display="flex" justifyContent="center">
                <CircularProgress color="inherit" />
              </Box> */}
              <Box sx={{ width: "100%", height: "auto" }}>
                {/* <img
                  style={{ width: "100%" }}
                  // src={images[currentImageIndex].src}
                  src={GETPREAPPROVEDOFFER}
                  alt="GetPreApprovedOffer"
                /> */}
                <Animation />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "14px",
                  fontFamily: "Lato",
                  mb: "10px",
                }}
              >
                {images[currentImageIndex].text2}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "#343435B2",
                  textAlign: { xs: "center" },
                }}
              >
                {images[currentImageIndex].text}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  bgcolor: "#EBEFF8",
                  borderRadius: "10px",
                  padding: "25px 20px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#3B5BBA",
                }}
              >
                <Typography sx={{ fontSize: "15px" }}>
                  We're currently pairing you with the best lenders. This could
                  take a minute—thanks for your patience!
                </Typography>
                <span>00:{timer}</span>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>

      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default Index;
