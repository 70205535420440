import React, { useEffect, useState } from "react";
import GlobalInput from "../../../../components/globalInput";
import { useFormik } from "formik";
import { Box, Checkbox, Container, Grid, Typography } from "@mui/material";
import GlobalInputLable from "../../../../components/globalInputLable";
import CommonButton from "../../../../components/commonButton";
import { Link, useNavigate } from "react-router-dom";
import CommonDropdown from "../../../../components/commonDropDown";
import apiEndPointsConfig from "../../../../config/apiEndPointConfig";
import { useApiCallMutation } from "../../../../services/apiCallServices";
import {
  setFormData,
  setIsBottomNavigation,
  setLenderDetails,
} from "../../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { otherDetailsSchema } from "../../../../utils/validateSchema";
import { getInitialOtherDetailsValues } from "../../../../utils/initialValues";
import InfoBox from "../../../../components/infobox";
import { errorFocus } from "../../../../utils/errorFocus";
import { isStepCompleted } from "../../../../utils/commonFunctions";

function OtherDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state?.form?.formData);
  const UserExist = useSelector((state) => state?.form?.userExist);
  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  useEffect(() => {
    if (!isStepCompleted(formData, "PersonalDetails")) {
      navigate("/profiling/profile/personaldetails");
    } else if (!isStepCompleted(formData, "EmploymentDetails")) {
      navigate("/profiling/profile/employmentdetails");
    } else if (!isStepCompleted(formData, "ResidenceDetails")) {
      navigate("/profiling/profile/residencedetails");
    }
  }, []);

  //Drop-Off Api
  const [DropOffApi, dropOffApiData] = useApiCallMutation();
  const dropOffRequest = async () => {
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      stage: "other details",
      others: {
        totalEmiPaidMonthly: formData?.others?.totalEmiPaidMonthly,
        interestedToGetCreditCard: formData?.others?.interestedToGetCreditCard,
      },
    };
    DropOffApi({
      endPoint: apiEndPointsConfig?.newDropOff,
      method: "POST",
      data: { data },
    });
  };
  const [SaveProfileApi, saveProfileApiData] = useApiCallMutation();
  const saveProfile = async () => {
    const isExistUser = sessionStorage.getItem("isExistUser");
    if(!isExistUser){
      sessionStorage.setItem("isCongratution", true);
    }
    sessionStorage.setItem("isExistUser", true);
  
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      termsAndCondition: formData?.termsAndCondition,
      employmentType: formData?.employmentType,
      employmentDetails: {
        companyTypeId: formData?.employmentDetails?.companyTypeId,
        industryTypeId: formData?.employmentDetails?.industryTypeId,
        companyName: formData?.employmentDetails?.companyName,
        designation: formData?.employmentDetails?.designation,
        address: formData?.employmentDetails?.address,
        pinCode: formData?.employmentDetails?.pinCode,
        countryId: formData?.employmentDetails?.countryId,
        stateId: formData?.employmentDetails?.stateId,
        cityId: formData?.employmentDetails?.cityId,
        yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
        businessTypeId: formData?.employmentDetails?.businessTypeId,
        professionTypeId: formData?.employmentDetails?.professionTypeId,
        turnover: formData?.employmentDetails?.turnover,
        monthlyProfit: formData?.employmentDetails?.monthlyProfit,
        income: formData?.employmentDetails?.income,
        salaryMode: formData?.employmentDetails?.salaryMode,
        bankId: formData?.employmentDetails?.bankId,
        currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
        savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
        businessOwnedId: formData?.employmentDetails?.businessOwnedId,
        gst: formData?.employmentDetails?.gst,
      },
      personalInfo: {
        emailId: formData?.personalInfo?.emailId,
        firstName: formData?.personalInfo?.firstName,
        lastName: formData?.personalInfo?.lastName,
        genderId: formData?.personalInfo?.genderId,
        dateOfBirth: formData?.personalInfo?.dateOfBirth,
        qualificationId: formData?.personalInfo?.qualificationId,
        maritalStatus: formData?.personalInfo?.maritalStatus,
      },
      address: {
        addressLine1: formData?.address?.addressLine1,
        addressLine2: formData?.address?.addressLine2,
        pinCode: formData?.address?.pinCode,
        countryId: formData?.address?.countryId,
        stateId: formData?.address?.stateId,
        cityId: formData?.address?.cityId,
        residenceTypeId: formData?.address?.residenceTypeId,
        yearsLivingIn: formData?.address?.yearsLivingIn,
      },

      finance: {
        panCard: formData?.panCard,
        aadharNo: formData?.aadharNo,
      },
      others: {
        totalEmiPaidMonthly: formData?.others?.totalEmiPaidMonthly,
        interestedToGetCreditCard: formData?.others?.interestedToGetCreditCard,
      },
    };
    SaveProfileApi({
      endPoint: apiEndPointsConfig?.saveProfile,
      method: "POST",
      data: { data },
    });
  };
  const [UpdateProfileApi, updateProfileApiData] = useApiCallMutation();
  const updateProfile = async () => {
    const data = {
      leadId: sessionStorage.getItem("leadId"),
      termsAndCondition: formData?.termsAndCondition,
      employmentType: formData?.employmentType,
      employmentDetails: {
        companyTypeId: formData?.employmentDetails?.companyTypeId,
        industryTypeId: formData?.employmentDetails?.industryTypeId,
        companyName: formData?.employmentDetails?.companyName,
        designation: formData?.employmentDetails?.designation,
        address: formData?.employmentDetails?.address,
        pinCode: formData?.employmentDetails?.pinCode,
        countryId: formData?.employmentDetails?.countryId,
        stateId: formData?.employmentDetails?.stateId,
        cityId: formData?.employmentDetails?.cityId,
        yearsWorkingIn: formData?.employmentDetails?.yearsWorkingIn,
        businessTypeId: formData?.employmentDetails?.businessTypeId,
        professionTypeId: formData?.employmentDetails?.professionTypeId,
        turnover: formData?.employmentDetails?.turnover,
        monthlyProfit: formData?.employmentDetails?.monthlyProfit,
        income: formData?.employmentDetails?.income,
        salaryMode: formData?.employmentDetails?.salaryMode,
        bankId: formData?.employmentDetails?.bankId,
        currentAccountBankId: formData?.employmentDetails?.currentAccountBankId,
        savingAccountBankId: formData?.employmentDetails?.savingAccountBankId,
        businessOwnedId: formData?.employmentDetails?.businessOwnedId,
        gst: formData?.employmentDetails?.gst,
      },
      personalInfo: {
        emailId: formData?.personalInfo?.emailId,
        firstName: formData?.personalInfo?.firstName,
        lastName: formData?.personalInfo?.lastName,
        genderId: formData?.personalInfo?.genderId,
        dateOfBirth: formData?.personalInfo?.dateOfBirth,
        qualificationId: formData?.personalInfo?.qualificationId,
        maritalStatus: formData?.personalInfo?.maritalStatus,
      },
      address: {
        addressLine1: formData?.address?.addressLine1,
        addressLine2: formData?.address?.addressLine2,
        pinCode: formData?.address?.pinCode,
        countryId: formData?.address?.countryId,
        stateId: formData?.address?.stateId,
        cityId: formData?.address?.cityId,
        residenceTypeId: formData?.address?.residenceTypeId,
        yearsLivingIn: formData?.address?.yearsLivingIn,
      },

      finance: {
        panCard: formData?.panCard,
        aadharNo: formData?.aadharNo,
      },
      others: {
        totalEmiPaidMonthly: formData?.others?.totalEmiPaidMonthly,
        interestedToGetCreditCard: formData?.others?.interestedToGetCreditCard,
      },
    };
    UpdateProfileApi({
      endPoint: apiEndPointsConfig?.updateProfile,
      method: "POST",
      data: { data },
    });
  };
  const handleSubmit = async () => {
    const isExistUser = sessionStorage.getItem("isExistUser");
    if (!isExistUser) {
      await dropOffRequest();
    }
    dispatch(setIsBottomNavigation(true));
    if (UserExist) {
      updateProfile();
      navigate("/profiling/dashboard/products");
    } else {
      saveProfile();
      navigate("/profiling/dashboard/products");
    }
  };

  const formik = useFormik({
    initialValues: getInitialOtherDetailsValues(formData),
    validationSchema: otherDetailsSchema,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    if (formik?.isSubmitting) {
      errorFocus(formik?.errors);
    }
  }, [formik?.isSubmitting, formik?.errors]);

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Container maxWidth={"sm"}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowGap={4}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <GlobalInputLable lableText={"How much is your total EMI?"} />
              <GlobalInput
                type="tel"
                inputMode="numeric"
                placeholder={"Enter EMI payment"}
                id="totalEmiPaidMonthly"
                name="totalEmiPaidMonthly"
                onChange={(event, newValue) => {
                  if (
                    /^\d*$/.test(event.target.value) &&
                    event.target.value.length <= 6
                  ) {
                    if (Number(event.target.value <= 500000)) {
                      dispatch(
                        setFormData({
                          others: {
                            ...formData.others,
                            totalEmiPaidMonthly: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }
                }}
                error={formik.errors.totalEmiPaidMonthly}
                helperText={formik.errors.totalEmiPaidMonthly}
                onBlur={formik.handleBlur}
                value={formData?.others?.totalEmiPaidMonthly}
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
              xs={12}
            >
              <GlobalInputLable
                lableText={"Are you looking to get a credit card?"}
              />
              <CommonDropdown
                id={"interestedToGetCreditCard"}
                name={"interestedToGetCreditCard"}
                options={options || []}
                onBlur={formik.handleBlur}
                value={
                  options?.find(
                    (option) =>
                      formData?.others?.interestedToGetCreditCard ===
                      option.value
                  )?.label
                }
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      others: {
                        ...formData.others,
                        interestedToGetCreditCard: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("interestedToGetCreditCard", newValue);
                }}
                error={formik.errors.interestedToGetCreditCard}
                helperText={formik.errors.interestedToGetCreditCard}
              />
            </Grid>
            {/* <Grid
            item
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
            xs={12}
          >
            <GlobalInputLable
              lableText={"Do you happen to have a referral code?"}
            />

            <GlobalInput
              type={""}
              placeholder={"XY6NM82W"}
              id="referralCode"
              name="referralCode"
              onChange={(event) => {
                handleChange(event);
              }}
              error={formik.errors.referralCode}
              helperText={formik.errors.referralCode}
              //   value={""}
            />
          </Grid> */}

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CommonButton buttonText={"Save"} />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
            text="Your data is securely encrypted. We never share it with third parties. See our"
            boldText="Privacy Policy ."
          /> */}
        </Box>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <InfoBox
            iconSrc={
              process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
            }
            text="No impact to your credit score"
          /> */}
        </Box>
      </Box>
    </Box>
  );
}

export default OtherDetails;
