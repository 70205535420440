import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Saving from "../../../../../../../images/Savings-bro (1).svg";
import InfoBox from "../../../../../../../components/infobox";

const NoData = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        height: "60vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
      }}
    >
      <Grid
        item
        sx={{
          height: "70%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <img src={Saving} alt="" />
        </Box>
        <Box sx={{ textAlign: "center", color: "#343435B2" }}>
          <Typography>Loan-less?</Typography>
          <Typography>
            Your Application Folder is as Empty as a Piggy Bank!
          </Typography>
        </Box>
        <Button
          onClick={() => {
            navigate("/profiling/dashboard/products");
          }}
          variant="contained"
          sx={{ mt: 3 }}
        >
          Get Started
        </Button>
      </Grid>
      <Grid item sx={{ display: "flex", flexDirection: "column" }}>
        <Box display={"flex"} gap={"5px"} margin={"8px"}>
          {/* <Box
            sx={{
              backgroundColor: "#D9D9D9",
              width: "20px",
              height: "20px",
              borderRadius: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
              alt=""
            />{" "}
          </Box> */}
          {/* <Typography sx={{ fontSize: "13px", color: "#343435B2" }}>
            Your data is securely encrypted. We never share it with third
            parties. See our{" "}
            <Link style={{ textDecoration: "none", color: "#343435" }}>
              {" "}
              Privacy Policy
            </Link>
            .
          </Typography> */}
        </Box>
        {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
          <Box
            sx={{
              backgroundColor: "#D9D9D9",
              width: "20px",
              height: "20px",
              borderRadius: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"}
              alt=""
            />{" "}
          </Box>
          <Typography sx={{ fontSize: "13px", color: "#343435B2" }}>
            No impact to your credit score.
          </Typography>
        </Box> */}
      </Grid>
    </Grid>
  );
};

export default NoData;
