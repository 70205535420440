import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import incred from "../../../../../../../Leanders/incred.svg";
import InfoBox from "../../../../../../../components/infobox";
import { useNavigate } from "react-router-dom";

function IncredMP({ setIncredPage }) {
  const redirectionLink = sessionStorage.getItem("incred_link") || "";
  // const [timer, setTimer] = useState(10);
  const navigate = useNavigate();
  // useEffect(() => {
  //   let currentTimer = 10;
  //   const timerInterval = setInterval(() => {
  //     currentTimer -= 1;
  //     setTimer(currentTimer);
  //     if (currentTimer <= 1) {
  //       window.open(redirectionLink, "_blank")
  //       // window.location.href = redirectionLink;
  //       setIncredPage(false);
  //     }
  //     if (currentTimer <= 0) {
  //       clearInterval(timerInterval);
  //     }
  //   }, 1000);
  // }, []);
  const handleSubmit = () => {
    window.open(redirectionLink, "_blank");
  };

  const cardStyling = {
    border: "1px solid #949495",
    borderRadius: "16px",
    display: "flex",
    transition: "all .4s ease",
    justifyContent: "center",
    alignItems: "center",
    padding: "60px 40px",
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            my: 4,
          }}
        >
          {" "}
          <Container maxWidth="xs">
            <Grid container rowGap={3}>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "lighter",
                    color: "#949495",
                  }}
                >
                  You have been selected the following lender
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Card
                  sx={{
                    ...cardStyling,
                  }}
                >
                  <CardMedia component="img" image={incred} alt="offer1" />
                </Card>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "lighter",
                    color: "#949495",
                  }}
                >
                  Complete your KYC and get your money now
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Box
                  sx={{
                    bgcolor: "#EBEFF8",
                    borderRadius: "10px",
                    padding: "25px 20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#3B5BBA",
                  }}
                >
                  <Typography sx={{ fontSize: "15px" }}>
                    You will receive a call from the lender shortly
                  </Typography>
                  <Button
                    sx={{
                      display: "flex",
                      height: "48px",
                      padding: "12px 30px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      flexShrink: 0,
                      background: "var(--Primary-Blue-state500, #3B5BBA)",
                      borderRadius: "8px",
                      "&:hover": { bgcolor: "#3B5BBA" },
                      color: " #FFF",
                    }}
                    onClick={handleSubmit}
                  >
                    Apply Now
                  </Button>
                  {/* <span>00:{timer}</span> */}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box display={"flex"} gap={"5px"} margin={"8px"}>
            <InfoBox
              iconSrc={process.env.PUBLIC_URL + "/assets/Icons/lock.svg"}
              text="Your data is securely encrypted. We never share it with third parties. See our"
              boldText="Privacy Policy ."
            />
          </Box>
          {/* <Box display={"flex"} gap={"5px"} margin={"8px"}>
            <InfoBox
              iconSrc={
                process.env.PUBLIC_URL + "/assets/Icons/readiness_score.svg"
              }
              text="No impact to your credit score"
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
}

export default IncredMP;
